import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Industries from "./Components/Adminpannel/Industries";
import NotInterested from "./Components/Adminpannel/NotInterested";
import Register from "./Components/Register";
import Login from "./Components/Login";
import About from "./Components/About";
import Header from "./Components/Header";

import Findjob from "./Components/Findjob";
import Profile from "./Components/Profile";
import EmployersLogin from "./Components/EmployersLogin";
import EmployersRegister from "./Components/EmployersRegister";
import Footer from "./Components/Footer";
import CareerGuidance from "./Components/CareerGuidance";
import ResumeWriting from "./Components/ResumeWriting";
import JobProfile from "./Components/JobProfile";
import "antd/dist/reset.css";
import ResumeCreate from "./Components/ResumeCreate";
import DummyResume from "./Components/DummyResume";
import Addjob from "./Components/Employer/Addjob";
import Admin from "./Components/Employer/Admin";
import Applieddetails from "./Components/Employer/Applieddetails";
import Changepassword from "./Components/Employer/Changepassword";
import Editlogindetails from "./Components/Employer/Editlogindetails";
import Gallery1 from "./Components/Employer/Gallery1";
import Myjob from "./Components/Employer/Myjob";
import Profile2 from "./Components/Employer/Profile2";
import Profiledetails from "./Components/Employer/Profiledetails";
import Shortlisted from "./Components/Employer/Shortlisted";
import Show1 from "./Components/Employer/Show1";
import Unverified from "./Components/Employer/Intrested";

import Verified from "./Components/Employer/UnVerified";
import View from "./Components/Employer/View";
import Wcs from "./Components/Employer/Wcs";
import Viewprofile from "./Components/Employer/Viewprofile";
import AdminLogin from "./Components/Adminpannel/AdminLogin";

import Category from "./Components/Adminpannel/Category";

// import Tabel from "./Components/Adminpannel/EditLoginDetails1";


import Addjob1 from "./Components/Adminpannel/Addjob1";
import Adminp from "./Components/Adminpannel/Adminp";
import Applieddetails1 from "./Components/Adminpannel/Applieddetails1";
import CompanyD1 from "./Components/Adminpannel/CompanyD1";
import CompanyDetails1 from "./Components/Adminpannel/CompanyDetails1";
import CompanyDetailsEdit1 from "./Components/Adminpannel/CompanyDetailsEdit1";

import Employee1 from "./Components/Adminpannel/Employee1";
import EmployeeDetails1 from "./Components/Adminpannel/EmployeeDetails1";

import EmployeeDetailsView1 from "./Components/Adminpannel/EmployeeDetailsView1";

import User1 from "./Components/Adminpannel/User1";
import Unverified1 from "./Components/Adminpannel/Unverified1";
import UserDetailsView1 from "./Components/Adminpannel/UserDetailsView1";
import Verified1 from "./Components/Adminpannel/Verified1";
import Wcs1 from "./Components/Adminpannel/Wcs1";
import AdminHome1 from "./Components/Adminpannel/AdminHome1";
import EmpCompanyDetails from "./Components/Employer/EmpCompanyDetails";
import EmpUserDetails from "./Components/Employer/EmpUserDetails";
import FindUser from "./Components/Employer/FindUser";
import EmployerProfile from "./Components/Employer/EmployerProfile";
import CompanyEditemp from "./Components/Employer/CompanyEdit";
import UserProfileview from "./Components/Employer/UserProfileView";
import UnVerified from "./Components/Employer/UnVerified";
import Intrested from "./Components/Employer/Intrested";
import ProgressYourCareer from "./Components/ProgressYourCareer";
import MakeCareerChange from "./Components/MakeCareerChange";
import Graph1 from "./Components/Adminpannel/Graph1";
import OurClient from "./Components/Adminpannel/OurClient";
import AdminHomeS from "./Components/SuAdmin/AdminHomeS";
import AddjobS from "./Components/SuAdmin/AddjobS";
import CategoryS from "./Components/SuAdmin/CategoryS";
// -p-
import UnverifiedS from "./Components/SuAdmin/UnverifiedS";
import VerifiedS from "./Components/SuAdmin/VerifiedS";
import ApplieddetailsS from "./Components/SuAdmin/ApplieddetailsS";
import WcsS from "./Components/SuAdmin/WcsS";
import GraphS from "./Components/SuAdmin/GraphS";
import CompanyDetailsS from "./Components/SuAdmin/CompanyDetailsS";
import OurClientS from "./Components/SuAdmin/OurClientS";
import CompanyDetailsEditS from "./Components/SuAdmin/CompanyDetailsEditS";
import EmployeeDetailsS from "./Components/SuAdmin/EmployeeDetailsS";
import EmployeeDetailsEditS from "./Components/SuAdmin/EmployeeDetailsEditS";
import EmployeeS from "./Components/SuAdmin/EmployeeS";
import UserS from "./Components/SuAdmin/UserS";
import UserDetailsViewS from "./Components/SuAdmin/UserDetailsViewS";
import CompanyDS from "./Components/SuAdmin/CompanyDS";
import EmployeeDetailsViewS from "./Components/SuAdmin/EmployeeDetailsViewS";
import ProfileViewS from "./Components/SuAdmin/ProfileViewS";
import ProfileDetailsS from "./Components/SuAdmin/ProfileDetailsS";
import EditLoginDetailsS from "./Components/SuAdmin/EditLoginDetailsS";
// import ChangepasswordS from './Components/SuAdmin/ChangepasswordS'
import AdminpS from "./Components/SuAdmin/AdminpS";
import AdminLoginS from "./Components/SuAdmin/AdminLoginS";
import A_Subadmin from "./Components/Adminpannel/SubAdmin";
import A_SubadminS from "./Components/SuAdmin/SubAdminS";
import EmployerHome from "./Components/Employer/EmployerHome";
import SubIndustries from "./Components/SuAdmin/IndustriesS";
import NotInterested1 from "./Components/SuAdmin/notintrested";
import CompanyDetails2 from "./Components/Adminpannel/Companydetailsapproved";
import CompanyDetailsSS from "./Components/SuAdmin/companydetailsapprovedS";
import AJobProfile from "./Components/AJobProfile";
import Findcat from "./Components/findcat";

import CompanyDetailsS3 from "./Components/SuAdmin/companydetailsview";
import CompanyDetails3 from "./Components/Adminpannel/companydetailsview";
import UserProfileview1 from "./Components/Employer/UserProfileView1";
import Password from "./Components/Adminpannel/passwordchange";
import Notification from "./Components/Adminpannel/Notification";
import Notification1 from "./Components/SuAdmin/NotificationS";
import Qrcode from "./Components/Adminpannel/Qrcode";
import QrcodeS from "./Components/SuAdmin/QrcodeS";
import Adbusiness from "./Components/Adminpannel/addbusiness";
import AdbusinessS from "./Components/SuAdmin/addbusinessS";
import Readmore from "./Components/readmore";
import Discliamer from "./Components/discliamer";
import Terms from "./Components/terms&condition";
import Policy from "./Components/policy";



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
                <Footer />
              </>
            }
          />
           <Route
            path="/readmore"
            element={
              <>
                <Header />
                <Readmore />
                <Footer />
              </>
            }
          />
          <Route
            path="/employee-login"
            element={
              <>
                {" "}
                <Header />
                <Login />
                <Footer />
              </>
            }
          />
          <Route
            path="/discliamer"
            element={
              <>
                {" "}
                <Header />
                <Discliamer />
                <Footer />
              </>
            }
          />
           <Route
            path="/privacypolicy"
            element={
              <>
                {" "}
                <Header />
                <Policy />
                <Footer />
              </>
            }
          />
           <Route
            path="/terms&condition"
            element={
              <>
                {" "}
                <Header />
                <Terms />
                <Footer />
              </>
            }
          />
          <Route
            path="/register"
            element={
              <>
                <Register /> <Footer />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <Header />
                <About />
                {/* <Blog /> */}
                <Footer />
              </>
            }
          />
          <Route
            path="/career-guidance"
            element={
              <>
                <Header />
                <CareerGuidance />
                <Footer />
              </>
            }
          />
          <Route
            path="/progress-your-career"
            element={
              <>
                <Header />
                <ProgressYourCareer />
                <Footer />
              </>
            }
          />
          <Route
            path="/make-career-change"
            element={
              <>
                <Header />
                <MakeCareerChange />
                <Footer />
              </>
            }
          />
          <Route
            path="/resume-writing"
            element={
              <>
                <Header />
                <ResumeWriting />
                <Footer />
              </>
            }
          />
          <Route
            path="/resume-create"
            element={
              <>
                <Header />
                <ResumeCreate />
                <Footer />
              </>
            }
          />
          <Route
            path="/dummy-resume"
            element={
              <>
                <Header />
                <DummyResume />
                <Footer />
              </>
            }
          />
          <Route
            path="/find-job/:jobs"
            element={
              <>
                <Header />
                <Findjob />
                <Footer />
              </>
            }
          />
           <Route
            path="/find-job1/:jobs"
            element={
              <>
                <Header />
                <Findcat />
                <Footer />
              </>
            }
          />
          <Route
            path="/condidate-profile"
            element={
              <>
                <Header />
                <Profile />
                <Footer />
              </>
            }
          />
          <Route
            path="/employers-login"
            element={
              <>
                <Header />
                <EmployersLogin />
                <Footer />
              </>
            }
          />
          <Route
            path="/employers-registers"
            element={
              <>
                <Header />
                <EmployersRegister />
                <Footer />
              </>
            }
          />
          <Route
            path="/job-profile-details/:id"
            element={
              <>
                <Header />
                <JobProfile />
                <Footer />
              </>
            }
          />
<Route
            path="/Ajob-profile-details/:id"
            element={
              <>
                <Header />
                <AJobProfile />
                <Footer />
              </>
            }
          />
          {/* Sameer */}

          {/* Employer panel */}
          <Route
            path="/addjob"
            element={
              <>
                <Admin children={<Addjob />} />
              </>
            }
          />
          <Route
            path="/myjob"
            element={
              <>
                <Admin children={<Myjob />} />
              </>
            }
          />
             <Route
            path="/Employer-home"
            element={
              <>
                <Admin children={<EmployerHome />} />
              </>
            }
          />
          <Route
            path="/find-user"
            element={
              <>
                <Admin children={<FindUser />} />
              </>
            }
          />
          <Route
            path="/employeecompanydetails/:id"
            element={
              <>
                <Admin children={<EmpCompanyDetails />} />
              </>
            }
          />
          <Route
            path="/employeeuserdetails"
            element={
              <>
                <Admin children={<EmpUserDetails />} />
              </>
            }
          />
          <Route
            path="/employerprofile"
            element={
              <>
                <Admin children={<EmployerProfile />} />
              </>
            }
          />
          <Route
            path="/interested-employees"
            element={
              <>
                <Admin children={<Intrested />} />
              </>
            }
          />

          <Route
            path="/unverifed-employer-job"
            element={
              <>
                <Admin children={<UnVerified />} />
              </>
            }
          />
          <Route
            path="/applieddetails"
            element={
              <>
                <Admin children={<Applieddetails />} />
              </>
            }
          />
          <Route
            path="gallery1"
            element={
              <>
                <Admin children={<Gallery1 />} />
              </>
            }
          />
            <Route
            path="/password"
            element={
              <>
                <Adminp children={<Password />} />
              </>
            }
          />
          <Route
            path="/wcs"
            element={
              <>
                <Admin children={<Wcs />} />
              </>
            }
          />
          <Route
            path="/view"
            element={
              <>
                <Admin children={<View />} />
              </>
            }
          />
          <Route
            path="/show"
            element={
              <>
                <Admin children={<Show1 />} />
              </>
            }
          />
          <Route
            path="/user-profile-view/:id"
            element={
              <>
                <Admin children={<UserProfileview />} />
              </>
            }
          />
            <Route
            path="/user-profile-view1/:id"
            element={
              <>
                <Admin children={<UserProfileview1 />} />
              </>
            }
          />
             
          <Route
            path="/shortlisted"
            element={
              <>
                <Admin children={<Shortlisted />} />
              </>
            }
          />
          <Route
            path="/profile"
            element={
              <>
                <Admin children={<Profile2 />} />
              </>
            }
          />
          <Route
            path="/viewprofile"
            element={
              <>
                <Admin children={<Viewprofile />} />
              </>
            }
          />
          <Route
            path="/companyeditemp/:id"
            element={
              <>
                <Admin children={<CompanyEditemp />} />
              </>
            }
          />
          {/* profile */}
          <Route
            path="/profiledetails"
            element={
              <>
                <Admin children={<Profiledetails />} />
              </>
            }
          />
          <Route
            path="/editlogindetails"
            element={
              <>
                <Admin children={<Editlogindetails />} />
              </>
            }
          />
          <Route
            path="/changepassword"
            element={
              <>
                <Admin children={<Changepassword />} />
              </>
            }
          />

          {/*  */}

          {/* Admin===== */}
          <Route
            path="/admindashboard"
            element={
              <>
                <AdminLogin />
              </>
            }
          />

          {/* admin panel */}

          <Route
            path="/adminhome"
            element={
              <>
                <Adminp children={<AdminHome1 />} />
              </>
            }
          />
          <Route
            path="/addjob1"
            element={
              <>
                <Adminp children={<Addjob1 />} />
              </>
            }
          />
          <Route
            path="/admincategory"
            element={
              <>
                <Adminp children={<Category />} />
              </>
            }
          />
          <Route
            path="/adminunverified"
            element={
              <>
                <Adminp children={<Unverified1 />} />
              </>
            }
          />

          <Route
            path="/adminverified"
            element={
              <>
                <Adminp children={<Verified1 />} />
              </>
            }
          />
          <Route
            path="/adminapplieddetails"
            element={
              <>
                <Adminp children={<Applieddetails1 />} />
              </>
            }
          />

          <Route
            path="/adminwcs"
            element={
              <>
                <Adminp children={<Wcs1 />} />
              </>
            }
          />
          <Route
            path="/admingraph"
            element={
              <>
                <Adminp children={<Graph1 />} />
              </>
            }
          />
          {/* <Route
            path="/view/:id"
            element={
              <>
                <Admin children={<View1 />} />
              </>
            }
          /> */}
          {/* <Route
            path="/show/:id"
            element={
              <>
                <Adminp children={<Show1 />} />
              </>
            }
          /> */}
          {/* <Route
            path="/companyDetails/:id"
            element={
              <>
                <Admin children={<V1 />} />
              </>
            }
          /> */}
          <Route
            path="/admincompanyDetails/:id"
            element={
              <>
                <Adminp children={<CompanyDetails1 />} />
              </>
            }
          />
           <Route
            path="/admincompanyDetails2/:id"
            element={
              <>
                <Adminp children={<CompanyDetails2 />} />
              </>
            }
          />
          <Route
            path="/admin-our-client"
            element={
              <>
                <Adminp children={<OurClient />} />
              </>
            }
          />
          <Route
            path="/admincompanyDetailsEdit/:id"
            element={
              <>
                <Adminp children={<CompanyDetailsEdit1 />} />
              </>
            }
          />
          <Route
            path="/qrcode"
            element={
              <>
                <Adminp children={<Qrcode />} />
              </>
            }
          />
          <Route
            path="/adminemployeeDetails"
            element={
              <>
                <Adminp children={<EmployeeDetails1 />} />
              </>
            }
          />
          {/* <Route
            path="/adminemployeeDetailsEdit"
            element={
              <>
                <Adminp children={<EmployeeDetailsEdit1 />} />
              </>
            }
          /> */}
          <Route
            path="/adminemployee"
            element={
              <>
                <Adminp children={<Employee1 />} />
              </>
            }
          />
          <Route
            path="/adminuser"
            element={
              <>
                <Adminp children={<User1 />} />
              </>
            }
          />
           <Route
            path="/industries"
            element={
              <>
                <Adminp children={<Industries />} />
              </>
            }
          />
          <Route
            path="/not-interested-job"
            element={
              <>
                <Adminp children={<NotInterested/>} />
              </>
            }
          />
           <Route
            path="/notification"
            element={
              <>
                <Adminp children={<Notification/>} />
              </>
            }
          />
            <Route
            path="/addbusiness"
            element={
              <>
                <Adminp children={<Adbusiness/>} />
              </>
            }
          />
          <Route
            path="/adminuserDetailsView/:id"
            element={
              <>
                <Adminp children={<UserDetailsView1 />} />
              </>
            }
          />
           <Route
            path="/admincompanyDetails3/:id"
            element={
              <>
                <Adminp children={<CompanyDetails3 />} />
              </>
            }
          />
          <Route
            path="/admincompanyd"
            element={
              <>
                <Adminp children={<CompanyD1 />} />
              </>
            }
          />
          <Route
            path="/adminemployeeDetailsView/:id"
            element={
              <>
                <Adminp children={<EmployeeDetailsView1 />} />
              </>
            }
          />
          {/* <Route
            path="/adminprofileview"
            element={
              <>
                <Adminp children={<ProfileView1 />} />
              </>
            }
          /> */}
          {/* <Route
            path="/adminprofiledetails"
            element={
              <>
                <Adminp children={<ProfileDetails1 />} />
              </>
            }
          /> */}
          {/* <Route
            path="/admineditlogindetails"
            element={
              <>
                <Adminp children={<EditLoginDetails1 />} />
              </>
            }
          /> */}
          <Route
            path="/add-sub-admin"
            element={
              <>
                <Adminp children={<A_Subadmin />} />
              </>
            }
          />
          {/* <Route
            path="/table"
            element={
              <>
                <Adminp children={<Tabel />} />
              </>
            }
          /> */}

          {/* ====== */}
          {/* Sub admin */}

          <Route
            path="/sub-admindashboard"
            element={
              <>
                <AdminLoginS />
              </>
            }
          />

          <Route
            path="/sub-add-sub-admin"
            element={
              <>
                <AdminpS children={<A_SubadminS />} />
              </>
            }
          />

          <Route
            path="/sub-adminhome"
            element={
              <>
                <AdminpS children={<AdminHomeS/>} />
              </>
            }
          />
          <Route
            path="/sub-addjob1"
            element={
              <>
                <AdminpS children={<AddjobS />} />
              </>
            }
          />
           <Route
            path="/sub-industries"
            element={
              <>
                <AdminpS children={<SubIndustries />} />
              </>
            }
          />
        
          <Route
            path="/sub-admincategory"
            element={
              <>
                <AdminpS children={<CategoryS />} />
              </>
            }
          />
          <Route
            path="/sub-adminunverified"
            element={
              <>
                <AdminpS children={<UnverifiedS />} />
              </>
            }
          />

          <Route
            path="/sub-adminverified"
            element={
              <>
                <AdminpS children={<VerifiedS />} />
              </>
            }
          />
          <Route
            path="/sub-adminapplieddetails"
            element={
              <>
                <AdminpS children={<ApplieddetailsS />} />
              </>
            }
          />

          <Route
            path="/sub-adminwcs"
            element={
              <>
                <AdminpS children={<WcsS />} />
              </>
            }
          />
          <Route
            path="/sub-admingraph"
            element={
              <>
                <AdminpS children={<GraphS />} />
              </>
            }
          />
          <Route
            path="/sub-not-interested-job"
            element={
              <>
                <AdminpS children={<NotInterested1/>} />
              </>
            }
          />
          <Route
            path="/sub-notification"
            element={
              <>
                <AdminpS children={<Notification1/>} />
              </>
            }
          />
           <Route
            path="/sub-addbusiness"
            element={
              <>
                <AdminpS children={<AdbusinessS/>} />
              </>
            }
          />
          <Route
            path="/admincompanyDetailsS3/:id"
            element={
              <>
                <Adminp children={<CompanyDetailsS3 />} />
              </>
            }
          />
          {/* <Route
            path="/view/:id"
            element={
              <>
                <Admin children={<View1 />} />
              </>
            }
          /> */}
          {/* <Route
            path="/show/:id"
            element={
              <>
                <AdminpS children={<Show1 />} />
              </>
            }
          /> */}
          {/* <Route
            path="/companyDetails/:id"
            element={
              <>
                <Admin children={<V1 />} />
              </>
            }
          /> */}
          <Route
            path="/sub-admincompanyDetails/:id"
            element={
              <>
                <AdminpS children={<CompanyDetailsS />} />
              </>
            }
          />
           <Route
            path="/qrcodes"
            element={
              <>
                <AdminpS children={<QrcodeS />} />
              </>
            }
          />
           <Route
            path="/sub-admincompanyDetails1/:id"
            element={
              <>
                <AdminpS children={<CompanyDetailsSS />} />
              </>
            }
          />
          <Route
            path="/sub-admin-our-client"
            element={
              <>
                <AdminpS children={<OurClientS />} />
              </>
            }
          />
          <Route
            path="/sub-admincompanyDetailsEdit/:id"
            element={
              <>
                <AdminpS children={<CompanyDetailsEditS />} />
              </>
            }
          />
          <Route
            path="/sub-adminemployeeDetails"
            element={
              <>
                <AdminpS children={<EmployeeDetailsS />} />
              </>
            }
          />
          <Route
            path="/sub-adminemployeeDetailsEdit"
            element={
              <>
                <AdminpS children={<EmployeeDetailsEditS />} />
              </>
            }
          />
          <Route
            path="/sub-adminemployee"
            element={
              <>
                <AdminpS children={<EmployeeS />} />
              </>
            }
          />
          <Route
            path="/sub-adminuser"
            element={
              <>
                <AdminpS children={<UserS />} />
              </>
            }
          />
          <Route
            path="/sub-adminuserDetailsView/:id"
            element={
              <>
                <AdminpS children={<UserDetailsViewS />} />
              </>
            }
          />
          <Route
            path="/sub-admincompanyd"
            element={
              <>
                <AdminpS children={<CompanyDS />} />
              </>
            }
          />
          <Route
            path="/sub-adminemployeeDetailsView/:id"
            element={
              <>
                <AdminpS children={<EmployeeDetailsViewS />} />
              </>
            }
          />
          <Route
            path="/sub-Adminprofileview"
            element={
              <>
                <AdminpS children={<ProfileViewS />} />
              </>
            }
          />
          <Route
            path="/sub-Adminprofiledetails"
            element={
              <>
                <AdminpS children={<ProfileDetailsS />} />
              </>
            }
          />
          <Route
            path="/sub-admineditlogindetails"
            element={
              <>
                <AdminpS children={<EditLoginDetailsS />} />
              </>
            }
          />
          {/* <Route
            path="/sub-
            adminchangepassword"
            element={
              <>
                <AdminpS children={<ChangepasswordS />} />
              </>
            }
          /> */}
          {/* <Route
            path="/table"
            element={
              <>
                <AdminpS children={<Tabel />} />
              </>
            }
          /> */}

          {/*========= */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
