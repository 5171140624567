import React from "react";
import { GiProgression } from "react-icons/gi";

function ProgressYourCareer() {
  return (
    <>
      <div className="career">
        <div className="ui-bg">
          <div
            class="container ui-header-content mt-30"
            style={{ paddingTop: "20px" }}
          >
            <figure class="mr-20">
              <GiProgression style={{ fontSize: "21px" }} />
            </figure>
            <h1
              class="heading1"
              style={{ fontSize: "35px", paddingLeft: "10px" }}
            >
              Plan for Success : Progress Your Career
            </h1>
          </div>
        </div>
        <div className="container">
          <div class="ui-main col">
            <h2 class="heading3 mt-30">Itching for your next challenge?</h2>
            <p>
              To help you transform your career, we’re bringing you the top tips
              from our Salesforce Team.
            </p>
            <ul className="list mt-40 fs-20">
              <li>
                What did you do today to prepare for the role you want in five
                years? Seize the day! It’s never too early to start preparing.
              </li>
              <li>
                Submit 99 applications and didn’t land one job? Pay close
                attention to what you like — and don’t — and pursue only the
                opportunities that align with those interests.
              </li>
              <li>
                We all want to work with the best and the brightest. Tap into
                the brilliant minds around you.
              </li>
              <li>
                While networking, consider how you can assemble your personal
                dream team.
              </li>
              <li>
                Camerin Thompson, Product Marketing Senior Representative,
                doesn’t regret getting uncomfortable for a second.
              </li>
              <li>
                Want to dip your toes in the water before diving into a new
                role? In Vice President of Workplace Services, Elaine Schultz's,
                experience, stretch assignments give you an authentic taste of a
                new role and showcase your skill set to others.
              </li>
              <li>
                If you find yourself wondering what life could look life if
                you'd taken a different career path, then don't daydream —
                pivot! When Lexis Hanson, Software Engineer wanted to pivot her
                career, she asked a software engineering manager about shadowing
                opportunities.
              </li>
              <li>
                What is a personal brand? Salesforce Director of Enablement
                Programs Paul Wilhoit describes a personal brand as “what people
                say about you when you’re not there.
              </li>
              <li>
                If the role you want requires you to skill up, then hop on
                Trailhead. Trailhead is an on-demand educational tool that
                provides an equal pathway into the tech industry for everyone.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgressYourCareer;