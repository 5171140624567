import React, { useState } from "react";

import { HiUsers } from "react-icons/hi";
import { BsBook, BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { AiFillEyeInvisible, AiOutlineFilePdf } from "react-icons/ai";

import { useNavigate } from "react-router-dom";
import axios from "axios";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Login() {
  let history = useNavigate();

const [email,setEmail]=useState("");
const [password,setpassword]=useState("")
const [eye,seteye]=useState("true")
  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      const config = {
        url: "/login",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data:{email:email,
          password:password},
      };
      let res = await axios(config);
      if (res.status === 200) {
        sessionStorage.setItem("user", JSON.stringify(res.data.success));
        history("/");
        // alert("successfully Login");
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const forgetPassword = async () => {

    try {
      const config = {
        url: "/forgetPassword",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: {email:email},
      };
      let res = await axios(config);
      if (res.status === 200) {
       
        alert("successfully send new password in your mail");
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  //forgetPassword
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <>
    <div className="">
    <div className="container pr">
      <div className="ad-lo" style={{
    //   backgroundImage:"url('/down-bg.jpg')",
    //   backgroundSize:"cover",
    //   height:"auto",
    //   margin: "20px 150px",

    }}>
        <div className="add-login">
          <div className="login-img">
             <figcaption>
              <img src="/login.png" alt="" style={{width:'100%',height:"385px"}}/>
             </figcaption>
          </div>
           {/* Login Start */}
        <div className="register login-signup-box">
          
        <div >
            {/* <h1 class="modal-title">Login</h1> */}
            <div className="d-flex justify-content-center">
              <div className="img-title">
               <img src="/un .jpg" alt="" style={{width:"100%",height:"61px"}} />
              </div>
            </div>
            <h1 class="modal-title">Hello Employee</h1>
          </div>
          <form >
            <fieldset>
              <div class="form-group">
                <input
                  type="text"
                  name="email"
                  class="form-control"
                  placeholder="Email/Mobile number"
                  maxlength="50"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  
                  id="email"
                />
                <span class="error-txt"></span>
              </div>
             {eye ?  <div class="form-group " style={{display:'flex',flexDirection:'row'}}>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  class="form-control"
                  maxlength="16"
                  id="password"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                
                />
                  <BsFillEyeSlashFill onClick={()=>seteye(false)} fontSize={30} style={{marginTop:10}}/>
                <span class="error-txt"></span>
              </div> : <div class="form-group " style={{display:'flex',flexDirection:'row'}}>
                <input
                  type="text"
                  name="password"
                  placeholder="Password"
                  class="form-control"
                  maxlength="16"
                  id="password"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                
                />
                  <BsEyeFill onClick={()=>seteye(true)} fontSize={30} style={{marginTop:10}}/>
                <span class="error-txt"></span>
              </div>}
              
            </fieldset>
            <div className="form-check">
              {/* <label class="form-check-label" for="l_box1">
                <input
                  type="checkbox"
                  name="remember_me"
                  value="locationbox"
                  id="l_box1"
                  class="form-check-input"
                />
                Remember me:
              </label> */}
              <button type="submit" class="btn btn-primary submit" onClick={onSubmit}>
                Login
              </button>
              <small class="form-text text-muted text-center"  style={{display:"flex",justifyContent:"space-between",color:"blue"}}>
              <p className="p-hero"  onClick={()=>history("/register")} style={{cursor:"pointer"}}>Register</p>{" "}
                <p onClick={()=>forgetPassword()} style={{cursor:"pointer"}}>Forgot Password</p>
              </small>
            </div>
          </form>
          
        </div>
        </div>
      </div>
       
      </div>
    </div>

      {/*Modal  */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Login;
