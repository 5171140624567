import React, { useEffect, useState } from "react";
import { BiShoppingBag } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import {
  AiFillTwitterCircle,
  AiFillTwitterSquare,
  AiOutlinePushpin,
} from "react-icons/ai";
import parse from "html-react-parser";

import {
  FaLinkedinIn,
  FaPinterestP,
  FaFacebookF,
  FaRegMoneyBillAlt,
} from "react-icons/fa";
import axios from "axios";
import { TfiTwitter } from "react-icons/tfi";
import { useNavigate, useParams } from "react-router-dom";
import { BsFillCalendarDateFill, BsInstagram, BsTwitter } from "react-icons/bs";
import { ImChrome } from "react-icons/im";
import GoogleMapReact from "google-map-react";

import Iframe from "react-iframe";
import moment from "moment";
import { GiFallingStar } from "react-icons/gi";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function JobProfile() {
  const [job, setJob] = useState({});
  const { id } = useParams();
  useEffect(() => {
    jobLoad();
    getJobList();
  }, []);
  const user = JSON.parse(sessionStorage.getItem("user"));

  console.log(user.skillSet, "user");

  const history = useNavigate();
  const jobLoad = async () => {
    const config = {
      url: "/user/getJobById/" + id,
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setJob(result.data.success);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const [UnvarifiedList, setUnvarifiedList] = useState([]);

  const getJobList = async () => {
    let res = await axios.get(
      "https://univiindia.com/api/user/getAllProfileEmployer"
    );
    if (res.status === 200) {
      console.log(res.data);
      setUnvarifiedList(res.data.success);
    }
  };

  const website = UnvarifiedList.filter((item) => item._id === job.employerId);
  console.log(website, "gccgcg");
  const applyJob = async () => {
    try {
      if (!user) {
        alert("Please login");
        history("/employers-login");
      } else if (!user.resume) {
        alert("Please upload your resume") }
        else if (user.industry === "") {
          alert("Please update your profile to apply")
        }
        else if (
        user?.skillSet.length === 0 || user?.education.length === 0 
     
      ) {
        alert("Please update your profile to apply");
      } else {
        const config = {
          url: "/user/applyForJob",
          method: "post",
          baseURL: "https://univiindia.com/api",
          headers: { "content-type": "application/json" },
          data: { companyId: job._id, userId: user._id },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert(result.data.success);
        
        } else {
          alert("Something went worng");
        }
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
       
      }
    }
  };
  console.log("job======>", job);

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  return (
    <>
      <div className="job-profile">
        <div className="container">
          <div
            className="job-udate"
            style={{
              boxShadow: " 0 5px 10px 2px rgb(195 192 19)",
              padding: "25px",
            }}
          >
            <h3 style={{ color: "#2bb792" }}>{job?.companyName}</h3>
            <div className="nskhdce" style={{ fontSize: "24px" }}>
              <div class="left">
            {job.salarytype !== "Incentive Only" ?  <div class="exp" style={{ color: "#164893" ,fontWeight:900 }}>
                  <em class="naukicon naukicon-bag">
                    {" "}
                    <FaRegMoneyBillAlt />
                  </em>

                  <span>
                    &nbsp; {"₹ "}
                    {job?.minSalary}-{job?.maxSalary} /{job.period}
                    {/* ({" "}
                    <span class="text-muted">{job.salarytype}</span>) */}
                  </span>
                </div> : ""}
                {job?.averageIncentive  ? <div class="exp" style={{ color: "#164893" ,fontWeight:900 }}>
                  <em class="naukicon naukicon-bag">
                    {" "}
                    <FaRegMoneyBillAlt />
                  </em>

                  <span>
                  &nbsp;  Incentive : {"₹ "}
                   {job?.averageIncentive }
                    {/* ({" "}
                    <span class="text-muted">{job.salarytype}</span>) */}
                  </span>
                </div> : ""}
                <div class="exp" style={{ color: "#164893" ,fontWeight:900 }}>
                  <em class="naukicon naukicon-bag">
                    {" "}
                    <GiFallingStar />
                  </em>
                  &nbsp; 
                  {job.experiencerequired === "Fresher" || job.experiencerequired === "Any" ? (
                      <span>{job.experiencerequired}</span>
                    ) : (
                      <span>
                        {job?.experience
                          ? job?.experience
                          : job.experiencerequired}</span>)}
                </div>
                <div class="salary" style={{ color: "#164893" ,fontWeight:900 }}>
                  <em class="naukicon naukicon-salary">
                    <BsFillCalendarDateFill />
                  </em>
                  &nbsp;{" "}
                  {job.Deadline ? (
                    <span>{job?.Deadline}</span>
                  ) : (
                    <span> {moment(job?.updatedAt).fromNow()}</span>
                  )}
                </div>
                <div class="loc" style={{ color: "#164893" ,fontWeight:900 }}>
                  <em class="naukicon naukicon-location">
                    <CiLocationOn />
                  </em>
                  &nbsp; <span class="location ">{job?.location}</span>
                </div>
                <div class="wfhmode" style={{ color: "#164893" ,fontWeight:900 }}>
                  <em class="naukicon naukicon-wfh-pin">
                    <AiOutlinePushpin />
                  </em>
                  &nbsp;&nbsp; <span>{job?.typeofjob}</span>
                </div>
                <div class="salary" style={{ color: "#164893" ,fontWeight:900 }}>
                  <em class="naukicon naukicon-salary">
                    <BiShoppingBag />
                  </em>
                  &nbsp;{" "}
                  {job.Deadline ? (
                    <span>{job?.Deadline}</span>
                  ) : (
                    <span> {job.jobProfile}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="job-desc">
              <h2 style={{ color: "#2bb792", marginTop: "10px" }}>
                Job description
              </h2>
              <div class="dang-inner-html">
                <h5> {job.description ? parse(job?.description) : ""} </h5>
                <br />

                {/* <ul>
                  <li>
                    Good with Angular or React frontend development, minimum
                    recent two-year hands-on experience in such.
                  </li>
                  <li>
                    Good in middleware writing and component customization
                  </li>
                  <li>Has good experience with Typescript</li>
                  <li>Backend:</li>
                  <li>
                    Advanced skills and knowledge of development, architecture,
                    and design on Java platforms
                  </li>
                  <li>Experienced REST API developer</li>
                  <li>
                    Development experience using Core Java, J2EE, Spring
                    framework and RDBMS.
                  </li>
                  <li>To qualify for the role, you must have</li>
                  <li>BE/B.Tech, ME/M.Tech. MCA from a reputed institute</li>
                  <li>
                    Experience with Product development companies will be
                    preferred
                  </li>
                </ul> */}
                <h4>
                  <strong>If you interested please apply now.</strong>{" "}
                </h4>
              </div>
            </div>
            <div className="fsdjfh">
              <div className="row">
                <div className="col-md-6">
                  <div class="other-details">
                    <div class="details">
                      <label>Contact Person Name:</label>
                      <span>
                        <a style={{fontSize:"20px"}}>{job?.interviewername}</a>
                        {/* <span class="comma">,</span> */}
                      </span>
                    </div>
                    <div class="details">
                      <label>Contact No:</label>
                      <span>
                        <a style={{fontSize:"20px"}}>{job?.whatsapp}</a>
                        {/* <span class="comma">,</span> */}
                      </span>
                    </div>
                    <div class="details">
                      <label>Call Us:</label>
                      <span>
                        <a style={{fontSize:"20px"}}>{job?.time}</a>
                        {/* <span class="comma">,</span> */}
                      </span>
                    </div>
                    <div class="details">
                      <label>Email:</label>
                      <span>
                        <span>
                          {" "}
                          <a style={{fontSize:"20px"}}>{job?.email}</a>
                        </span>
                      </span>
                    </div>
                    <div class="details">
                      <label>Who can apply:</label>

                      <span>
                        {" "}
                        <a style={{fontSize:"20px"}}>{job?.gendertype}</a>
                      </span>
                    </div>
                    <div class="details">
                      <label>Role:</label>
                      <span>
                        <a style={{fontSize:"20px"}}>{job?.jobProfile}</a>
                        {/* <span class="comma">,</span> */}
                      </span>
                    </div>

                    <div class="details">
                      <label>Work Type:</label>
                      <span>
                        <a style={{fontSize:"20px"}}>{job?.typeofwork}</a>
                        {/* <span class="comma">,</span> */}
                      </span>
                    </div>
                    <div class="details">
                      <label>No of openings:</label>
                      <span>
                        <a style={{fontSize:"20px"}}>{job?.openings}</a>
                        {/* <span class="comma">,</span> */}
                      </span>
                    </div>
                    <div class="details">
                      <label>Category:</label>
                      <span>
                        <a style={{fontSize:"20px"}}>{job?.category}</a>
                        {/* <span class="comma">,</span> */}
                      </span>
                    </div>
                    <div class="details">
                      <label>Shift Type:</label>
                      <span>
                        <a style={{fontSize:"20px"}}>
                          {job?.night}
                        </a>
                        {/* <span class="comma">,</span> */}
                      </span>
                    </div>
                    <div class="details">
                      <label>Benefit:</label>
                      <span>
                        <a style={{fontSize:"20px"}}>{job?.benefits}</a>
                        {/* <span class="comma">,</span> */}
                      </span>
                    </div>
                    <div class="details">
                      <label>Deposit:</label>
                     {job?.fee ? <span>
                        <a style={{fontSize:"20px"}}>
                          {job?.fee} for {job?.reason}
                        </a>
                        {/* <span class="comma">,</span> */}
                      </span> :<span>
                        <a style={{fontSize:"20px"}}>No Deposit</a></span> }
                    </div>
                    <div class="details">
                      <label>English Level Preference:</label>
                      <span>
                        <a style={{fontSize:"20px"}}>{job?.english}</a>
                        {/* <span class="comma">,</span> */}
                      </span>
                    </div>
                    {/* <div class="details">
                      <label>Experience</label>
                      <span>
                        <a style={{fontSize:"20px"}}>{job?.experiencerequired}</a>
                      
                      </span>
                    </div> */}
                    <div class="details">
                      <label>Salary Type:</label>
                      <span>
                        {" "}
                        <a style={{fontSize:"20px"}}>{job?.salarytype}</a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* {job?.location} */}
                  {/* <div style={{ height: "100vh", width: "100%" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: "" }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      <AnyReactComponent
                        lat={59.955413}
                        lng={30.337844}
                        text="My Marker"
                      />
                    </GoogleMapReact>
                  </div> */}
                </div>
              </div>
            </div>
            <div class="education mt-3">
              <div class="heading" style={{fontSize:"20px"}}>
                {" "}
                <strong>Education </strong>
                
              </div>
              <div class="details">
                <label>Qualification :</label>
                <span>
                  {" "}
                  <a style={{fontSize:"20px"}}>{job?.typeofeducation}</a>
                </span>
                <span>
                  {" "}
                  <a style={{fontSize:"20px"}}>({job?.typeofqualification})</a>
                </span>
              </div>
            </div>
            <div class="education mt-3">
              <div class="heading" style={{fontSize:"20px"}}>
                {" "}
                <strong>Interview </strong>
              </div>
              <div class="details">
                <label>Address :</label>
                <span>
                  {" "}
                  <a style={{fontSize:"20px"}}>
                    {job?.address ? job?.address : "Telephonic Interview"}
                  </a>
                </span>
              </div>
            </div>
            <div className="education mt-3">
              <div class="heading" style={{fontSize:"20px"}}>
                {" "}
                <strong>Skills</strong>
              </div>
              <span>
                {" "}
                <a style={{fontSize:"20px"}}>{job?.skill}</a>
              </span>
            </div>
            <div class="education mt-3">
              <div class="heading" style={{fontSize:"20px"}}>
                {" "}
                <strong>Company Address </strong>
              </div>
              <div class="details" style={{ flexDirection: "column" }}>
                <label>Company Name :</label>
                <span>
                  {" "}
                  <a style={{fontSize:"20px"}}>{job.adminId ? job.companyName : website[0]?.CompanyName }</a>
                </span>
              </div>
              <div class="details" style={{ flexDirection: "column" }}>
                <label>Contact :</label>
                <span>
                  {" "}
                  <a style={{fontSize:"20px"}}>{ job.adminId ? job.whatsapp : website[0]?.mobile}</a>
                </span>
              </div>
              <div class="details" style={{ flexDirection: "column" }}>
                <label>Email :</label>
                <span>
                  {" "}
                  <a style={{fontSize:"20px"}}>{job.adminId ? job.email : website[0]?.email}</a>
                </span>
              </div>
             {!job.adminId ? <div class="details" style={{ flexDirection: "column" }}>
                <label>Industry :</label>
                <span>
                  {" "}
                  <a style={{fontSize:"20px"}}>{website[0]?.industry}</a>
                </span>
              </div> : ""}
              
              <div class="details" style={{ flexDirection: "column" }}>
                <label>Address :</label>
                <span>
                  {" "}
                  <a style={{fontSize:"20px"}}>{job.adminId ? job.address :website[0]?.address}</a>
                </span>
              </div>
              <div class="details" style={{ flexDirection: "column" }}>
                <label>Website :</label>
                <span>
                  {" "}
                  <a style={{fontSize:"20px"}}>  {website[0]?.companyWebsite ? website[0]?.companyWebsite : "http://univiindia.com/"}</a>
                </span>
              </div>
            </div>

            <div className="education mt-3">
              <button
                type="button"
                class="btn btn-success"
                onClick={applyJob}
                style={{ float: "right", marginTop: "-50px" }}
              >
                Apply now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobProfile;
