import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./MajorProducts.css";
import Wcs from "./Wcs";

import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { TimePicker } from "antd";

import { Input } from "antd";

// back-e
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";
const weekFormat = "MM/DD";
const monthFormat = "YYYY/MM";
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;
const customWeekStartEndFormat = (value) =>
  `${dayjs(value).startOf("week").format(weekFormat)} ~ ${dayjs(value)
    .endOf("week")
    .format(weekFormat)}`;

dayjs.extend(customParseFormat);
const onChange = (time, timeString) => {
  //console.log(time, timeString);
};

const { TextArea } = Input;

function View() {
  const [companyname, setCompanyname] = useState("");
  const [contacts, setContacts] = useState("");
  const [jobstatus, setJobstatus] = useState("");
  const [ceo, setCeo] = useState("");
  const [jobprofile, setJobprofile] = useState("");
  const [email, setEmail] = useState("");
  const [skill, setSkill] = useState("");
  const [qualification, setQualification] = useState("");
  const [minsalary, setMinsalary] = useState("");
  const [maxsalary, setMaxsalary] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [location, setLocation] = useState("");
  const [profilelogo, setProfilelogo] = useState("");
  const [salarytype, setSalarytype] = useState("");
  const [city, setCity] = useState("");
  const [websitelink, setWebsitelink] = useState("");
  const [requiredage, setRequiredage] = useState("");
  const [experience, setExperience] = useState("");
  // const [submit, setSubmit] = useState("");
  const formdata = new FormData();
  formdata.set("companyName", companyname);
  formdata.set("contact", contacts);
  formdata.set("jobStatus", jobstatus);
  formdata.set("jobStatus", jobstatus);
  formdata.set("CEO", ceo);
  formdata.set("jobProfile", jobprofile);
  formdata.set("email", email);
  formdata.set("skill", skill);
  formdata.set("qulification", qualification);
  formdata.set("minSalary", minsalary ? minsalary : "");
  formdata.set("maxSalary", maxsalary ? maxsalary : "");
  formdata.set("category", category);
  formdata.set("subcategory", subcategory);
  formdata.set("location", location);
  formdata.set("profileLogo", profilelogo);
  formdata.set("salaryType", salarytype);
  formdata.set("city", city);
  formdata.set("webSiteLink", websitelink);
  formdata.set("requireAge", requiredage);
  formdata.set("experience", experience);
  //console.log("formdata===>", formdata);

  const addJob = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/registerCompany",
        method: "post",
        baseURL: "https://univiindia.com/api/admin",
        headers: { "content-type": "multipart/data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };

  return (
    <>
      {" "}
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
        

          <div
            className="container img"
            style={{
              border: "1px solid #24496a",
              background: "#f1f4fb",
              marginTop: "30px",
              borderRadius: "10px",
              // position: "fixed",
              // overflow: "scroll",
              // height: "500px",
              width: "100%",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  className="heading-banner wow animate__animated animate__fadeInUp"
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Edit Profile Details</b>
                </h1>
              </div>
            </div>
            <br></br>
            <div className="categ d-flex">
              {/* <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
            {" "}
            Id
            <Form.Control type="" placehold"er="Id" />
          </Form.Group> */}

              <br />
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Company Name
                <Form.Control
                  required
                  type="text"
                  placeholder="Company Name"
                  name="companyname"
                  value={companyname}
                  onChange={(e) => setCompanyname(e.target.value)}
                  id="companyname"
                />
              </Form.Group>
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                {" "}
                Contacts
                <Form.Control
                  type="number"
                  placeholder="Contacts"
                  name="contacts"
                  required
                  onChange={(e) => setContacts(e.target.value)}
                  id="contacts"
                />{" "}
              </Form.Group>
            </div>
            <br />
            <div className="categ d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Contact Person Name
                <Form.Control
                  type="text"
                  placeholder="Contact Person Name"
                  name="ceo"
                  required
                  onChange={(e) => setCeo(e.target.value)}
                  id="ceo"
                />
              </Form.Group>
              <br />
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Job Profile
                <Form.Control
                  type="text"
                  placeholder=" Job Profile"
                  name="jobprofile"
                  required
                  onChange={(e) => setJobprofile(e.target.value)}
                  id="jobprofile"
                />
              </Form.Group>
            </div>
            <br />
            <div className="categ d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Job Status
                <Form.Control
                  type="text"
                  placeholder="Job Status"
                  required
                  name="jobstatus"
                  onChange={(e) => setJobstatus(e.target.value)}
                  id="jobstatus"
                />
              </Form.Group>
              <br />
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                {" "}
                Email
                <Form.Control
                  type="text"
                  placeholder="Email"
                  required
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                />
              </Form.Group>
            </div>
            <br />
            <div className="categ d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Skill
                <Form.Control
                  type="text"
                  placeholder="Skill"
                  required
                  name="skill"
                  onChange={(e) => setSkill(e.target.value)}
                  id="skill"
                />
              </Form.Group>
              <br />
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Qualification
                <Form.Control
                  type="text"
                  placeholder="Qualification"
                  name="qualification"
                  required
                  onChange={(e) => setQualification(e.target.value)}
                  id="qualification"
                />
              </Form.Group>
            </div>
            <br />
            <div className="date d-flex">
              {/* <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
            AdminId
            <Form.Control type="text" placeholder="Details" />
          </Form.Group> */}
            </div>

            <div className="date d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Minimum Salary
                <Form.Control
                  type="number"
                  placeholder="minSalary"
                  name="minsalary"
                  required
                  onChange={(e) => setMinsalary(e.target.value)}
                  id="minsalary"
                />
              </Form.Group>
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                {" "}
                Maximum Salary
                <Form.Control
                  type="number"
                  placeholder="maxSalary"
                  name="maxsalary"
                  required
                  onChange={(e) => setMaxsalary(e.target.value)}
                  id="maxsalary"
                />{" "}
              </Form.Group>
            </div>
            <br />
            <div className="categ d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Category
                <Form.Control
                  type="text"
                  placeholder="Category"
                  name="category"
                  required
                  onChange={(e) => setCategory(e.target.value)}
                  id="category"
                />
              </Form.Group>

              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Subcategory
                <Form.Control
                  type="text"
                  placeholder="Subcategory"
                  name="subcategory"
                  onChange={(e) => setSubcategory(e.target.value)}
                  id="subcategory"
                />
              </Form.Group>
            </div>


            <div className="categ d-flex">

            </div>
            <br />
            <div className="categ d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Location
                <Form.Control
                  type="text"
                  placeholder="location"
                  name="location"
                  required
                  onChange={(e) => setLocation(e.target.value)}
                  id="location"
                />
              </Form.Group>

              <Form.Group
                controlId="formFile"
                className="mb-3"
                style={{ width: "500px", margin: "0px 8px" }}
              >
                <Form.Label htmlFor="upload1">profile Logo</Form.Label>
                <Form.Control
                  name="file"
                  accept="image/*"
                  type="file"
                  onChange={(e) => setProfilelogo(e.target.files[0])}
                  id="file"
                />
              </Form.Group>
            </div>

            <div className="categ d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                salaryType
                <Form.Control
                  type="text"
                  placeholder="salaryType"
                  name="salarytype"
                  required
                  onChange={(e) => setSalarytype(e.target.value)}
                  id="salarytype"
                />
              </Form.Group>

              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                city
                <Form.Control
                  type="text"
                  placeholder="city"
                  name="city"
                  required
                  onChange={(e) => setCity(e.target.value)}
                  id="city"
                />
              </Form.Group>
            </div>
            <br />
            <div className="categ d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                webSiteLink
                <Form.Control
                  type="text"
                  placeholder="webSiteLink"
                  name="websitelink"
                  required
                  onChange={(e) => setWebsitelink(e.target.value)}
                  id="websitelink"
                />
              </Form.Group>
              <br />
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Required Age
                <Form.Control
                  type="number"
                  placeholder="Required Age"
                  name="requiredage"
                  required
                  onChange={(e) => setRequiredage(e.target.value)}
                  id="requiredage"
                />{" "}
              </Form.Group>

              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Experience
                <Form.Control
                  type="text"
                  placeholder="experience"
                  name="experience"
                  required
                  onChange={(e) => setExperience(e.target.value)}
                  id="experience"
                />
              </Form.Group>
            </div>
            <br />
            {/* Event Place
        <TextArea rows={4} placeholder="Place" maxLength={6} /> */}
            <Button
              variant="primary"
              type="submit"
              style={{ margin: "10px 0px" }}
              name="submit"
              onClick={addJob}
              id="submit"
            >
              submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default View;
