import React from "react";

import Button from "react-bootstrap/Button";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
  FaBuilding,
  FaPhoneAlt,
  FaMailBulk,
  FaBookReader,
  FaCity,
  FaLocationArrow,
  FaMoneyBillAlt,
  FaMoneyCheckAlt,
  FaMoneyBillWaveAlt,
} from "react-icons/fa";
import {
  BsFillPersonCheckFill,
  BsFillPersonPlusFill,
  BsLink45Deg,
} from "react-icons/bs";
import { FcBusinessman } from "react-icons/fc";
import { TbBrandJavascript } from "react-icons/tb";
import { GiSkills } from "react-icons/gi";
import { BiCategoryAlt } from "react-icons/bi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(<BsFillPersonPlusFill />, "User Name", ":", "Ibrar", ""),
  createData(<FaPhoneAlt />, "Contact", ":", 987563417, ""),
//  createData(<BsFillPersonCheckFill />, "CEO", ":", "Ibrar", ""),
//   createData(<TbBrandJavascript />, "Job Profile", ":", "it", ""),
//   createData(<FcBusinessman />, "Job Status", ":", "maneger", ""),
  createData(<FaMailBulk />, "Email", ":", "Ibrar@123", ""),
  createData(<GiSkills />, "Skill", ":", "Node", ""),
  createData(<FaBookReader />, "Qulification", ":", " B.E", ""),
  createData(<FaLocationArrow />, "State:", ":", "Karnataka", ""),
  createData(<FaCity />, "City", ":", "bengaluru", ""),
  createData(<BsLink45Deg />, "Pincode", ":", "585105", ""),
  createData(
    <BsFillPersonPlusFill />,
    "Profile Picture",
    ":",
    <img src="./e1.jpg" />,

    ""
  ),
  createData(<BiCategoryAlt />, "Category", ":", "1b", ""),
//   createData(<BiCategoryAlt />, "Subcategory", ":", "2b", ""),
  createData(<BiCategoryAlt />, "Religion", ":", "Islam", ""),
  createData(<FaMoneyBillAlt />, "minSalary", ":", "50000", ""),
  createData(<FaMoneyCheckAlt />, "maxSalary", ":", " 7000000", ""),
//   createData(<FaMoneyBillWaveAlt />, "Salary Type", ":", "20000", ""),
// createData(<BsLink45Deg />, "webSiteLink", ":",<a href="">Ibrariuhwi8yxdihfu</a>, ""
//   ),
];

function EmployeeDetails1() {
  return (
    <>
      {" "}
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          {/* <Wcs /> */}

          <div
            className="container"
            style={{
              border:" 1px solid rgba(36, 73, 106, 0.21)",
              borderRadius: "10px",
              marginBottom: "10px",
              boxShadow:" rgb(0 0 0 / 16%) 0px 1px 4px",
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Employee Details</b>{" "}
                </h1>
              </div>
              <p align="right">
                <Button
                  type="button"
                  href="/View1"
                  style={{
                    backgroundColor: "rgb(5, 38, 78)",
                    color: "white",
                    border: "none",
                  }}
                >
                  +Edit
                </Button>
              </p>
            </div>
            <br />
            <div className="ta">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Logo</StyledTableCell>
                      <StyledTableCell align="left">Name </StyledTableCell>
                      <StyledTableCell align="right" style={{ color: "black" }}>
                        :
                      </StyledTableCell>
                      <StyledTableCell align="left">Details</StyledTableCell>

                      {/* <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.calories}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.fat}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.carbs}
                        </StyledTableCell>
                        {/* <StyledTableCell align="right">{row.protein}</StyledTableCell> */}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeDetails1;
