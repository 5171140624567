import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillCamera, AiOutlineMail } from "react-icons/ai";
import {FiPhoneCall} from "react-icons/fi"
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { RxCrossCircled } from "react-icons/rx";
import { MdOutlineDeleteForever } from "react-icons/md";

import {CgEditBlackPoint} from "react-icons/cg"
import { GrLocation } from "react-icons/gr";
import moment from "moment";
function ResumeCreate() {
  let check = JSON.parse(sessionStorage.getItem("user"));
  if (!check) {
    alert("Please login");
    window.location.assign("/employee-login");
  }
  const [resume, setResumes] = useState({});
  const [SkillSet, setSkillset] = useState([]);
  const [Langset, setlangset] = useState([]);

  const [educate, setEducate] = useState([]);
  const [achivement, setachivement] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [workandEx, setworkandEx] = useState([]);

  const loadUsers = async () => {
    const config = {
      url: "/user/getResumeByuser/" + check._id,
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setResumes(result.data.success);
        setSkillset(result.data.success?.skillSet);
        setlangset(result.data.success?.langset);

        setEducate(result.data.success?.education);
        setachivement(result.data.success?.ACHIEVEMENTS);
        setProjects(result.data.success?.addProject);
        setworkandEx(result.data.success?.workAndExperience);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  useEffect(() => {
    loadUsers();
  }, []);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [big, setBio] = useState("");
  const [title, setTit] = useState("");
  const [LANGUAGES, setLANGUAGES] = useState("");
  const [INTERESTS, setINTERESTS] = useState("");
  const [HOBBIES, setHOBBIES] = useState("");
  const [profile, setprofile] = useState("");
  const [CERTIFICATES, setCERTIFICATES] = useState("");
  //skill
  const [skill, setSkill] = useState("");
  //Education
  const [institute, setInstitutes] = useState("");
  const [edAdress, setedAdresss] = useState("");
  const [Courses, setCourse] = useState("");
  const [eduSession, seteduSession] = useState("");
  //workand experience
  const [companyName, setcompanyName] = useState("");
  const [workPlace, setworkPlace] = useState("");
  const [position, setposition] = useState("");
  const [workSession, setworkSession] = useState("");
  //project
  const [projectName, setprojectName] = useState("");
  const [projectLink, setprojectLink] = useState("");
  const [discriptiona, setdiscription] = useState("");
  const [DateOftime, setDateOftime] = useState("");

  //achivement
  const [achiveName, setachiveName] = useState("");
  const [Achuvediscription, setAchuvediscription] = useState("");
  const [Achuvesession, setAchuvesession] = useState("");

  const addAchive = async () => {
    try {
      if (!achiveName) {
        alert("Please enter achivement name");
      } else if (!Achuvediscription) {
        alert("Please enter achivement discription");
      } else if (!Achuvesession) {
        alert("Please enter achivement session");
      } else {
        const config = {
          url: "/addACHIEVEMENTSR",
          method: "put",
          baseURL: "https://univiindia.com/api/user",
          headers: { "content-type": "application/json" },
          data: {
            achiveName: achiveName,
            discription: Achuvediscription,
            session: Achuvesession,
            userId: check?._id,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("successfully added");
          loadUsers();
          setachiveName("")
          setAchuvediscription("")
          setAchuvesession("")
        } else {
          alert("Something Wrong");
        }
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const addProject = async () => {
    try {
      if (!projectName) {
        alert("Please enter project name");
      } else if (!discriptiona) {
        alert("Please enter project discription");
      } else if (!DateOftime) {
        alert("Please enter your project session");
      } else {
        const config = {
          url: "/addProject",
          method: "put",
          baseURL: "https://univiindia.com/api/user",
          headers: { "content-type": "application/json" },
          data: {
            projectName: projectName,
            discription: discriptiona,
            projectLink: projectLink,
            DateOftime: DateOftime,
            userId: check?._id,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("successfully added");
          loadUsers();
          setprojectName("")
          setdiscription("")
          setprojectLink("")
          setDateOftime("")

        } else {
          alert("Something Wrong");
        }
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const addWorkAndExperince = async () => {
    try {
      if (!companyName) {
        alert("Please enter company name");
      } else if (!workPlace) {
        alert("Please enter work place address");
      } else if (!position) {
        alert("Please enter work position");
      } else if (!workSession) {
        alert("Please enter your worksession");
      } else {
        const config = {
          url: "/addWorkExperienceR",
          method: "put",
          baseURL: "https://univiindia.com/api/user",
          headers: { "content-type": "application/json" },
          data: {
            Company: companyName,
            workPlace: workPlace,
            position: position,
            session: workSession,
            userId: check?._id,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("successfully added");
          loadUsers();
          setcompanyName("")
          setworkPlace("")
          setposition("")
          setworkSession("")
        } else {
          alert("Something Wrong");
        }
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const addEducation = async () => {
    try {
      if (!institute) {
        alert("Please enter institute name");
      } else if (!edAdress) {
        alert("Please enter institute address");
      } else if (!Courses) {
        alert("Please enter your course");
      } else if (!eduSession) {
        alert("Please enter your session");
      } else {
        const config = {
          url: "/AddEducationR",
          method: "put",
          baseURL: "https://univiindia.com/api/user",
          headers: { "content-type": "application/json" },
          data: {
            Course: Courses,
            Location: edAdress,
            starting: eduSession,
            Institue: institute,
            userId: check?._id,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("successfully added");
          loadUsers();
          setCourse("")
          setedAdresss("")
          seteduSession("")
          setInstitutes("")
        } else {
          alert("Something Wrong");
        }
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const removeWork = async (id) => {
    try {
      const config = {
        url: `/removeWorkExperienceR/${check?._id}/${id}`,
        method: "delete",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("successfully removed");
        loadUsers();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const removeAchive = async (id) => {
    try {
      const config = {
        url: `/removeACHIEVEMENTSR/${check?._id}/${id}`,
        method: "delete",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("successfully removed");
        loadUsers();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const removeEducation = async (id) => {
    try {
      const config = {
        url: `/removeEducationR/${check?._id}/${id}`,
        method: "delete",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("successfully removed");
        loadUsers();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const removeProject = async (id) => {
    try {
      const config = {
        url: `/removeproject/${check?._id}/${id}`,
        method: "delete",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("successfully removed");
        loadUsers();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const removeSkill = async (id) => {
    try {
      const config = {
        url: `/removeSkillR/${check?._id}/${id}`,
        method: "delete",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("successfully removed");
        loadUsers();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const removelang = async (id) => {
    try {
      const config = {
        url: `/removelang/${check?._id}/${id}`,
        method: "delete",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("successfully removed");
        loadUsers();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const addSkill = async () => {
    try {
      const config = {
        url: "/AddSkillR",
        method: "put",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: { skill: skill, userId: check?._id },
      };
      if (skill) {
        let res = await axios(config);
        if (res.status === 200) {
          alert("successfully added");
          setSkill("");
          loadUsers();
        } else {
          alert("Something Wrong");
        }
      } else {
        alert("please enter your skill");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const AddLang = async () => {
    try {
      const config = {
        url: "/addlang",
        method: "put",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: { LANGUAGES: LANGUAGES, userId: check?._id },
      };
      if (LANGUAGES) {
        let res = await axios(config);
        if (res.status === 200) {
          alert("successfully added");
          setLANGUAGES("");
          loadUsers();
        } else {
          alert("Something Wrong");
        }
      } else {
        alert("please enter your language");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const [summary,setSUMMARY]=useState("")

  const addWorkSummary = async () => {
    try {
      const config = {
        url: "/addSkiilSummery",
        method: "put",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: { summery: summary, userId: check?._id },
      };
      if (summary) {
        let res = await axios(config);
        if (res.status === 200) {
          alert("successfully added");
          setSUMMARY("");
          loadUsers();
        } else {
          alert("Something Wrong");
        }
      } else {
        alert("Please enter your work summary");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const addCERTIFICATES = async () => {
    try {
      if (CERTIFICATES) {
      const config = {
        url: "/addCertificateR",
        method: "put",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: { certificate: CERTIFICATES, userId: check?._id },
      };
     
        let res = await axios(config);
        if (res.status === 200) {
          alert("successfully added");
          setCERTIFICATES("");
          loadUsers();
        } else {
          alert("Something Wrong");
        }
      } else {
        alert("please enter your certificate");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const removeCertificate = async (id) => {
    try {
      const config = {
        url: `/removeCertificateR/${check?._id}/${id}`,
        method: "delete",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("successfully removed");
        loadUsers();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  const removeWorkSummary = async (id) => {
    try {
      const config = {
        url: `/removeSkiilSummery/${check?._id}/${id}`,
        method: "delete",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("successfully removed");
        loadUsers();
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const [address,setAddress]=useState("")
  const [singn,setsingn]=useState("")
  const [declearation,setdeclearation]=useState("")
  const [dateOfbirth,setdateOfbirth]=useState("")
  const updateResume = async () => {
    try {
      let obj = {
        userId: check?._id,
        name: name,
        mobile: mobile,
        email: email,
        ProfessionalSummary: big,
        profile: profile,
        Hobbies: HOBBIES,
        jobTitle: title,
        // LANGUAGES: LANGUAGES,
        INTERESTS: INTERESTS,
        dateOfbirth:dateOfbirth,
        birthofPlace:address,
        singn:singn,
        declearation:declearation,
      };
      const config = {
        url: "/editResume",
        method: "put",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      let res = await axios(config);
      if (res.status === 200) {
        window.location.assign("/dummy-resume")
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const updateProfile = async () => {
    try {
      let obj = {
        userId: check?._id,
        name: name,
        mobile: mobile,
        email: email,
        ProfessionalSummary: big,
        profile: profile,
        Hobbies: HOBBIES,
        jobTitle: title,
        // LANGUAGES: LANGUAGES,
        INTERESTS: INTERESTS,
        dateOfbirth:dateOfbirth,
        birthofPlace:address,
      };
      const config = {
        url: "/editResume",
        method: "put",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      let res = await axios(config);
      if (res.status === 200) {
          window.location.reload(true)
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  if (profile) {
    updateProfile();
  }

  //console.log(resume, "dhfgkjhgkjf");
  return (
    <>
      <div>
        <div className="q1ox_q-sides q1pm_q-box">
          <div
            className="container"
            style={{ borderBottom: "1px solid black" }}
          >
            <div className="row">
              <div className="col-md-4">
                <div className="q1zh_q-side-top q1zh_q-side-top-xslot1">
                  <div className="q1zh_q-header">
                    <div className="q1eu_q-photo q1le_q ">
                      <div
                        class="q1xr_q-photo-img-border x-t-c1-border x-t-c1"
                        style={{
                          maxWidth: "210px",
                          maxHeight: "210px",
                          width: "210px",
                          height: "210px",
                        }}
                      >
                        <div class="q1xr_q-photo-img">
                          {resume?.profile ? (
                            <img
                              src={`https://univiindia.com/resume/${resume?.profile}`}
                              alt="rj"
                              style={{
                                width: "100%",
                                borderRadius: " 50%",
                                height: "212px",
                                border: " 1px solid black",
                                // padding:"2px"
                              }}
                            />
                          ) : (
                            <img
                              src="/Images/profileIcon.png"
                              alt="rj"
                              style={{
                                width: "100%",
                                borderRadius: " 50%",
                                height: "212px",
                               
                                border: " 1px solid black",
                                padding:"10px"
                              }}
                            />
                          )}

                          <div className="camer-oi">
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="label"
                              htmlFor="upload1"
                            >
                              <input
                                hidden
                                accept="image/*"
                                type="file"
                                id="upload1"
                                name="file"
                                onChange={(e) => setprofile(e.target.files[0])}
                              />
                              <PhotoCamera
                                className="camera-rdit"
                                style={{ color: "avenderblush" }}
                              />
                            </IconButton>
                            {/* <AiFillCamera className="camera-rdit" /> */}
                          </div>
                        </div>
                      </div>
                      <div className="create-090">
                        <div className="skills-lbl pb-2">
                          <div className="save-date-08">
                            <div className="educt-09">
                              {/* <img
                                src="/Images/a-1.svg"
                                alt="educate"
                                style={{ width: "20px", height: "20px" }}
                              /> */}
                              <h3>SKILLS</h3>
                            </div>
                            <div className="save-0">
                              <button
                                class="btn btn-secondary w-100"
                                onClick={addSkill}
                              >
                                Add
                              </button>
                            </div>
                          </div>

                          <div className="q1ro_q-label-0">
                            <input
                              type="text"
                              name="skill"
                              className="skil-09"
                              placeholder="Add skill one by one.."
                              value={skill}
                              onChange={(e) => setSkill(e.target.value)}
                            />
                            <ul>
                              {SkillSet.length === 0 ? (
                                <div style={{ display: "none" }}></div>
                              ) : (
                                <>
                                  {SkillSet?.map((item) => {
                                    return (
                                      <li>
                                        {item?.skill}
                                        <span
                                          style={{
                                            "padding-left": "52px",
                                            color: "chocolate",
                                          }}
                                          onClick={() => removeSkill(item?._id)}
                                        >
                                          <RxCrossCircled />
                                        </span>
                                      </li>
                                    );
                                  })}
                                </>
                              )}
                            </ul>
                          </div>
                        </div>
                        <div className="skills-lbl pb-2">
                        <div className="save-date-08">
                            <div className="educt-09">
                              {/* <img
                                src="/Images/a-1.svg"
                                alt="educate"
                                style={{ width: "20px", height: "20px" }}
                              /> */}
                              <h3>LANGUAGES</h3>
                            </div>
                            <div className="save-0">
                              <button
                                class="btn btn-secondary "
                                onClick={AddLang}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                          
                          <div className="q1ro_q-label-0">
                         
                            <input
                              type="text"
                              name="skill"
                              className="skil-09"
                              value={LANGUAGES}
                              onChange={(e) => setLANGUAGES(e.target.value)}
                              placeholder="Add languages one by one.."
                            />
                          </div>
                          <ul>
                              {Langset.length === 0 ? (
                                <div style={{ display: "none" }}></div>
                              ) : (
                                <>
                                  {Langset?.map((item) => {
                                    return (
                                      <li>
                                        {item?.LANGUAGES}
                                        <span
                                          style={{
                                            "padding-left": "52px",
                                            color: "chocolate",
                                          }}
                                          onClick={() => removelang(item?._id)}
                                        >
                                          <RxCrossCircled />
                                        </span>
                                      </li>
                                    );
                                  })}
                                </>
                              )}
                            </ul>
                        </div>
                        <div className="skills-lbl pb-2">
                          <h3>STRENGTH</h3>
                          <div className="q1ro_q-label-0">
                            <input
                              type="text"
                              name="skill"
                              value={INTERESTS}
                              onChange={(e) => setINTERESTS(e.target.value)}
                              className="skil-09"
                              // placeholder="INTERESTS...."
                              placeholder={
                                resume?.INTERESTS
                                  ? resume?.INTERESTS
                                  : "Eg:- Focused,Creativity"
                              }
                            />
                          </div>
                        </div>
                        <div className="skills-lbl pb-2">
                          <h3>HOBBIES</h3>
                          <div className="q1ro_q-label-0">
                            <input
                              type="text"
                              name="skill"
                              className="skil-09"
                              value={HOBBIES}
                              placeholder={
                                resume?.Hobbies ? resume?.Hobbies : "Eg:- Reading book,Singing"
                              }
                              onChange={(e) => setHOBBIES(e.target.value)}
                              // placeholder="HOBBIES...."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8" style={{ borderLeft: "groove" }}>
                <div className="q1zh_q-name-con">
                  <div class="q1ro_q-full-name mb-3 ">
                    <input
                      className="skil-09-0"
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      placeholder={resume?.name}
                    ></input>
                  </div>
                  <div class="q1va_q-title">
                    <div className="hdhe-00 mb-3">
                      <input
                        className="skil-09-0"
                        type="text"
                        placeholder={
                          resume?.jobTitle
                            ? resume?.jobTitle
                            : "Eg:- Backend developer."
                        }
                        value={title}
                        onChange={(e) => setTit(e.target.value)}
                      ></input>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                    <div className="hdhe-00 mb-3" style={{display:"flex"}}>
                      <span style={{marginTop:"6px"}}><strong>D.O.B: </strong></span>
                      <input
                        className="skil-09-0"
                        type="date"
                        value={dateOfbirth}
                        onChange={(e) => setdateOfbirth(e.target.value)}
                        placeholder={resume?.dateOfbirth}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="hdhe-00 mb-3" style={{display:"flex"}}>
                      <span style={{marginTop:"6px"}}><AiOutlineMail/></span>
                      <input
                        className="skil-09-0"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={resume?.email}
                      ></input>
                    </div>
                  </div>
               
                  <div className="col-md-4">
                    <div className="hdhe-00 mb-3" style={{display:"flex"}}>
                    <span style={{marginTop:"6px"}}><FiPhoneCall/></span>
                      <input
                        className="skil-09-0"
                        type="text"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        placeholder={resume?.mobile}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="hdhe-00 mb-3" style={{display:"flex"}}>
                <span style={{marginTop:"6px"}}><GrLocation/></span>
                      <input
                        className="skil-09-0"
                        type="text"
                        placeholder={
                          resume?.birthofPlace
                            ? resume?.birthofPlace
                            : "Enter your full address."
                        }
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      ></input>
                    </div>
                    <div className="hdhe-00 mb-3">
                      <div style={{display:"flex",gap:"10px"}}>
                    <span style={{marginTop:"2px",fontSize:"14px"}}><CgEditBlackPoint/></span>
                      <h3 className="lk-nh-i">CAREER OBJECTIVES</h3></div>
                      <input
                        className="skil-09-0"
                        type="text"
                        value={big}
                        onChange={(e) => setBio(e.target.value)}
                        placeholder={
                          resume?.ProfessionalSummary
                            ? resume?.ProfessionalSummary
                            : "Short and engaging pitch about yourself...."
                        }
                        // placeholder="Short and engaging pitch about yourself...."
                      ></input>
                    </div>
                  </div>
                </div>
                
                <div className="hdhe-00 mb-3">
                  <div className="save-date-08">
                    <div className="educt-09">
                    <span style={{marginTop:"2px",fontSize:"14px"}}><CgEditBlackPoint/></span>
                      <h3 className="lk-nh-i">EDUCATION</h3>
                    </div>
                    <div className="save-0">
                      <button
                        class="btn btn-secondary w-100"
                        onClick={addEducation}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <input
                    contenteditable="true"
                    className="skil-09-0 mb-2"
                    type="text"
                    value={institute}
                    placeholder="Institution/Place of Education...."
                    onChange={(e) => setInstitutes(e.target.value)}
                  ></input>
                  <input
                    className="skil-09-0 mb-2"
                    type="text"
                    value={edAdress}
                    onChange={(e) => setedAdresss(e.target.value)}
                    placeholder="Place..."
                  ></input>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="hdhe-00 mb-3">
                        <input
                          className="skil-09-0"
                          type="text"
                          value={Courses}
                          onChange={(e) => setCourse(e.target.value)}
                          placeholder="Courses...."
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="hdhe-00 mb-3">
                        <input
                          className="skil-09-0"
                          type="text"
                          value={eduSession}
                          onChange={(e) => seteduSession(e.target.value)}
                          placeholder="2022-2023"
                        ></input>
                      </div>
                    </div>
                  </div>
                  {educate?.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      {educate?.map((item) => {
                        return (
                          <div className="">
                            <ul>
                              <li>{item?.Institue}</li>
                              <li>{item?.Location}</li>
                              <div className="row">
                                <div className="col-md-6">
                                  {" "}
                                  <li>{item?.Course}</li>
                                </div>
                                <div className="col-md-6">
                                  <li>
                                    {item?.starting}
                                    <span
                                      onClick={() => {
                                        removeEducation(item?._id);
                                      }}
                                      style={{
                                        float: "right",
                                        display: "flex",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "3px",
                                        backgroundColor: "black",
                                      }}
                                    >
                                      <MdOutlineDeleteForever
                                        style={{ color: "white" }}
                                      />
                                    </span>
                                  </li>
                                </div>
                              </div>
                            </ul>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
                <div className="hdhe-00 mb-3">
                  <div className="save-date-08">
                    <div className="educt-09">
                    <span style={{marginTop:"2px",fontSize:"14px"}}><CgEditBlackPoint/></span>
                      <h3 className="lk-nh-i">WORK EXPERIENCE</h3>
                    </div>
                    <div className="save-0">
                      <button
                        class="btn btn-secondary w-100"
                        onClick={addWorkAndExperince}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  <input
                    contenteditable="true"
                    className="skil-09-0 mb-2"
                    type="text"
                    value={companyName}
                    onChange={(e) => setcompanyName(e.target.value)}
                    placeholder="Company...."
                  ></input>
                  <input
                    contenteditable="true"
                    className="skil-09-0 mb-2"
                    type="text"
                    value={workPlace}
                    onChange={(e) => setworkPlace(e.target.value)}
                    placeholder="Work Place...."
                  ></input>
                 
                  <div className="row">
                    <div className="col-md-6">
                      <div className="hdhe-00 mb-3">
                        <input
                          className="skil-09-0"
                          type="text"
                          value={position}
                          onChange={(e) => setposition(e.target.value)}
                          placeholder="Position...."
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="hdhe-00 mb-3">
                        <input
                          className="skil-09-0"
                          type="text"
                          value={workSession}
                          onChange={(e) => setworkSession(e.target.value)}
                          placeholder="2022-2023"
                        ></input>
                      </div>
                    </div>
                  </div>
                  {workandEx?.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      {workandEx?.map((item) => {
                        return (
                          <div className="">
                            <ul>
                              <li>{item?.Company}</li>
                              <li>{item?.workPlace}</li>
                              <div className="row">
                                <div className="col-md-6">
                                  {" "}
                                  <li>{item?.position}</li>
                                </div>
                                <div className="col-md-6">
                                  <li>
                                    {item?.session}
                                    <span
                                      onClick={() => {
                                        removeWork(item?._id);
                                      }}
                                      style={{
                                        float: "right",
                                        display: "flex",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "3px",
                                        backgroundColor: "black",
                                      }}
                                    >
                                      <MdOutlineDeleteForever
                                        style={{ color: "white" }}
                                      />
                                    </span>
                                  </li>
                                </div>
                              </div>
                            </ul>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
                <div className="hdhe-00 mb-3">
                <div className="save-date-08">
                    <div className="educt-09">
                    <span style={{marginTop:"2px",fontSize:"14px"}}><CgEditBlackPoint/></span>
                      <h3 className="lk-nh-i">CERTIFICATES</h3>
                    </div>
                    <div className="save-0">
                      <button
                        class="btn btn-secondary w-100"
                        onClick={addCERTIFICATES}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <input
                    contenteditable="true"
                    className="skil-09-0 mb-2"
                    type="text"
                    value={CERTIFICATES}
                    onChange={(e) => setCERTIFICATES(e.target.value)}
                    placeholder="Eg:- Computer Diploma,Tally.."
                    
                  ></input>
                </div>
                {resume?.certificate?.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      {resume?.certificate?.map((item) => {
                        return (
                          <div className="">
                            <ul>
                  
                              <div className="row">
                                <div className="col-md-6">
                                  {" "}
                                  <li>{item?.certificate}</li>
                                </div>
                                <div className="col-md-6">
                                 
                                    
                                    <span
                                      onClick={() => {
                                        removeCertificate(item?._id);
                                      }}
                                      style={{
                                        float: "right",
                                        display: "flex",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "3px",
                                        backgroundColor: "black",
                                      }}
                                    >
                                      <MdOutlineDeleteForever
                                        style={{ color: "white" }}
                                      />
                                    </span>
                                
                                </div>
                              </div>
                            </ul>
                          </div>
                        );
                      })}
                    </>
                  )}
                <div className="hdhe-00 mb-3">
                  <div className="save-date-08">
                    <div className="educt-09">
                    <span style={{marginTop:"2px",fontSize:"14px"}}><CgEditBlackPoint/></span>
                      <h3 className="lk-nh-i">PERSONAL PROJECTS</h3>
                    </div>
                    <div className="save-0">
                      <button
                        class="btn btn-secondary w-100"
                        onClick={addProject}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="hdhe-00 mb-3">
                        <input
                          contenteditable="true"
                          className="skil-09-0 mb-2"
                          type="text"
                          value={projectName}
                          onChange={(e) => setprojectName(e.target.value)}
                          placeholder="Projects Name.."
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="hdhe-00 mb-3">
                        <input
                          className="skil-09-0"
                          type="text"
                          value={DateOftime}
                          onChange={(e) => setDateOftime(e.target.value)}
                          placeholder="2020-2022"
                        ></input>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="hdhe-00 mb-3">
                        <input
                          className="skil-09-0"
                          type="text"
                          value={discriptiona}
                          onChange={(e) => setdiscription(e.target.value)}
                          placeholder="Project Discription..."
                        ></input>
                      </div>
                    </div>
                  
                  </div>
                  {Projects?.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      {Projects?.map((item) => {
                        return (
                          <div className="">
                            <ul>
                              
                              <div className="col-md-6">
                                  <li>{item?.projectName}{" : "}
                                    ({item?.DateOftime})
                                
                                  </li>
                                </div>
                                <div >     <span
                                      onClick={() => {
                                        removeProject(item?._id);
                                      }}
                                      style={{
                                        float: "right",
                                        display: "flex",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "3px",
                                        backgroundColor: "black",
                                        marginTop: "-22px"
                                      }}
                                    >
                                      <MdOutlineDeleteForever
                                        style={{ color: "white" }}
                                      />
                                    </span></div>
                              <div className="row">
                                <div className="col-md-12">
                                  {" "}
                                  <li>{item?.discription}</li>
                                </div>
                             
                              </div>
                            </ul>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
               
                <div className="hdhe-00 mb-3">
                  <div className="save-date-08">
                    <div className="educt-09">
                    <span style={{marginTop:"2px",fontSize:"14px"}}><CgEditBlackPoint/></span>
                      <h3 className="lk-nh-i">ACHIEVEMENTS</h3>
                    </div>
                    <div className="save-0">
                      <button
                        class="btn btn-secondary w-100"
                        onClick={addAchive}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <input
                    contenteditable="true"
                    className="skil-09-0 mb-2"
                    type="text"
                    value={achiveName}
                    onChange={(e) => setachiveName(e.target.value)}
                    placeholder="Achievement Name..."
                  ></input>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="hdhe-00 mb-3">
                        <input
                          className="skil-09-0"
                          type="text"
                          value={Achuvediscription}
                          onChange={(e) => setAchuvediscription(e.target.value)}
                          placeholder="Achievement Discription......"
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="hdhe-00 mb-3">
                        <input
                          className="skil-09-0"
                          type="text"
                          value={Achuvesession}
                          onChange={(e) => setAchuvesession(e.target.value)}
                          placeholder="2020-2022"
                        ></input>
                      </div>
                    </div>
                  </div>
                  {achivement?.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      {achivement?.map((item) => {
                        return (
                          <div className="">
                            <ul>
                              <li>{item?.achiveName}</li>

                              <div className="row">
                                <div className="col-md-6">
                                  {" "}
                                  <li>{item?.discription?.slice(0, 30)}...</li>
                                </div>
                                <div className="col-md-6">
                                  <li>
                                    {item?.session}
                                    <span
                                      onClick={() => {
                                        removeAchive(item?._id);
                                      }}
                                      style={{
                                        float: "right",
                                        display: "flex",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "3px",
                                        backgroundColor: "black",
                                      }}
                                    >
                                      <MdOutlineDeleteForever
                                        style={{ color: "white" }}
                                      />
                                    </span>
                                  </li>
                                </div>
                              </div>
                            </ul>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
                <div className="hdhe-00 mb-3">
                <div className="save-date-08">
                    <div className="educt-09">
                    <span style={{marginTop:"2px",fontSize:"14px"}}><CgEditBlackPoint/></span>
                      <h3 className="lk-nh-i">WORK SUMMARY </h3>
                    </div>
                    <div className="save-0">
                      <button
                        class="btn btn-secondary w-100"
                        onClick={addWorkSummary}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <input
                    contenteditable="true"
                    className="skil-09-0 mb-2"
                    type="text"
                    value={summary}
                    onChange={(e) => setSUMMARY(e.target.value)}
                    placeholder="Eg:- Knowledge on Agile methodology and all the meeting."
                    
                  ></input>
                </div>
                {resume?.SkiilSummery?.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      {resume?.SkiilSummery?.map((item) => {
                        return (
                          <div className="">
                            <ul>
                  
                              <div className="row">
                                <div className="col-md-6">
                                  {" "}
                                  <li>{item?.summery}</li>
                                </div>
                                <div className="col-md-6">
                                 
                                    
                                    <span
                                      onClick={() => {
                                        removeWorkSummary(item?._id);
                                      }}
                                      style={{
                                        float: "right",
                                        display: "flex",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "3px",
                                        backgroundColor: "black",
                                      }}
                                    >
                                      <MdOutlineDeleteForever
                                        style={{ color: "white" }}
                                      />
                                    </span>
                                
                                </div>
                              </div>
                            </ul>
                          </div>
                        );
                      })}
                    </>
                  )}
                   <div className="hdhe-00 mb-3">
                <div className="save-date-08">
                    <div className="educt-09">
                    <span style={{marginTop:"2px",fontSize:"14px"}}><CgEditBlackPoint/></span>
                      <h3 className="lk-nh-i">DECLARATION</h3>
                    </div>
                
                  </div>
                  <input
                    contenteditable="true"
                    className="skil-09-0 mb-2"
                    type="text"
                    value={declearation}
                    onChange={(e) => setdeclearation(e.target.value)}
                    placeholder={resume?.declearation ? (resume?.declearation):("Eg:- I hereby declare that the above information furnished by me is true to the best of my knowledge and belief.")}
                    
                  ></input>
                </div>
                <div className="row">
                 
                      
                    <div >
                      <h8>Signature of the Candidate</h8>
                      <div className="col-md-3" style={{textAlign:"right"}}>
                    <div className="hdhe-00 mb-3" style={{display:"flex",}}>
                      {/* <span style={{marginTop:"6px"}}><AiOutlineMail/></span> */}
                      <input
                        className="skil-09-0"
                        type="email"
                        value={singn}
                        onChange={(e) => setsingn(e.target.value)}
                        placeholder={resume?.singn ? (resume?.singn):("Please enter your name....")}
                      ></input>
                    </div>
                  </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <a>
            <button type="button" class="btn btn-dark" onClick={updateResume}>
              Submit
            </button>
          </a>
        </div>
      </div>
    </>
  );
}

export default ResumeCreate;
