import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import './MajorProduct.scoped.scss';
import Wcs from "./Wcs1";
import axios from "axios";
import { Link } from "react-router-dom";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import exportFromJSON from "export-from-json";
import moment from "moment";
import ReactPaginate from "react-paginate";

function Verified1() {
  
  const [unVerify, setUnVerify] = useState([]);
  const [Selectall, setSelectall] = useState(false);
  

  const [singleselected, setsingleselect] = useState([]);
  const getVerifiedList = async () => {
    let res = await axios.get("https://univiindia.com/api/user/getAllJobs");
    if (res.status === 200) {
      //console.log(res.data);
      setUnVerify(res.data.success);
    }
  };

  useEffect(() => {
    getVerifiedList();
  }, []);
  const data=[]
  if (unVerify?.length !== 0) {
    for (let i = 0; i < unVerify.length; i++) {
      data.push({
        JobID :unVerify[i]?._id,
        CompanyName: unVerify[i]?.companyName,
        ContactPersonName: unVerify[i]?.interviewername,
        Email: unVerify[i]?.email,
        Contact: unVerify[i]?.whatsapp,
        Category: unVerify[i]?.category,
        JobProfile: unVerify[i]?.jobProfile,
       
        PostBy: unVerify[i]?.employerId?.name ? unVerify[i]?.employerId?.name : "Admin",
        PostedOn: moment(unVerify[i]?.updatedAt).fromNow(),
      });
    }
  }

  const exportType = "xls";
  const [fileName, setfileName] = useState("Approved Jobs");

  const ExportToExcel = () => {
        exportFromJSON({ data, fileName, exportType });
  };
  const deletejob = async (id) => {
    let res = await axios.delete(
      "https://univiindia.com/api/user/deleteJob/" + id
    );
    if (res.status === 200) {
      alert(res.data.success);
      getVerifiedList();
    }
  };
  const makeBlock = async (id) => {
    try {
      const config = {
        url: "/makeBlockUnBlockCompany",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: { userId: id, isBlock: true },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // alert("Successfully blocked");
        window.location.reload();
        getVerifiedList();
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const makeUnBlock = async (id) => {
    try {
      const config = {
        url: "/makeBlockUnBlockCompany",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: { userId: id, isBlock: false },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // alert("Successfully unblocked");
        window.location.reload();
        getVerifiedList();
      }
    } catch (error) {
      //console.log(error);
    }
  };
  //console.log(unVerify, "jhhtrrtttrttr");
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    unVerify.length /
      usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  function Blockbtn() {

    for(let i =0 ;i <singleselected.length ; i++){
    
  makeBlock(singleselected[i])
  
  
    }
  }
  function Unblockbtn() {
  
    for(let i =0 ;i <singleselected.length ; i++){
    
  makeUnBlock(singleselected[i])
    }
  }
  const handleCheckboxSelect = (event, data) => {
    if (event.target.checked) {
      setsingleselect([...singleselected, data]);
      
    
    // setshowMoreId(singleselected?.includes(data),"true/false")
      console.log(singleselected,"jjjj");
    } else {
      setsingleselect(singleselected?.filter(id => id !== data));
      

    }
  };
 console.log(singleselected,"fdfdfdfdfd");

  const handleSelectAll = (event) => {
    
    if (event.target.checked) {
      setSelectall(true)
      setsingleselect(...singleselected, unVerify?.map(item => item._id));
   
    } else {
      setsingleselect([]);
      setSelectall(false)
    
    }
  };
 
  return (
    <>
    <div className="townhub">
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          {/* <Wcs /> */}
          <div
            className="container"
            style={{
              border: "1px solid rgba(36, 73, 106, 0.21)",
              borderRadius:" 10px",
              marginBottom: "10px",
              boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform">
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                    textAlign: "center",
                  }}
                >
                  <b>Approved Jobs</b>
                </h1>
                {/* <button onClick={ExportToExcel} style={{backgroundColor:'green',color:'white',float:"right"}}>Export to Excel</button> */}
              </div>
            </div>
            {/* <p align="right">
                  <Link to={`/addjob1`}>
                    <Button
                      type="button"
                      style={{
                        backgroundColor: "rgb(5, 38, 78)",
                        color: "white",
                        border: "none",
                      }}
                    >
                      Post Job +
                    </Button>
                  </Link>
                </p> */}
               <p align="right" >
           
           <Button
             variant="primary"
             type="button"
             value="ShortList"
             onClick={ExportToExcel}
             style={{
               backgroundColor: "rgb(5, 38, 78)",
               color: "white",
               border: "none",
             }}
           >
             <b style={{ color: "white"}}>Export to Excel</b>
           </Button>
        </p>
            <div className="tble">
            <div style={{display:'flex',flexDirection:'row',margin:"20px"}}>
            <input onChange={handleSelectAll}   type="checkbox"  style={{marginLeft:"20px"}}/>
          
                <Button class="btn btn-outline-primary" onClick={Blockbtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Block{" "}
                 
                </Button>
                <Button class="btn btn-outline-primary" onClick={Unblockbtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Unblock{" "}
                 
                </Button>
           
                
           </div> 
              <table style={{ width: "100%" }}>
                <tr>
                  <th></th>
                  <th>Job ID</th>
                  <th>Company Name</th>
                  <th>Job Title</th>
                 
                  <th>Job owner</th>
                  <th>Posted on</th>
                  <th>View</th>
                  {/* <th>Delete</th> */}
                </tr>
                {unVerify.slice(pagesVisited, pagesVisited + usersPerPage).map((item) => {
                  return (
                    <tr>
                     <td> {Selectall ? <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)} checked={Selectall} /> : <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)}  />}</td>

                      <td>
                        {" "}
                       {item._id.slice(18,24)}
                      </td>
                      <td>
                        <a > {item?.companyName}</a>
                      </td>{" "}
                      <td>
                        <a >{item?.jobProfile} </a>
                      </td>{" "}
                      
                      <td>
                        <a >{item?.employerId ? (<Link to={`/adminemployeeDetailsView/${item?.employerId?._id}`}>{item?.employerId?.name}</Link>):("Admin")}</a>
                      </td>
                      <td> {moment(item?.updatedAt).fromNow()}</td>
                      <td>
                        <Link to={`/admincompanyDetails2/${item?._id}`}>
                          <Button variant="outline-primary">View</Button>{" "}
                        </Link>
                        {item?.isBlock === false ? (
                        <button
                          type="button"
                          class="btn btn-danger"
                          onClick={() => makeBlock(item?._id)}
                        >
                          Block
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="btn btn-warning"
                          onClick={() => makeUnBlock(item?._id)}
                        >
                          Unblock
                        </button>
                      )}
                      </td>
                      {/* <td>
                        {" "}
                        <Button
                          variant="danger"
                          onClick={() => deletejob(item?._id)}
                          style={{ color: "white" }}
                        >
                          Delete
                        </Button>{" "}
                      </td> */}
                    </tr>
                  );
                })}
              </table>
              <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
                     <p align="right" style={{marginBottom:"1em"}}>
           
           <Button
             variant="primary"
             type="button"
             value="ShortList"
             onClick={ExportToExcel}
             style={{
               backgroundColor: "rgb(5, 38, 78)",
               color: "white",
               border: "none",
             }}
           >
             <b style={{ color: "white"}}>Export to Excel</b>
           </Button>
        </p>
            </div>
            {/* table */}
            {/* <Button
          variant="primary"
          type="submit"
          style={{ margin: "20px 470px" }}
        >
          submit
        </Button> */}
            {/* img */}
          </div>
       
        </div>
        
      </div>
     </div>
    </>
  );
}

export default Verified1;
