import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { BiUserCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';

function Tabel() {
  const employer=JSON.parse(sessionStorage.getItem("employer"))
  const [unVerify, setUnVerify] = useState([]);

  const getVerifiedList = async () => {
    let res = await axios.get("https://univiindia.com/api/user/AllAplliedDetals");
    if (res.status === 200) {
      //console.log(res.data);
      setUnVerify(res.data.success);
    }
  };
  useEffect(()=>{
    getVerifiedList();
  },[])
  return (
    <Table striped bordered hover size="lg" style={{ width: "100%",zIndex:"auto" }}>
      <thead>
        <tr>
          <th>Job ID</th>
          <th>Employee Name</th>
          <th>Email</th>
         
          <th>Applied on</th>
        </tr>
      </thead>
      <tbody>
        {unVerify?.filter((ele) => ele?.companyId?.employerId === employer?._id) ?.filter((el) => el?.status === "Applied").map((item,i)=>{
          if(i<=2){
            return(
              <tr>
              <td>
                {" "}
                {item?.companyId?._id.slice(18,24)}
               
              </td>
              <td>
                <Link
                  to={`/user-profile-view1/${item?.userId?._id}`}
                  name="name"
                  id="name"
                >
                  {item?.userId?.name}
                </Link>
              </td>
              <td>
                <Link to={`/user-profile-view1/${item?.userId?._id}`}>
                  {item?.userId?.email}
                </Link>
              </td>
              
              <td>{moment(item?.updatedAt).fromNow()}</td>
            </tr>
            )
          }
        })}
       
      </tbody>
       
     
    </Table>
  );
}

export default Tabel;