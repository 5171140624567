import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Form } from "react-router-dom";
import Wcs from "./Wcs";

import { CiLocationOn } from "react-icons/ci";
import { HiOutlineBriefcase } from "react-icons/hi";
import { BsStopwatch } from "react-icons/bs";
import { BiRefresh } from "react-icons/bi";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { selectUnstyledClasses } from "@mui/base";

function FindUser() {
  let check = JSON.parse(sessionStorage.getItem("employer"));

  const [user, setUser] = useState([]);
  const [search, setsearch] = useState([]);
  const [searchView, setSearchView] = useState(false);
  const [city, setcity] = useState("");
  const [category, setcategory] = useState("");
  const [jobProfile, setjobProfile] = useState("");
  const [value, setValue] = useState("vxfdffxf");

  const [job, setUnVerify] = useState([]);

  const [SelectedCategory, setSelectedCategory] = useState();
  // const unique = [...new Set(job.map((item) => item?.category))];
  const loadUsers1 = async () => {
    const config = {
      url: "/user/getEmployerById/" + check?._id,
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        sessionStorage.removeItem("employer");
        sessionStorage.setItem("employer", JSON.stringify(result.data.success));
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };

  useEffect(() => {
    loadUsers();
    loadUsers1();
    searchfillter();
    getVerifiedList();
    // getJobList();
  }, []);

  //console.log(user, "user");
  const loadUsers = async () => {
    const config = {
      url: "/user/getAllProfile",
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);

      if (result.status === 200) {
        setUser(result.data.success);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  // const getJobList = async () => {

  //   let res = await axios.get("https://univiindia.com/api/user/AllAplliedDetals");
  //   if (res.status === 200) {
  //     //console.log(res.data);
  //     setUser(res.data.success.filter((ele) => ele?.companyId?.employerId === check?._id));
  //   }
  // };
  const check1 = () => {
    if (!category) {
      if (jobProfile) {
        alert("Please select category!");
      }
    }
  };
  //console.log(jobProfile, "dfsdfsd");
  const searchfillter = async (e) => {
    // e.preventDefault();
    let obj = {};
    // if(!category){
    //   obj["category"]="not"
    // }
    // if(category){
    //   obj["category"]=category
    // }
    // if(city){
    //   obj["city"]=city
    // }
    if (jobProfile) {
      obj["industry"] = jobProfile;
    }
    const config = {
      url: "/user/getUserByFillter",
      method: "post",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
      data: obj,
    };
    try {
      const result = await axios(config);

      if (result.status === 200) {
        setsearch(result.data.success);
        //console.log(result.data.success, "ghjdfghjdfkg");
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
      setsearch([]);
    }
  };

  const getVerifiedList = async () => {
    let res = await axios.get(
      "https://univiindia.com/api/user/getJobByEmployerId/" + check?._id
    );
    if (res.status === 200) {
      //console.log(res.data);
      setUnVerify(res.data.success);
      searchfillter();
    }
  };

  const handleInputSearchChange = (e) => {
    setSearchView(true);
    setjobProfile(e.target.value);
  };
  const unique = [...new Set(job?.map((item) => item?.category))];
  //console.log(value, "value");
  return (
    <>
      {check.status !== "Approved" ? (
        <div style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
          <br></br>
          <h3>Admin Not approved your profile please update.</h3>{" "}
          {check?.reasion ? <p>{check?.reasion}</p> : <></>}
        </div>
      ) : (
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            {/* <Wcs /> */}

            {/* <div
           className="container img"
           style={{
             border: "1px solid #24496a",
             background: "#f1f4fb",
             marginTop: "30px",
             borderRadius: "10px",
             height: "500px",
             overflow: "scroll",
             width: "100%",
           }}
         ></div> */}
            {/* ======================= */}
            <div className="">
              <section className="section-box-2">
                <div className="container">
                  <div
                    className="banner-hero banner-single banner-single-bg"
                    style={{
                      padding: " 40px 20px 60px",
                      borderRadius: " 8px",
                      backgroundColor: "unset",
                      border: " 1px solid #00000047",
                      position: "relative",
                      boxShadow:
                        " rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px",
                    }}
                  >
                    <div className="block-banner text-center">
                      <h3 class="wow animate__animated animate__fadeInUp">
                        <span class="color-brand-2">Employees</span> Available
                        Now
                      </h3>
                      <div className="font-sm color-text-paragraph-2 mt-10 wow animate__animated animate__fadeInUp">
                        Find recent employees According to your job and apply to
                        filter,
                        <br className="d-none d-xl-block" />
                        "Available employees according job?"
                      </div>
                      <div className="form-find text-start mt-40 wow animate__animated animate__fadeInUp">
                        <form>
                          {/* <div className="box-industry">
                     <select
                       class="form-input mr-10  input-industry"
                   
                       value={SelectedCategory}
                       onChange={(e) => {
                         setSelectedCategory(e.target.value);
                         setcategory(e.target.value)
                       }}
                     >
                       <option value="">Select Category</option>
                       {unique.map((cat, ind) => {
                         return <option value={cat}>{cat}</option>;
                       })}
                     </select>
                   </div>

                   <div className="box-industry">
                     <select
                       class="form-input mr-10 select-active input-location"
                      
                       value={city}
                       onChange={(e) => {
                         setcity(e.target.value)
                       }}
                     >
                       <option >Select City</option>
                       {SelectedCategory ? (   user?.filter((item) =>item?.industry  === SelectedCategory)
                         .map((data, ind) => {
                           return (
                             <option value={data?.city}>{data?.city}</option>
                           );
                         })):(<></>)}
                    
                     </select>
                   </div> */}
                          <input
                            class="form-input input-keysearch mr-10"
                            type="text"
                            placeholder="Enter job category..."
                            value={jobProfile}
                            onChange={handleInputSearchChange}
                          ></input>
                          {searchView ? (
                            <div
                              className="BoxShadow1"
                              style={{
                                position: "absolute",
                                zIndex: 1000,
                                background: "white",
                                width: "98%",
                                marginTop: "35px",
                                padding: "2px",
                                borderBottom: "2px solid #2bb792",
                              }}
                            >
                              {unique
                                .filter((item) => {
                                  if (jobProfile == "") {
                                    return null;
                                  } else if (
                                    item
                                      .toLowerCase()
                                      .includes(jobProfile.toLowerCase())
                                  ) {
                                    return item;
                                  }
                                })
                                .map((item) => (
                                  <div className="searchItem">
                                    {" "}
                                    <Link
                                      onClick={() => (
                                        setValue(item),
                                        setjobProfile(""),
                                        setSearchView(false)
                                      )}
                                    >
                                      <h6 style={{ textAlign: "left" }}>
                                        {item}
                                      </h6>
                                    </Link>
                                  </div>
                                ))}
                            </div>
                          ) : null}
                          {/* <button
                            class="btn btn-default btn-find font-sm"
                            // onClick={(e) => {
                            //   searchfillter(e);
                            // }}
                          >
                            Search
                          </button> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* filterpart */}
              <div className="section-box mt-30">
                <div className="container">
                  <div className="row ">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                      <div className="sidebar-shadow none-shadow mb-30">
                        <div className="content-page">
                          <div className="box-filters-job">
                            <div className="row">
                              {user
                                .filter(
                                  (item) =>
                                    item.industry[0] === value ||
                                    item.industry[1] === value ||
                                    item.industry[2] === value ||
                                    item.industry[3] === value ||
                                    item.industry[4] === value
                                )
                                .map((items, index) => {
                                  return (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                                      <div class="card-grid-2 hover-up">
                                        <div class="card-grid-2-image-left">
                                          <div
                                            class="image-box"
                                            style={{ width: "33%" }}
                                          >
                                            <a
                                              href={`/user-profile-view/${items?._id}`}
                                            >
                                              {items?.profile ? (
                                                <img
                                                  src={`https://univiindia.com/user/${items?.profile}`}
                                                  alt="jobBox"
                                                  style={{
                                                    width: "65px",
                                                    height: "65px",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src="./Images/profileIcon.png"
                                                  alt="jobBox"
                                                  style={{
                                                    width: "65px",
                                                    height: "65px",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              )}
                                            </a>
                                          </div>

                                          <div class="right-info">
                                            <span class="location-small">
                                              {" "}
                                              <span
                                                style={{
                                                  paddingRight: "8px",
                                                }}
                                              >
                                                <CiLocationOn />
                                              </span>
                                              {items?.city}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="card-block-info">
                                          <h6>
                                            <a
                                              href={`/user-profile-view/${items._id}`}
                                            >
                                              {items?.email}
                                            </a>
                                          </h6>
                                          <div class="mt-3">
                                            <span class="card-briefcase">
                                              <span
                                                style={{
                                                  paddingRight: "4px",
                                                }}
                                              >
                                                <HiOutlineBriefcase />
                                              </span>
                                              {items?.mobile}
                                            </span>
                                            {/* <span class="card-time">
                                              
                                              <span
                                                style={{
                                                  paddingRight: "4px",
                                                }}
                                              >
                                                <BsStopwatch />
                                              </span>
                                              {moment(
                                                items?.updatedAt
                                              ).fromNow()}
                                              
                                            </span> */}
                                          </div>
                                          {/* <a
                                            href={`/user-profile-view/${items._id}`}
                                          >
                                            <p class="font-sm color-text-paragraph mt-3 mb-3">
                                              {items?.bio?.slice(0, 30)}
                                            </p>
                                          </a> */}
                                          {/* <div class="mt-30 mb-3">
                                          
                                            {items?.skillSet?.map((ele, i) => {
                                              {
                                                if (i < 6) {
                                                  return (
                                                    <a
                                                      class="btn btn-grey-small mr-5"
                                                      href={`/user-profile-view/${items?._id}`}
                                                    >
                                                      {ele?.skill}
                                                    </a>
                                                  );
                                                }
                                              }
                                            })}
                                          </div> */}
                                          <div class="card-2-bottom mt-30">
                                            <div class="row">
                                              {/* <div class="col-lg-7 col-7">
                                                <span class="card-text-price">
                                                  {items?.country}
                                                </span>
                                                <span class="text-muted"></span>
                                              </div> */}
                                              {/* <div class="col-lg-5 col-5 text-end">
                                                    <div
                                                      class="btn btn-apply-now"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#ModalApplyJobForm"
                                                      onClick={() => {
                                                        applyJob(
                                                          items?._id,
                                                          items?.name,
                                                          items?.email
                                                        );
                                                      }}
                                                    >
                                                      Interested?
                                                    </div>
                                                  </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* =================== */}
          </div>
        </div>
      )}
    </>
  );
}

export default FindUser;
