import React from "react";
import Viewprofile from "./Viewprofile";
import Wcs from "./Wcs";
import Button from "react-bootstrap/Button";

function Profiledetails() {
  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
      

          <div
            className="container"
            style={{
              border: "1px solid #24496a",
              background: "#f1f4fb",
              marginTop: "30px",
              borderRadius: "10px",
              height: "500px",
              overflow: "scroll",
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Profile Details</b>
                </h1>
                <p align="right">
                  <Button
                    type="button"
                    href="/View"
                    style={{backgroundColor: "rgb(5, 38, 78)",
                    color: "white",
                    border: "none" }}
                    
                  >
                    +Edit
                  </Button>
                </p>
              </div>
            </div>
            {/*  */}
            <div className="row">
              <div className="col-md-4">
                <Viewprofile />
              </div>
              <div className="col-md-2">
                <div
                  id="profileDetailsWrap"
                  style={{
                    textAlign: "right",
                    marginTop: "100px",
                    color: "black",
                  }}
                >
                  <div class="profileLable">
                    <ul>
                      <h5>Name:</h5>
                      <br />
                      {/* <h5>Registered As:</h5> */}
                      <h5>Email:</h5>
                      <br />
                      {/* <h5>Alternate Email:</h5> */}
                      <h5>Mobile:</h5>
                      <br />
                      <h5>Password:</h5>
                      <br />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div class="profileDetailBox">
                  <ul
                    style={{
                      fontSize: "12px",
                      marginTop: "100px",
                      color: "black",
                    }}
                  >
                    <h5>Bishwajit Adityadev &nbsp;</h5>
                    <br />
                    <h5>
                      <span id="currentEmail">bishuadityadev33@gmail.com</span>
                    </h5>
                    <br />
                    <h5>
                      <span
                        id="currentAlternateEmail"
                        style={{ display: "none" }}
                      ></span>
                      <span
                        class="verifiedCNo"
                        id="alternateEmailVerify"
                        style={{ display: "none" }}
                      >
                        Verified
                      </span>
                    </h5>
                    <h5>
                      <span id="currentMobile">7541086135</span>
                      {/* <span class="verifiedCNo">Verified</span>
                      <a href="" class="link-click link-click__update">
                        Change Mobile
                      </a> */}
                    </h5>
                    <br />
                    <h5>
                      Not shown due to security reasons
                      <a
                        href="/editlogindetails"
                        class="link-click link-click__update"
                        style={{ color: "red", marginLeft: "8px" }}
                      >
                        Change Password
                      </a>
                    </h5>
                    <br />
                    <h5 id="deactivationLink">
                      <a
                        href="javascript:userprofilePopup('deactivate-Account')"
                        class="link-click link-click__deactivate"
                      >
                        Deactivate account
                      </a>
                    </h5>
                  </ul>
                </div>
              </div>
              {/* <div className="col-md-2" style={{ marginTop: "70px", textAlign:"right" }}>
                <h5>Name:</h5>
                <h5>Company Name:</h5>
                <h5>Email:</h5>
                <h5>Contact:</h5>
                <h4>Password:</h4>
              </div>
              <div className="col-md-2" style={{ marginTop: "70px", }}>
                <h5>Bishwajit Adityadev &nbsp;</h5>
                <h5>infosy</h5>
                <h5>bishuadityadev33@gmail.com</h5>
                <h5>9863564321</h5>
                <h4>Not shown due to security reasons <a href="">Change Password</a></h4>
              </div> */}
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Profiledetails;
