import "./subadmin.css";
import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";

import axios from "axios";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { HiOutlinePencilAlt } from "react-icons/hi";
import Button from "react-bootstrap/Button";

import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Form } from "react-bootstrap";

function SubAdmin() {
  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [Delete, setDelete] = useState();
  const [modalData, setmodalData] = useState({});

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    setDelete(data);
  };

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);
  const [dataSource, setdataSource] = useState([]);

  const [home, sethome] = useState(false);
  const [employer, setemployer] = useState(false);
  const [employees, setemployees] = useState(false);
  const [ourclients, setourclients] = useState(false);
  const [postedjob, setpostedjob] = useState(false);
  const [payments, setpayments] = useState(false);
  const [graphics, setgraphics] = useState(false);
  const [notification, setnotification] = useState(false);


  const [subadmin, setsubadmin] = useState(false);
  const [category, setcategory] = useState(false);
  const [verifiedjobs, setverifiedjobs] = useState(false);
  const [appliedJobs, setappliedJobs] = useState(false);
  const [feedback, setfeedback] = useState(false);

  const [testimonial, settestimonial] = useState(false);

  const [bulkEnquiry, setbulkEnquiry] = useState(false);
  const [accountstatement, setaccountstatement] = useState(false);
  const [askforprice, setaskforprice] = useState(false);

  const [vendor, setvendor] = useState(false);
  // const [purchase, setpurchase] = useState(false);

  const [customer, setcustomer] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [home1, sethome1] = useState(false);
  const [applieddetails1, setapplieddetails1] = useState(false);
  const [postjob1, setpostjob1] = useState(false);
  const [promocode1, setpromocode1] = useState(false);
  const [verifiedJob1, setverifiedJob1] = useState(false);
  const [ourClient1, setourClient1] = useState(false);
  const [graphics1, setgraphics1] = useState(false);
  const [notification1, setnotification1] = useState(false);


  const [subadmin1, setsubadmin1] = useState(false);
  const [industries, setindustries] = useState(false);
  const [intrested, setintrested] = useState(false);
  const [industries1, setindustries1] = useState(false);
  const [intrested1, setintrested1] = useState(false);
  const [category1, setcategory1] = useState(false);
  const [services1, setservices1] = useState(false);
  const [adhocinvoice1, setAdhocInvoice1] = useState(false);
  const [employer1, setemployer1] = useState(false);

  const [testimonial1, settestimonial1] = useState(false);

  const [bulkEnquiry1, setbulkEnquiry1] = useState(false);
  const [accountstatement1, setaccountstatement1] = useState(false);
  const [askforprice1, setaskforprice1] = useState(false);

  const [employees1, setemployees1] = useState(false);
  // const [purchase, setpurchase] = useState(false);

  const [customer1, setcustomer1] = useState(false);

  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(dataSource.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };
  const setModalIsOpenToTrue1 = (data) => {
    setmodalData(data);
    setModalIsOpen1(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const setModalIsOpenToFalse1 = () => {
    setModalIsOpen1(false);
  };
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [mobile, setMobile] = useState();
  const [name1, setName1] = useState();
  const [email1, setEmail1] = useState();
  const [password1, setPassword1] = useState();
  const [mobile1, setMobile1] = useState();

  const ClearInput = () => {
    setName();
    setEmail();
    setPassword();
    setMobile();
    setName1();
    setEmail1();
    setPassword1();
    setMobile1();
  };

  let check = JSON.parse(sessionStorage.getItem("admin"));
  if (!check) {
    alert("Please login");
    window.location.assign("/admindashboard");
  }
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() +
    1}/${current.getFullYear()}`;

  // var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  function validemail(inputtxt) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // var no = /^\d{10}$/;
    if (inputtxt.match(mailformat)) {
      return true;
    } else {
      alert("You have entered an invalid email!");
      return false;
    }
  }
  const addAdmin = async () => {
    if (!name) {
      alert("Please enter the name ");
    } else if (!email) {
      alert("Please entered a valid email");
    } else if (!password) {
      alert("Please enter the password");
    } else if (!mobile || mobile.length !== 10) {
      alert("Please enter the mobile number in write format ");
    } else if (
     
      !graphics &&
      !subadmin &&
      !category &&
      !industries &&
      !intrested &&
    
    !notification &&
      !appliedJobs &&
      !verifiedjobs &&
      !postedjob &&
      !ourclients &&
      !employer &&
      !employees 
    ) {
      alert("Please select the access module");
    } else {
      try {
        const config = {
          url: "/admin/signup",
          method: "post",
          baseURL: "https://univiindia.com/api",
          hearder: { "content-type": "application/json" },

          data: {
            name: name,
            email: email,
            password: password,
            mobile: mobile,

            subadmin: subadmin,
            category: category,
            industries: industries,
            intrested: intrested,
            home: home,
            graphics: graphics,
            notification:notification,
            appliedDetails: appliedJobs,
            PostedJob: postedjob,
            verifiedJob: verifiedjobs,
            ourClient: ourclients,
            employee: employees,
            employer: employer,
            // purchase:purchase,
            // customer: customer,
            adminId: check?._id,
            date: date,
          },
        };
        await axios(config).then(function(res) {
          if (res.status === 200) {
            //console.log(res.data);
            setShow4(false);
            AllSubadmin();
            alert("Subadmin created");
            ClearInput();
          } else {
            alert("Something Wrong");
          }
        });
      } catch (error) {
        //console.log(error.response);
        alert(error.response.data.error);
      }
    }
  };
  const editsubadmin = async () => {
    try {
      const config = {
        url: "/admin/udateSubAdmin",
        method: "put",
        baseURL: "https://univiindia.com/api",
        hearder: { "content-type": "application/json" },

        data: {
          subadminId: modalData._id,
          name1: name1 ? name1 : modalData.name,
          email1: email1 ? email1 : modalData.email,
          password1: password1 ? password1 : modalData.password,
          mobile1: mobile1 ? mobile1 : modalData.mobile,

          subadmin1: subadmin1 ? subadmin1 : modalData.subadmin,
          category1: category1 ? category1 : modalData.category,

          home1: home1 ? home1 : modalData.home,
          appliedDetails1: applieddetails1
            ? applieddetails1
            : modalData.appliedDetails,
          PostedJob1: postjob1 ? postjob1 : modalData.PostedJob,
          industries1: industries1 ? industries1 : modalData.industries1,
          intrested1: intrested1 ? intrested1 : modalData.intrested,
          verifiedJob1: verifiedJob1 ? verifiedJob1 : modalData.verifiedJob,
          ourClient1: ourClient1 ? ourClient1 : modalData.ourClient,
          graphics1: graphics1 ? graphics1 : modalData.graphics,
          notification1: notification1 ? notification1 : modalData.notification,
          employee1: employees1 ? employees1 : modalData.employee,
          employer1: employer1 ? employer1 : modalData.employer,
          adminId: check?._id,

          date: date,
        },
      };
      await axios(config).then(function(res) {
        if (res.status === 200) {
          //console.log(res.data);
          setModalIsOpen(false);
          AllSubadmin();
          setModalIsOpenToFalse1(false);
          alert("Subadmin Updated");
          ClearInput();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      //console.log(error.response);
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    AllSubadmin();
  }, []);

  const AllSubadmin = async () => {
    let res = await axios.get("https://univiindia.com/api/admin/getallsubadmin");
    if (res.status === 200) {
      //console.log(res);
      setdataSource(res.data.subAdminList);
    }
  };

  const deletesubadmin = async () => {
    try {
      axios
        .post(`https://univiindia.com/api/admin/deletesubadmin/` + Delete._id)
        .then(function(res) {
          if (res.status === 200) {
            setShow1(false);
            AllSubadmin();
          }
        });
    } catch (error) {
      //console.log(error);
    }
  };

  const [viewItems, setviewItems] = useState({});
  const [AccessModules, setAccessModules] = useState({});

  const clicked = (data) => {
    setviewItems(data);
    setAccessModules(data.accessmodules);
    //console.log(data);
    handleShow();
  };

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = dataSource.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setdataSource([...dataSource]);
    }
  };

  //console.log(dataSource);

  return (
    <div className="trev">
      <div>
        <div
          className="py-3 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
          style={{ minHeight: "100vh", height: "100%", background: "white" }}
        >
          <div className="TabLayout">
            <div className="container">
              <div
                className=" row3"
                style={{
                  left: "26%",
                  padding: "12px",
                  width: "73%",
                  position: "absolute",
                  zIndex: "999",
                  overflow: "hidden",
                  overflowY: "scroll",
                  height: "500px",
                  border: "1px solid rgba(36, 73, 106, 0.21)",
                  borderRadius: "10px",
                  marginBottom: "10px",
                  boxShadow: " rgb(0 0 0 / 16%) 0px 1px 4px",
                }}
              >
                <div className="d-flex justify-content-between align-items-center p-2 my-2 bg-light  col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div style={{ padding: "2% 1%", width: "25%" }}>
                    <button
                      style={{
                        backgroundColor: "rgb(43, 183, 146)",
                        padding: "10px",
                        color: "white",
                        border: "1px solid white",
                        fontWeight: "600",
                        borderRadius: "10px",
                      }}
                    >
                      ALL SUBADMIN LIST
                    </button>
                  </div>
                  <div className="textOrange">
                    <button onClick={handleShow4} className="btn btn-primary">
                      {" "}
                      Create New
                    </button>
                  </div>
                </div>

                <div className="col">
                  <div className="searchHeadFlex">
                    <div className="searchWrapper">
                      <input
                        type="text"
                        maxlength="230"
                        style={{
                          "margin-right": "10px",
                          "border-radius": "5px",
                        }}
                        className="textsearch"
                        placeholder="Search by SubAdmin"
                        onChange={handleFilter}
                      />

                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        id="BsSearch"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                          clip-rule="evenodd"
                        ></path>

                        <path
                          fill-rule="evenodd"
                          d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="row3
                    "
                >
                  <div className=" col">
                    <div className="table-responsive">
                      <Table striped bordered hover style={{ width: "100%",zIndex:"auto" }}>
                        <thead className="thead-light">
                          <tr
                            style={{
                              textAlign: "center",
                              color: "#495057",
                              backgroundColor: "#e9ecef",
                              borderColor: "#dee2e6",
                              fontWeight: "900",
                              fontSize: "16px",
                            }}
                          >
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Date</th>
                            <th scope="col">Access Modules</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {search.length > 0
                            ? tableFilter
                                .slice(
                                  pagesVisited,
                                  pagesVisited + usersPerPage
                                )
                                .map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{data.name}</td>
                                      <td>{data.email}</td>
                                      <td>{data.mobile}</td>
                                      <td>{data.date}</td>
                                      <td>
                                        <FaEye
                                          className="subadmmineye"
                                          onClick={() => {
                                            clicked(data);
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <HiOutlinePencilAlt
                                          style={{
                                            fontSize: "30px",
                                            color: "#002171",
                                          }}
                                          onClick={() =>
                                            setModalIsOpenToTrue1(data)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <div
                                          onClick={() => {
                                            handleShow1(data);
                                          }}
                                        >
                                          <RiDeleteBin5Line
                                            style={{
                                              color: "red",
                                              fontSize: "25px",
                                            }}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                            : dataSource
                                .slice(
                                  pagesVisited,
                                  pagesVisited + usersPerPage
                                )
                                .map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{data.name}</td>
                                      <td>{data.email}</td>
                                      <td>{data.mobile}</td>
                                      <td>{data.date}</td>
                                      <td>
                                        <FaEye
                                          className="subadmmineye"
                                          onClick={() => {
                                            clicked(data);
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <HiOutlinePencilAlt
                                          style={{
                                            fontSize: "30px",
                                            color: "#002171",
                                          }}
                                          onClick={() =>
                                            setModalIsOpenToTrue1(data)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <div
                                          onClick={() => {
                                            handleShow1(data);
                                          }}
                                        >
                                          <RiDeleteBin5Line
                                            style={{
                                              color: "red",
                                              fontSize: "25px",
                                            }}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                        </tbody>
                      </Table>
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton style={{ backgroundColor: "#165c49" }}>
          <Modal.Title style={{ color: "white", fontWeight: "900" }}>
            Delete Subadmin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#165c49" }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label style={{ color: "white", fontSize: "20px" }}>
              Are you sure?
            </Form.Label>

            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#165c49" }}>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "red" }}
            onClick={deletesubadmin}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show4}
        onHide={handleClose4}
        size="xl"
        className="subadmin"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="subadmincontainer">
          <div className="formheading">
            <div>
              <h4>Sub-Admin Form</h4>
            </div>
            <div>
              <MdOutlineCancelPresentation
                onClick={handleClose4}
                style={{ fontSize: "30px", cursor: "pointer", color: "red" }}
              />
            </div>
          </div>

          <div style={{ padding: "0% 5%" }}>
            <p className="subadminlabel"> Name </p>
            <input
              type="text"
              className="subadmininput"
              placeholder="Sub admin name..."
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ padding: "0% 5%" }}>
              <p className="subadminlabel"> Email-ID </p>
              <input
                type="text"
                className="subadmininput"
                placeholder="Email ID..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div style={{ padding: "0% 5%" }}>
              <p className="subadminlabel">Password </p>
              <input
                type="text"
                className="subadmininput"
                placeholder="Password..."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div style={{ padding: "0% 5%" }}>
              <p className="subadminlabel">Mobile Number </p>
              <input
                type="test"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength="10"
                className="subadmininput"
                placeholder="Mobile Number..."
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
          </div>

          <div style={{ padding: "1% 5%" }}>
            <p className="subadminlabel">Access Modules </p>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "column",
                flexWrap: "wrap",
              }}
            >
              {/* <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="products"
                  name="products"
                  value="products"
                  onChange={() => sethome(!home)}
                />
                <label for="products"> Home</label>
              </div> */}

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="Order"
                  name="Order"
                  value="Order"
                  onChange={() => setgraphics(!graphics)}
                />
                <label for="Order"> Graph</label>
              </div>
              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="Order"
                  name="Order"
                  value="Order"
                  onChange={() => setnotification(!notification)}
                />
                <label for="Order"> Notification</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="category"
                  name="category"
                  value="category"
                  onChange={() => setcategory(!category)}
                />
                <label for="category"> Category</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="services"
                  name="services"
                  value="services"
                  onChange={() => setemployer(!employer)}
                />
                <label for="services"> Employer</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="RfqEnquiry"
                  name="RfqEnquiry"
                  value="RfqEnquiry"
                  onChange={() => setemployees(!employees)}
                />
                <label for="RfqEnquiry">Employees</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="promocode"
                  name="promocode"
                  value="promocode"
                  onChange={() => setourclients(!ourclients)}
                />
                <label for="promocode"> Our Clients</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="subadmin"
                  name="subadmin"
                  value="subadmin"
                  onChange={() => setsubadmin(!subadmin)}
                />
                <label for="subadmin"> Subadmin</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="AdhocInvoice"
                  name="AdhocInvoice"
                  value="AdhocInvoice"
                  onChange={() => setpostedjob(!postedjob)}
                />
                <label for="AdhocInvoice"> Posted Jobs</label>
              </div>
              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="BulkEnquiry"
                  name="BulkEnquiry"
                  value="BulkEnquiry"
                  onChange={() => setverifiedjobs(!verifiedjobs)}
                />
                <label for="BulkEnquiry"> Approved Jobs</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="askfor"
                  name="askfor"
                  value="askfor"
                  onChange={() => setappliedJobs(!appliedJobs)}
                />
                <label for="askfor"> Applied Details </label>
              </div>
              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="industry"
                  name="industry"
                  value="industry"
                  onChange={() => setindustries(!industries)}
                />
                <label for="industry"> Industry</label>
              </div>
              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="intrested"
                  name="intrested"
                  value="intrested"
                  onChange={() => setintrested(!intrested)}
                />
                <label for="intrested"> Not Intrested User</label>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleClose4}
              className="subadmininput1"
              style={{ backgroundColor: "#575366" }}
            >
              Cancel
            </button>
            <button
              onClick={addAdmin}
              className="subadmininput1"
              style={{ backgroundColor: "#2e3c69" }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <Modal show={modalIsOpen1} size="xl" className="subadmin">
        <div className="subadmincontainer">
          <div className="formheading">
            <div>
              <h4>Sub-Admin Edit Form</h4>
            </div>
            <div>
              <MdOutlineCancelPresentation
                onClick={setModalIsOpenToFalse1}
                style={{ fontSize: "30px", cursor: "pointer", color: "red" }}
              />
            </div>
          </div>

          <div style={{ padding: "0% 5%" }}>
            <p className="subadminlabel"> Name </p>
            <input
              type="text"
              className="subadmininput"
              placeholder={modalData.name}
              value={name1}
              onChange={(e) => setName1(e.target.value)}
            />
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ padding: "0% 5%" }}>
              <p className="subadminlabel"> Email-ID </p>
              <input
                type="text"
                className="subadmininput"
                placeholder={modalData.email}
                value={email1}
                onChange={(e) => setEmail1(e.target.value)}
              />
            </div>

            <div style={{ padding: "0% 5%" }}>
              <p className="subadminlabel">Password </p>
              <input
                type="text"
                className="subadmininput"
                placeholder={modalData.password}
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
              />
            </div>

            <div style={{ padding: "0% 5%" }}>
              <p className="subadminlabel">Mobile Number </p>
              <input
                type="test"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength="10"
                className="subadmininput"
                placeholder={modalData.mobile}
                value={mobile1}
                onChange={(e) => setMobile1(e.target.value)}
              />
            </div>
          </div>

          <div style={{ padding: "1% 5%" }}>
            <p className="subadminlabel">Access Modules </p>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "column",
                flexWrap: "wrap",
              }}
            >
              {/* <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="products"
                  name="products"
                  value={modalData.home}
                  onChange={() => sethome1(!home1)}
                />
                <label for="products"> Home</label>
              </div> */}

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  value={modalData.graphics}
                  id="Order"
                  name="Order"
                  onChange={() => setgraphics1(!graphics1)}
                />
                <label for="Order"> Graph</label>
              </div>
              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  value={modalData.notification}
                  id="Order"
                  name="Order"
                  onChange={() => setnotification1(!notification1)}
                />
                <label for="Order"> Notification</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="category"
                  name="category"
                  value={modalData.category}
                  onChange={() => setcategory1(!category1)}
                />
                <label for="category"> Category</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="services"
                  name="services"
                  value={modalData.employer}
                  onChange={() => setemployer1(!employer1)}
                />
                <label for="services"> Employers</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="RfqEnquiry"
                  name="RfqEnquiry"
                  value={modalData.employee}
                  onChange={() => setemployees1(!employees1)}
                />
                <label for="RfqEnquiry"> Employees</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="promocode"
                  name="promocode"
                  value={modalData.ourClient}
                  onChange={() => setourClient1(!ourClient1)}
                />
                <label for="promocode"> Our Clients</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="subadmin"
                  name="subadmin"
                  value={modalData.subadmin}
                  onChange={() => setsubadmin1(!subadmin1)}
                />
                <label for="subadmin"> Subadmin</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="AdhocInvoice"
                  name="AdhocInvoice"
                  value={modalData.PostedJob}
                  onChange={() => setpostjob1(!postjob1)}
                />
                <label for="AdhocInvoice"> Posted Jobs</label>
              </div>
              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="BulkEnquiry"
                  name="BulkEnquiry"
                  value={modalData.verifiedJob}
                  onChange={() => setverifiedJob1(!verifiedJob1)}
                />
                <label for="BulkEnquiry"> Approved Jobs</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="askfor"
                  name="askfor"
                  value={modalData.appliedDetails}
                  onChange={() => setapplieddetails1(!applieddetails1)}
                />
                <label for="BulkEnquiry"> Applied Details</label>
              </div>

              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="industry"
                  name="industry"
                  value="industry"
                  onChange={() => setindustries1(!industries1)}
                />
                <label for="industry"> Industry</label>
              </div>
              <div style={{ width: "25%" }}>
                <input
                  type="checkbox"
                  id="intrested"
                  name="intrested"
                  value="intrested"
                  onChange={() => setintrested1(!intrested1)}
                />
                <label for="intrested"> Not Intrested User</label>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={setModalIsOpenToFalse1}
              className="subadmininput1"
              style={{ backgroundColor: "#575366" }}
            >
              Cancel
            </button>
            <button
              onClick={editsubadmin}
              className="subadmininput1"
              style={{ backgroundColor: "#2e3c69" }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header
          closeButton
          style={{
            backgroundColor: "rgb(43, 183, 146)",
            padding: "10% 10%",
            color: "white",
          }}
        >
          <Offcanvas.Title style={{ fontSize: "30px" }}>
            Sub Admin All Details
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ textAlign: "left", paddingLeft: "20px" }}>
            <h4>Name : {viewItems.name}</h4>
            <h4>Email : {viewItems.email}</h4>
            <h4>Mobile : {viewItems.mobile}</h4>
            <h4 style={{ margin: "20px 0px" }}>AccessModules</h4>

            {/* <h5>
              Home :{" "}
              {viewItems?.home == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}{" "}
            </h5> */}

            <h5>
              Graph :{" "}
              {viewItems?.graphics == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5>
            <h5>
              Notification :{" "}
              {viewItems?.notification == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5>

            <h5>
              Category :{" "}
              {viewItems?.category == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5>

            <h5>
              Employers :{" "}
              {viewItems?.employer == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5>

            <h5>
              Employees :{" "}
              {viewItems?.employee == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5>

            <h5>
              Our Clients :{" "}
              {viewItems?.ourClient == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5>

            <h5>
              Subadmin :{" "}
              {viewItems?.subadmin == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5>
            <h5>
              Posted Jobs :{" "}
              {viewItems?.PostedJob == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5>

            <h5>
              Approved Jobs :{" "}
              {viewItems?.verifiedJob == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5>
            <h5>
              Industry :{" "}
              {viewItems?.industries == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5>
            <h5>
              Applied Details :{" "}
              {viewItems?.appliedDetails == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5>
            <h5>
              Not Intrested User :{" "}
              {viewItems?.intrested == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5>
            {/* <h5>
              Ask For Price :{" "}
              {viewItems.askforprice == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5> */}
            {/* <h5>
              Feedback :{" "}
              {viewItems.feedback == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5> */}
            {/* <h5>
              Testimonials :{" "}
              {viewItems.testimonial == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5> */}

            {/* <h5>
              Customer :{" "}
              {viewItems.customer == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5> */}

            {/* <h5>
              RfqEnquiry :{" "}
              {viewItems.rfqEnquiry == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5> */}

            {/* <h5>
              Payments :{" "}
              {AccessModules.payments == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5> */}

            {/* <h5>
              Graphics :{" "}
              {AccessModules.graphics == "true" ? (
                <AiOutlineCheck className="subadmincheck" />
              ) : (
                <AiOutlineClose className="subadminclose" />
              )}
            </h5> */}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default SubAdmin;
