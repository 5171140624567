import React from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Mp.css";
import Wcs from "./Wcs1";
import { Modal } from "react-bootstrap";

// back-e
import axios from "axios";
import { useState, useEffect } from "react";
import exportFromJSON from "export-from-json";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { waitForElementToBeRemoved } from "@testing-library/react";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactPaginate from "react-paginate";

function Unverified1() {

  const [UnvarifiedList, setUnvarifiedList] = useState([]);
  const [Selectall, setSelectall] = useState(false);
  

  const [singleselected, setsingleselect] = useState([]);
  // const formdata = new FormData();
  // formdata.set("companyName", companyname);
  // formdata.set("jobProfile", jobprofile);
  // formdata.set("contact", contact);
  const getJobList = async () => {
    let res = await axios.get("https://univiindia.com/api/user/getUnvarifiedList");
    if (res.status === 200) {
      //console.log(res.data);
      setUnvarifiedList(res.data.success);
    }
  };

  useEffect(() => {
    getJobList();
  }, []);

  const makeVerified = async (id) => {
    let res = await axios.put("https://univiindia.com/api/user/makeVerify/" + id);
    if (res.status === 200) {
      window.location.reload();
      getJobList();
    }
  };
  const [reasion, setreasion] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
 

  const showModal = () => {
    setIsModalOpen(true);
  
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  const data = [];
  if (UnvarifiedList?.length !== 0) {
    for (let i = 0; i < UnvarifiedList.length; i++) {
      data.push({
        JobID :UnvarifiedList[i]?._id.slice(18,24),
        CompanyName: UnvarifiedList[i]?.companyName,
        ContactPersonName: UnvarifiedList[i]?.interviewername,
        Email: UnvarifiedList[i]?.email,
        Contact: UnvarifiedList[i]?.whatsapp,
        Category: UnvarifiedList[i]?.category,
        JobProfile: UnvarifiedList[i]?.jobProfile,
        PostedOn: moment(UnvarifiedList[i]?.updatedAt).fromNow(),
      });
    }
  }
  const exportType = "xls";
  const [fileName, setfileName] = useState("Unverified Jobs");

  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };

  //console.log("unvarifiedList===>", UnvarifiedList);
  const makeBlock = async (id) => {
    try {
      const config = {
        url: "/makeBlockUnBlockCompany",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: { userId: id, isBlock: true },
      };
      let res = await axios(config);
      if (res.status === 200) {
        window.location.reload();
        getJobList();
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const makeUnBlock = async (id) => {
    try {
      const config = {
        url: "/makeBlockUnBlockCompany",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: { userId: id, isBlock: false },
      };
      let res = await axios(config);
      if (res.status === 200) {
        window.location.reload();
        getJobList();
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    UnvarifiedList.length /
      usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const holdEmployer = async (id) => {
   
    const config = {
      url: "/user/makeVerifyUnverifyAdmin",
      method: "post",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
      data: { status: "Hold", reasion: reasion, userId: id },
    };
    if(!reasion )
    {alert("Please enter the reason")} else {
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setIsModalOpen(false);
        // alert("Successfully hold");
        window.location.reload();
      } else {
        alert("Something went worng");
      }
     
    } catch (err) {
      //console.log(err.message);
    }
    }
  };
  const handleCheckboxSelect = (event, data) => {
    if (event.target.checked) {
      setsingleselect([...singleselected, data]);
      
    
    // setshowMoreId(singleselected?.includes(data),"true/false")
      console.log(singleselected,"jjjj");
    } else {
      setsingleselect(singleselected?.filter(id => id !== data));
      

    }
  };
 console.log(singleselected,"fdfdfdfdfd");

  const handleSelectAll = (event) => {
    
    if (event.target.checked) {
      setSelectall(true)
      setsingleselect(...singleselected, UnvarifiedList?.map(item => item._id));
   
    } else {
      setsingleselect([]);
      setSelectall(false)
    
    }
  };
 
 function AllHoldbtn() {

  for(let i =0 ;i <singleselected.length ; i++){
  
holdEmployer(singleselected[i])


  }
}
function Approvebtn() {

  for(let i =0 ;i <singleselected.length ; i++){
  
    makeVerified(singleselected[i])
  }
}
function Blockbtn() {

  for(let i =0 ;i <singleselected.length ; i++){
  
makeBlock(singleselected[i])


  }
}
function Unblockbtn() {

  for(let i =0 ;i <singleselected.length ; i++){
  
makeUnBlock(singleselected[i])
  }
}

  return (
    <>
      {/* img */}
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          {/* <Wcs /> */}

          <div
            className="container"
            style={{
              border: "1px solid rgba(36, 73, 106, 0.21)",
              borderRadius: " 10px",
              marginBottom: "10px",
              boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Unverified Jobs</b>
                </h1>
                {/* <button onClick={ExportToExcel} style={{backgroundColor:'green',color:'white',float:"left"}}>Export to Excel</button> */}
              </div>
            </div>
            <p align="right">
              <Link to={`/addjob1`}>
                <Button
                  type="button"
                  style={{
                    backgroundColor: "rgb(5, 38, 78)",
                    color: "white",
                    border: "none",
                  }}
                >
                  Post Job +
                </Button>
              </Link>
            </p>
            <div className="tble">
            
             <div style={{display:'flex',flexDirection:'row',margin:"20px"}}>
            <input onChange={handleSelectAll}   type="checkbox"  style={{marginLeft:"20px"}}/>
            <Button class="btn btn-outline-primary" onClick={Approvebtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Approve{" "}
                 
                </Button>
                <Button class="btn btn-outline-primary" onClick={Blockbtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Block{" "}
                 
                </Button>
                <Button class="btn btn-outline-primary" onClick={Unblockbtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Unblock{" "}
                 
                </Button>
            <Button class="btn btn-outline-primary" onClick={showModal} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Hold{" "}
                 
                </Button>
                
           </div> 
            <div style={{ display: "flex", margin: "20px",justifyContent:'space-around' }}>
                
                <Button class="btn btn-outline-primary" style={{backgroundColor:"#2bb792",borderColor:"#2bb792"}}>Pending {
                   UnvarifiedList.filter((item) => item.status === "Pending").length
                 }</Button>
                <Button class="btn btn-outline-primary" style={{backgroundColor:"#2bb792",borderColor:"#2bb792"}}>Hold {
                   UnvarifiedList.filter((item) => item.status === "Hold").length
                 }</Button>
             </div>
              <table style={{ width: "100%", marginBottom: "20px",zIndex:"auto" }}>
                <tr>
                  <th></th>
                  <th>Job ID</th>
                  <th>Company Name</th>
                  <th>Job Title</th>

                  <th>Job owner</th>
                  {/* <th>Status</th> */}
                  <th>Posted on</th>
                  <th>Status</th>
                  <th>Action</th>
                  {/* <th>Delete</th> */}
                </tr>
                {UnvarifiedList?.slice(pagesVisited, pagesVisited + usersPerPage).map((item) => (
                  <tr>
                     <td> {Selectall ? <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)} checked={Selectall} /> : <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)}  />}</td>
                    <td>{item._id.slice(18, 24)}</td>
                    <td>
                      <Link
                        to={`/admincompanyDetails/${item?._id}`}
                        name="name"
                        id="name"
                      >
                        {item.companyName}
                      </Link>
                    </td>
                    <td>
                      <Link to={`/admincompanyDetails/${item?._id}`}>
                        {item.jobProfile}
                      </Link>
                    </td>

                    <td>
                      <Link to={`/admincompanyDetails/${item?._id}`}>
                        {item?.employerId ? (
                          <Link
                            to={`/adminemployeeDetailsView/${item?.employerId?._id}`}
                          >
                            {item?.employerId?.name}
                          </Link>
                        ) : (
                          <>Admin</>
                        )}
                      </Link>
                    </td>
                    {/* <td>
                      {" "}
                      {item?.isBlock ? (
                        <span style={{ color: "red" }}>Block</span>
                      ) : (
                        <span style={{ color: "#040050" }}>Pending</span>
                      )} */}
                      {/* <Button
                        variant="success"
                        style={{ color: "white" }}
                        onClick={() => makeVerified(item?._id)}
                      >
                        Verify
                      </Button>{" "} */}
                    {/* </td> */}
                    <td> {moment(item?.updatedAt).fromNow()}</td>
                    <td>
                      {" "}
                      {item?.status === "Approved" ? (
                        <p style={{ color: "green" }}>{item?.status}</p>
                      ) : (
                        <>
                          {item?.status === "Hold" ? (
                            <p style={{ color: "#f72840" }}>{item?.status}</p>
                          ) : (
                            <p style={{ color: "blue" ,marginTop:"10px"}}>{item?.status}</p>
                          )}
                        </>
                      )}{" "}
                    </td>

                    <td style={{ display: "flex", gap: "3px" }}>
                      <Link
                        to={`/admincompanyDetails/${item?._id}`}
                        class="btn btn-outline-primary"
                      >
                        View
                      </Link>
                      {item?.isBlock === false ? (
                        <button
                          type="button"
                          class="btn btn-danger"
                          onClick={() => makeBlock(item?._id)}
                        >
                          Block
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="btn btn-warning"
                          onClick={() => makeUnBlock(item?._id)}
                        >
                          Unblock
                        </button>
                      )}
                    </td>
                    {/* <td>
                      {" "}
                      <Button
                        variant="danger"
                        style={{ color: "white" }}
                        onClick={() => removeJob(item?._id)}
                      >
                        Delete
                      </Button>{" "}
                    </td> */}
                  </tr>
                ))}
              </table>
              <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
            </div>
          </div>
          <p align="right" style={{ marginBottom: "2em" }}>
            <Button
              variant="primary"
              type="button"
              value="ShortList"
              onClick={ExportToExcel}
              style={{
                backgroundColor: "rgb(5, 38, 78)",
                color: "white",
                border: "none",
              }}
            >
              <b style={{ color: "white" }}>Export to Excel</b>
            </Button>
          </p>
        </div>
      </div>
      <Modal
           show={isModalOpen} onHide={handleCancel}
          >
             <Modal.Header
                  closeButton
                  style={{ backgroundColor: "#165c49" }}
                >
                  <Modal.Title style={{ color: "white", fontWeight: "900" }}>
                   Reason
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#165c49" }}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Enter reason"
                value={reasion}
                onChange={(e) => setreasion(e.target.value)}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: "#165c49" }}>
                  <Button variant="secondary" onClick={handleCancel}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    style={{ backgroundColor: "red" }}
                    onClick={AllHoldbtn}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
          </Modal>
    </>
  );
}

export default Unverified1;
