import React, { useEffect } from "react";


// back-e
import axios from "axios";
import { useState } from "react";



// import { Modal } from 'antd';
import Modal from "react-bootstrap/Modal";

import { RiDeleteBin2Fill } from "react-icons/ri";
import { useLocation } from "react-router-dom";

function Readmore() {
    let location = useLocation();
    const { data } = location.state;



  const [text1, settext1] = useState("");
 
  return (
    <>
      {" "}
      <div className="townhub">
        <div className="row">
          <div className="col-md-3"></div>
         
          
              <div
                class="container"
                style={{
                  border: "1px solid rgba(36, 73, 106, 0.21)",
                  borderRadius: " 10px",
                  marginBottom: "10px",
                  boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
                  width: "100%",
                }}
              >
                <div class="loginform" style={{ textAlign: "center" }}>
                  <h1
                    className="heading-banner wow animate__animated animate__fadeInUp"
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                    }}
                  >
                    <b>Business Content</b>
                  </h1>
                  <br />
                </div>
              
               

                <div className="slide_hero mb-12">
                  
                      <div class="item">
                        <div className="esw">
                          {data?.text}
                        </div>
                      
                      </div>
                  
                </div>
              </div>
            </div>
          </div>
      
  
      
     
    </>
  );
}

export default Readmore;
