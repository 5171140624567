// import React from "react";
import ProSidebar from "./ProSidebarS";
import Button from "react-bootstrap/Button";
import { BiLogOut, BiLogOutCircle } from "react-icons/bi";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import './MajorProduct.scoped.scss';
import { FaPager } from "react-icons/fa";
import { GrUserAdmin } from "react-icons/gr";
import { BsArrowsFullscreen, BsFlagFill } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";
import Wcs from "./WcsS";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState } from "react";
import * as React from "react";

// export default function ColorToggleButton() {
function ProfileView1(props) {
  const [alignment, setAlignment] = React.useState("web");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  // }

  return (
    <>
    <div className="townhub">
    <div className="row">
        <div className="col-md-3"></div>
        {/* <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        > */}
          {/* <Wcs /> */}
      {/* admi */}
      <div
        style={{
          top: "0px",
          width: "100%",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-md-3 fixed"
              style={{
                border:" 1px solid rgba(36, 73, 106, 0.21)",
                borderRadius: "10px",
                marginBottom: "10px",
                boxShadow:" rgb(0 0 0 / 16%) 0px 1px 4px",
                height: "100%",
                width: "80%",
              }}
            >
              {/* <div className="container"> */}
              <div className="row">
                <div className="col-md-6">
                  {/* <h4 style={{ fontSize: "15px", color: " #4f5e64" }}>
                          Welcome back!
                        </h4> */}
                  <h6></h6>
                </div>
                {/* <div className="col-md-6">
                  <img
                    className="adim"
                    src="e17.png"
                    style={{ height: "100px", marginBottom: "-132px" }}
                  />
                </div> */}
              </div>
              {/* <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  color=""
                  aria-label="upload picture"
                  component="label"
                >
                  <input hidden accept="image/*" type="file" /> */}
                  <img
                    className="rounded-circle"
                    alt="avatar2"
                    src="https://mdbcdn.b-cdn.net/img/new/avatars/1.webp"
                    style={{
                      height: "100px",
                      margin: "10px 0px",
                      border: "4px solid white",
                    }}
                  />
                {/* </IconButton>
              </Stack> */}
              <div
                className="bg-white"
                style={{
                  padding: "30px 10px",
                  marginTop: "-50px",
                  borderRadius: "5px",
                }}
              >
                <h1
                  className="adm textalign-center"
                  style={{
                    textAlign: "center",
                    color: "#36454F",
                    fontSize: "20px",
                    fontFamily: "",
                    marginTop: "15px",
                  }}
                >
                 Admin Name
                </h1>

                <br />
                <br />

                <div
                  className="row"
                  style={{
                    marginLeft: "128px",
                    marginTop: "-65px",
                    color: "black",
                  }}
                >
                  <br></br>
                </div>
                <hr></hr>

                <div
                  className="container"
                  style={{
                    textAlign: "left",
                    lineHeight: "1.7rem",
                    color: "#e0e6f7",
                    fontSize: "15px",
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                  >
                    <ul>
                      <a href="/addjob1" style={{ color: "rgb(36, 73, 106)" }}>
                        {/* <FaPager style={{ marginRight: "8px" }} /> */}
                      </a>
                      <Link to="/adminprofiledetails">
                        <a style={{ color: "rgb(36, 73, 106)" }}>
                          <h5>Profile Details</h5>
                          {/* <ToggleButton value="web">Web</ToggleButton> */}
                        </a>
                      </Link>
                      <br />

                      <Link to="/admineditlogindetails">
                        <a
                          style={{
                            color: "rgb(36, 73, 106)",
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          <h5> Edit Login Details</h5>
                          {/* <ToggleButton value="android">Android</ToggleButton> */}
                        </a>
                      </Link>
                      <br />
                      <Link to="/adminchangepassword">
                        {" "}
                        <a style={{ color: "rgb(36,73,106)" }}>
                          <h5>Change Password</h5>
                          {/* <ToggleButton href="/changepassword" value="ios">iOS</ToggleButton> */}
                        </a>
                      </Link>
                    </ul>
                  </ToggleButtonGroup>

                  {/* </div> */}
                </div>
              </div>
              <div className="col-md-17">
                {props.children}

                {/* card end */}
              </div>
            </div>
          </div>
        </div>
        {/* admi */}
      </div>
        </div>
        </div>
      {/* </div> */}
    </>
  );
}

export default ProfileView1;
