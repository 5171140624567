import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Wcs1 from "./WcsS";
import axios from "axios";
import { Link } from "react-router-dom";
import Table from "./Table";
import './MajorProduct.scoped.scss';
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

function AdminHome1() {
const subadmin=JSON.parse(sessionStorage.getItem("subadmin"))
if(!subadmin){
  alert("Please login!");
  
}
  return (
    <>
    <div className="twonhub">
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
         
        >
          <div
            className="container"
            style={{
              border:" 1px solid rgba(36, 73, 106, 0.21)",
              borderRadius:" 10px",
              marginBottom: "10px",
              boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
              width: "100%",
              height: "500px",
               overflow: "scroll",
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Home</b>
                </h1>
              </div>
       
            </div>
            <br />
            <div className="home">
              <Wcs1 />
            </div>
            <br/>
            {subadmin?.PostedJob==="true" ? (<> <Table/>
            <p style={{float:"right",display:"flex"}}>
              <Link to={`/sub-adminunverified`}>
              <button type="button" class="btn btn-outline-primary">View all</button>
              </Link>
            </p></>):(<></>)}
           
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default AdminHome1;
