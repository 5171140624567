import React from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Mp.css";
import Wcs from "./Wcs1";
import { Link } from "react-router-dom";
import { MdDelete } from 'react-icons/md';
// back-e
import axios from "axios";
import { useState, useEffect } from "react";
import { BiUserCircle } from "react-icons/bi";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { waitForElementToBeRemoved } from "@testing-library/react";
import exportFromJSON from "export-from-json";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Modal } from "react-bootstrap";

function Employee1() {

  const [Selectall, setSelectall] = useState(false);
  

  const [singleselected, setsingleselect] = useState([]);


  const [UnvarifiedList, setUnvarifiedList] = useState([]);

  const getJobList = async () => {
    let res = await axios.get(
      "https://univiindia.com/api/user/getAllProfileEmployer"
    );
    if (res.status === 200) {
      //console.log(res.data);
      setUnvarifiedList(res.data.success);
    }
  };

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  useEffect(() => {
    getJobList();
  }, []);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 40;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    UnvarifiedList.filter((ele) => ele?.isDelete === false).length /
      usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const ApprovedEmployee = async (id) => {
    const config = {
      url: "/user/makeVerifyUnverify",
      method: "post",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
      data: { status: "Approved", reasion: "", userId: id },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        // alert("Successfully approved");
        window.location.reload();
        getJobList();
     
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  const [reasion, setreasion] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
 

  const showModal = () => {
    setIsModalOpen(true);
  
  };

 

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const holdEmployer = async (id) => {
    const config = {
      url: "/user/makeVerifyUnverify",
      method: "post",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
      data: { status: "Hold", reasion: reasion, userId: id },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setIsModalOpen(false);
        // alert("Successfully hold");
        getJobList();
        window.location.reload();
       
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = UnvarifiedList.filter(
        (ele) => ele?.isDelete === false
      ).filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setUnvarifiedList([...UnvarifiedList]);
    }
  };
  const handleFilter1 = (e) => {
  
      setSearch("Approved");
      const filterTable = UnvarifiedList.filter(
        (ele) => ele?.isDelete === false
      ).filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])
            .toLowerCase()
            .includes("Approved".toLowerCase())
        )
      );
      settableFilter([...filterTable]);
   
  };
  console.log(tableFilter.map(item=>item._id),"table");
   const handleFilter2 = (e) => {
  
      setSearch("unVerified");
      const filterTable = UnvarifiedList.filter(
        (ele) => ele?.isDelete === false
      ).filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k])
            .toLowerCase()
            .includes("unVerified".toLowerCase())
        )
      );
      settableFilter([...filterTable]);
   
  };
  const handleFilter3 = (e) => {
  
    setSearch("Pending");
    const filterTable = UnvarifiedList.filter(
      (ele) => ele?.isDelete === false
    ).filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k])
          .toLowerCase()
          .includes("Pending".toLowerCase())
      )
    );
    settableFilter([...filterTable]);
 
};
const handleFilter4 = (e) => {
  
    setSearch("Hold");
    const filterTable = UnvarifiedList.filter(
      (ele) => ele?.isDelete === false
    ).filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k])
          .toLowerCase()
          .includes("Hold".toLowerCase())
      )
    );
    settableFilter([...filterTable]);
 
};
  const data = [];
  if (UnvarifiedList?.length > 0) {
    for (let i = 0; i < UnvarifiedList?.length; i++) {
      if (UnvarifiedList[i]?.isDelete === false) {
        data.push({
          Name: UnvarifiedList[i]?.name,
          Email: UnvarifiedList[i]?.email,
          PhoneNumber: UnvarifiedList[i]?.mobile,
          Industry: UnvarifiedList[i]?.industry,
          CompanyName: UnvarifiedList[i]?.CompanyName,
          RegisteredOn: moment(UnvarifiedList[i]?.updatedAt).fromNow(),
        });
      }
    }
  }
  const exportType = "xls";
  const [fileName, setfileName] = useState("Employer Details");
  const [show1, setShow1] = useState(false);
  const [Delete, setDelete] = useState();
  const [showMoreId, setshowMoreId] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    setDelete(data);
  };

  const deleteEmployee = async (id) => {
    try {
      let res = await axios.delete(
        "https://univiindia.com/api/user/deleteParmanetEmployer/" + Delete._id
      );
      if (res.status === 200) {
        getJobList();
        setShow1(false);
        alert("Successfully deleted");
      }
    } catch (error) {
      //console.log(error)
    }
  };
  const deleteEmployee1 = async (id) => {
      
    try {
      let res = await axios.delete(
        "https://univiindia.com/api/user/deleteParmanetEmployer/" + id
      );
      if (res.status === 200) {
        getJobList();
        setShow1(false);
        window.location.reload();
        // alert("Successfully deleted");
         window.location.reload();
      }
    } catch (error) {
      //console.log(error)
    }
  };
  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };
  //console.log("unvarifiedList===>", UnvarifiedList);
  const makeBlock = async (id) => {
    try {
      const config = {
        url: "/makeBlockUnBlockEmployer",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: { userId: id, isBlock: true },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // alert("Successfully blocked");
        window.location.reload();
        
        getJobList();
      }
    } catch (error) {
      //console.log(error)
    }
  };

  const makeUnBlock = async (id) => {
    try {
      const config = {
        url: "/makeBlockUnBlockEmployer",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: { userId: id, isBlock: false },
      };
      let res = await axios(config);
      if (res.status === 200) {
        // alert("Successfully unblocked");
        window.location.reload();
        getJobList();
       
      }
    } catch (error) {
      //console.log(error)
    }
  };
 
  const handleCheckboxSelect = (event, data) => {
    if (event.target.checked) {
      setsingleselect([...singleselected, data]);
      
    
    // setshowMoreId(singleselected?.includes(data),"true/false")
      console.log(singleselected,"jjjj");
    } else {
      setsingleselect(singleselected?.filter(id => id !== data));
      

    }
  };
 console.log(singleselected,"fdfdfdfdfd");

  const handleSelectAll = (event) => {
    
    if (event.target.checked) {
      setSelectall(true)
      setsingleselect(...singleselected, UnvarifiedList?.filter((ele) => ele?.isDelete === false).map(item => item._id));
   
    } else {
      setsingleselect([]);
      setSelectall(false)
    
    }
  };
  const handleSelectAll1 = (event) => {
    
    if (event.target.checked) {
      setSelectall(true)
      setsingleselect(...singleselected,tableFilter.map(item=>item._id));
   
    } else {
      setsingleselect([]);
      setSelectall(false)
    
    }
  };
  console.log(singleselected,"jjjj");

 function AllHoldbtn() {

  for(let i =0 ;i <singleselected.length ; i++){
  
holdEmployer(singleselected[i])


  }
}
function Approvebtn() {

  for(let i =0 ;i <singleselected.length ; i++){
  
ApprovedEmployee(singleselected[i])
  }
}
function Blockbtn() {

  for(let i =0 ;i <singleselected.length ; i++){
  
makeBlock(singleselected[i])


  }
}
function Unblockbtn() {

  for(let i =0 ;i <singleselected.length ; i++){
  
makeUnBlock(singleselected[i])
  }
}
function Deletebtn() {

  for(let i =0 ;i <singleselected.length ; i++){
  
deleteEmployee1(singleselected[i])
  }
}
  return (
    <>
      {/* img */}
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          {/* <Wcs /> */}

          <div
            className="container"
            style={{
              border: "1px solid rgba(36, 73, 106, 0.21)",
              borderRadius: " 10px",
              marginBottom: "10px",
              boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Employer Details</b>
                </h1>

                {/* <button onClick={ExportToExcel} style={{backgroundColor:'green',color:'white',float:"right"}}>Export to Excel</button> */}
              </div>
            </div>

            <div className=" my-1 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="searchHeadFlex">
                <div className="searchWrapper">
                  <input
                    type="text"
                    maxlength="230"
                    style={{ "margin-right": "10px", "border-radius": "5px" }}
                    className="textsearch"
                    placeholder="Search by Employer"
                    onChange={handleFilter}
                  />
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 16 16"
                    id="BsSearch"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                      clip-rule="evenodd"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <div className="tble" >
             {search !== "" ?  "" :
             <div style={{display:'flex',flexDirection:'row',margin:"20px"}}>
            <input onChange={handleSelectAll}   type="checkbox"  style={{marginLeft:"20px"}}/>
            <Button class="btn btn-outline-primary" onClick={Approvebtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Approve{" "}
                 
                </Button>
                <Button class="btn btn-outline-primary" onClick={Blockbtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Block{" "}
                 
                </Button>
                <Button class="btn btn-outline-primary" onClick={Unblockbtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Unblock{" "}
                 
                </Button>
            <Button class="btn btn-outline-primary" onClick={showModal} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Hold{" "}
                 
                </Button>
                
            <Button class="btn btn-outline-primary" onClick={Deletebtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Delete All{" "}
                 
                </Button></div> }
                <p align="right" style={{marginTop:-40}} >
                <Button
                  variant="primary"
                  type="button"
                  value="ShortList"
                  onClick={ExportToExcel}
                  style={{
                    backgroundColor: "rgb(5, 38, 78)",
                    color: "white",
                    border: "none",
                    
                  }}
                >
                  <b style={{ color: "white" }}>Export to Excel</b>
                </Button>
              </p>
            <div style={{ display: "flex", margin: "20px",justifyContent:'space-around' }}>
            <Button class="btn btn-outline-primary" onClick={()=>setSearch("")} style={{backgroundColor:"#2bb792",borderColor:"#2bb792"}}>
                  All{" "}
                  {
                    UnvarifiedList.length
                  }
                </Button>{" "}
                <Button class="btn btn-outline-primary" onClick={handleFilter1} style={{backgroundColor:"#2bb792",borderColor:"#2bb792"}}>
                  Approved{" "}
                  {
                    UnvarifiedList.filter(
                      (ele) => ele?.isDelete === false
                    ).filter((item) => item.status === "Approved").length
                  }
                </Button>{" "}
                <Button class="btn btn-outline-primary" onClick={handleFilter2} style={{backgroundColor:"#2bb792",borderColor:"#2bb792"}}>
                  Registered{" "}
                  {
                    UnvarifiedList.filter(
                      (ele) => ele?.isDelete === false
                    ).filter((item) => item.status === "unVerified").length
                  }
                </Button>{" "}
                 <Button class="btn btn-outline-primary" onClick={handleFilter3} style={{backgroundColor:"#2bb792",borderColor:"#2bb792"}}>Request For Approval {
                    UnvarifiedList.filter(
                      (ele) => ele?.isDelete === false
                    ).filter((item) => item.status === "Pending").length
                  }</Button>
                 <Button class="btn btn-outline-primary" onClick={handleFilter4} style={{backgroundColor:"#2bb792",borderColor:"#2bb792"}}>Hold {
                    UnvarifiedList.filter(
                      (ele) => ele?.isDelete === false
                    ).filter((item) => item.status === "Hold").length
                  }</Button>
              </div>
              {search === "" ?  "" :
             <div style={{display:'flex',flexDirection:'row',margin:"20px"}}>
            <input onChange={handleSelectAll1}   type="checkbox"  style={{marginLeft:"20px"}}/>
            <Button class="btn btn-outline-primary" onClick={Approvebtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Approve{" "}
                 
                </Button>
                <Button class="btn btn-outline-primary" onClick={Blockbtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Block{" "}
                 
                </Button>
                <Button class="btn btn-outline-primary" onClick={Unblockbtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Unblock{" "}
                 
                </Button>
            <Button class="btn btn-outline-primary" onClick={showModal} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Hold{" "}
                 
                </Button>
                
            <Button class="btn btn-outline-primary" onClick={Deletebtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Delete All{" "}
                 
                </Button></div> }
             
              <table style={{ width: "100%",zIndex:"auto"}}>
                <tr>
                 <th></th>
                  <th>Employer Name</th>
                  {/* <th >Email</th> */}
                  <th>Contact</th>
                  <th>Company Name</th>
                  <th>Industry</th>
                  <th>Registered on</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>Remove</th>
                </tr>
                {search.length > 0
                  ? tableFilter
                      .slice(pagesVisited, pagesVisited + usersPerPage)
                      .map((item, index) => {
                        return (
                          <tr>
                            {/* <td>
                              {item?.profile ? (
                                <img
                                  src={`https://univiindia.com/employer/${item?.profile}`}
                                  width="30"
                                  height="30"
                                  className="rounded-circle"
                                  alt="React Bootstrap logo"
                                  style={{ marginRight: "10px" }}
                                />
                              ) : (
                                <BiUserCircle
                                  style={{
                                    margin: "0px 10px",
                                    fontSize: "22px",
                                    color: "#24496a",
                                  }}
                                />
                              )}
                            </td> */}
                             <td> {Selectall ? <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)} checked={Selectall} /> : <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)}  />}</td>
                            <td>
                              <Link
                                to={`/adminemployeeDetailsView/${item?._id}`}
                              ><BiUserCircle
                              style={{
                                margin: "0px 10px",
                                fontSize: "22px",
                                color: "#24496a",
                              }}
                            />
                                <a name="name" id="name">
                                  {item?.name}
                                </a>
                              </Link>
                            </td>
                            {/* <td>
                              <Link
                                to={`/adminemployeeDetailsView/${item?._id}`}
                                style={{width:"100px"}}
                              >
                                <a>{item?.email}</a>
                              </Link>
                            </td> */}
                            <td>
                              <Link
                                to={`/adminemployeeDetailsView/${item?._id}`}
                              >
                                <a>{item?.mobile}</a>
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/adminemployeeDetailsView/${item?._id}`}
                              >
                                <a>{item?.CompanyName}</a>
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/adminemployeeDetailsView/${item?._id}`}
                              >
                                <a>{item?.industry}</a>
                              </Link>
                            </td>
                            <td> {moment(item?.updatedAt).fromNow()}</td>
                            <td>
                              {" "}
                              {item?.status === "Approved" ? (
                                <p style={{ color: "green" }}>{item?.status}</p>
                              ) : (
                                <>
                                  {item?.status === "Hold" ? (
                                    <p style={{ color: "#f72840" }}>
                                      {item?.status}
                                    </p>
                                  ) : (
                                    <p style={{ color: "blue" }}>
                                      {item?.status}
                                    </p>
                                  )}
                                </>
                              )}{" "}
                            </td>

                            <td style={{ display: "flex", gap: "3px" }}>
                              <Link
                                to={`/adminemployeeDetailsView/${item?._id}`}
                                class="btn btn-outline-primary"
                              >
                                View
                              </Link>
                              {item?.isBlock === false ? (
                                <button
                                  type="button"
                                  class="btn btn-danger"
                                  onClick={() => makeBlock(item?._id)}
                                >
                                  Block
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  class="btn btn-warning"
                                  onClick={() => makeUnBlock(item?._id)}
                                >
                                  Unblock
                                </button>
                              )}
                            </td>
                            <td>
                            <MdDelete size={24} onClick={() => handleShow1(item)}
                                style={{ color: "red",marginLeft:'10px' }}/>
                              {/* <Button
                                variant="danger"
                                onClick={() => handleShow1(item)}
                                style={{ color: "white" }}
                              >
                                Delete
                              </Button>{" "} */}
                            </td>
                          </tr>
                        );
                      })
                  : UnvarifiedList?.filter((ele) => ele?.isDelete === false)
                      .slice(pagesVisited, pagesVisited + usersPerPage)
                      .map((item) => {
                        return (
                          <tr>
                            {/* <td>
                              {item?.profile ? (
                                <img
                                  src={`https://univiindia.com/employer/${item?.profile}`}
                                  width="30"
                                  height="30"
                                  className="rounded-circle"
                                  alt="React Bootstrap logo"
                                  style={{ marginRight: "10px" }}
                                />
                              ) : (
                                <BiUserCircle
                                  style={{
                                    margin: "0px 10px",
                                    fontSize: "22px",
                                    color: "#24496a",
                                  }}
                                />
                              )}
                            </td> */}
                             <td> {Selectall ? <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)} checked={Selectall} /> : <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)}  />}</td>
                            <td>
                              <Link
                                to={`/adminemployeeDetailsView/${item?._id}`}
                              >
                                <BiUserCircle
                                  style={{
                                    margin: "0px 10px",
                                    fontSize: "22px",
                                    color: "#24496a",
                                  }}
                                />
                                <a name="name" id="name">
                                  {item.name}
                                </a>
                              </Link>
                            </td>
                            {/* <td >
                              <Link
                                to={`/adminemployeeDetailsView/${item?._id}`}
                                
                              >
                                <a >{item.email}</a>
                              </Link>
                            </td> */}
                            <td>
                              <Link
                                to={`/adminemployeeDetailsView/${item?._id}`}
                              >
                                <a>{item?.mobile}</a>
                              </Link>
                            </td>
                            
                            <td>
                              <Link
                                to={`/adminemployeeDetailsView/${item?._id}`}
                              >
                                <a>{item.CompanyName}</a>
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/adminemployeeDetailsView/${item?._id}`}
                              >
                                <a>{item?.industry}</a>
                              </Link>
                            </td>
                            <td> {moment(item?.updatedAt).fromNow()}</td>
                            <td>
                              {" "}
                              {item?.status === "Approved" ? (
                                <p style={{ color: "green" }}>{item?.status}</p>
                              ) : (
                                <>
                                  {item?.status === "Hold" ? (
                                    <p style={{ color: "#f72840" }}>
                                      {item?.status}
                                    </p>
                                  ) : (
                                    <p style={{ color: "blue" }}>
                                      {item?.status}
                                    </p>
                                  )}
                                </>
                              )}{" "}
                            </td>

                            <td style={{ display: "flex", gap: "3px" }}>
                              <Link
                                to={`/adminemployeeDetailsView/${item?._id}`}
                                class="btn btn-outline-primary"
                              >
                                View
                              </Link>
                              {item?.isBlock === false ? (
                                <button
                                  type="button"
                                  class="btn btn-danger"
                                  onClick={() => makeBlock(item?._id)}
                                >
                                  Block
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  class="btn btn-warning"
                                  onClick={() => makeUnBlock(item?._id)}
                                >
                                  Unblock
                                </button>
                              )}
                            </td>
                            <td>
                              {" "}
                             <MdDelete size={24} onClick={() => handleShow1(item)}
                                style={{ color: "red",marginLeft:'10px' }}/>
                              {/* <Button
                                variant="danger"
                                onClick={() => handleShow1(item)}
                                style={{ color: "white" }}
                              >
                                Delete
                              </Button>{" "} */}
                            </td>
                          </tr>
                        );
                      })}
              </table>
              <Modal show={show1} onHide={handleClose1}>
                <Modal.Header
                  closeButton
                  style={{ backgroundColor: "#165c49" }}
                >
                  <Modal.Title style={{ color: "white", fontWeight: "900" }}>
                    Delete Employer
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#165c49" }}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{ color: "white", fontSize: "20px" }}>
                      Are you sure?
                    </Form.Label>

                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#165c49" }}>
                  <Button variant="secondary" onClick={handleClose1}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    style={{ backgroundColor: "red" }}
                    onClick={deleteEmployee}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
           show={isModalOpen} onHide={handleCancel}
          >
             <Modal.Header
                  closeButton
                  style={{ backgroundColor: "#165c49" }}
                >
                  <Modal.Title style={{ color: "white", fontWeight: "900" }}>
                   Reason
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#165c49" }}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Enter reason"
                value={reasion}
                onChange={(e) => setreasion(e.target.value)}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: "#165c49" }}>
                  <Button variant="secondary" onClick={handleCancel}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    style={{ backgroundColor: "red" }}
                    onClick={AllHoldbtn}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
          </Modal>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
              {/* <p align="right" style={{ marginBottom: "6em" }}>
                <Button
                  variant="primary"
                  type="button"
                  value="ShortList"
                  onClick={ExportToExcel}
                  style={{
                    backgroundColor: "rgb(5, 38, 78)",
                    color: "white",
                    border: "none",
                    
                  }}
                >
                  <b style={{ color: "white" }}>Export to Excel</b>
                </Button>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Employee1;
