import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { MdOutlineMailOutline } from "react-icons/md";
import axios from "axios";
import "../Adminpannel/SamAdmin.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import React from "react";
import "../Adminpannel/Mp.css";

function AdminLogin() {
  // const [user, setUser] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eye,seteye]=useState("true")
  let history = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/login",
        method: "post",
        baseURL: "https://univiindia.com/api/admin",
        headers: { "content-type": "application/json" },
        data: { email: email, password: password },
      };
      let res = await axios(config);
      if (res.status === 200) {
        sessionStorage.setItem("admin", JSON.stringify(res.data.success));
        history("/adminhome");
        // alert("successfully Login");
        // window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  return (
    <div className="container pr">
      <div
        className="ct"
        style={{
          backgroundImage: "url(/login-bg.jpg)",
          height: "357px",
          backgroundSize: "cover",
          margin: "152px 0px 100px",
          position: "relative",
        }}
      >
        <div className="icon d-flex">
          <div class="login-bg-txt">
            <figure class="login-icon1">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 20 20"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: "white", fontSize: "30px" }}
              >
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
              </svg>
            </figure>
            <strong>1 Lacs+</strong>Satisfied Employees
          </div>
          <div class="login-bg-txt">
            <figure class="login-icon2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: "white", fontSize: "30px" }}
              >
                <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"></path>
              </svg>
            </figure>
            <strong>300+</strong>Jobs
          </div>
          <div class="login-bg-txt">
            <figure class="login-icon3">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 1024 1024"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: "white", fontSize: "30px" }}
              >
                <path d="M531.3 574.4l.3-1.4c5.8-23.9 13.1-53.7 7.4-80.7-3.8-21.3-19.5-29.6-32.9-30.2-15.8-.7-29.9 8.3-33.4 21.4-6.6 24-.7 56.8 10.1 98.6-13.6 32.4-35.3 79.5-51.2 107.5-29.6 15.3-69.3 38.9-75.2 68.7-1.2 5.5.2 12.5 3.5 18.8 3.7 7 9.6 12.4 16.5 15 3 1.1 6.6 2 10.8 2 17.6 0 46.1-14.2 84.1-79.4 5.8-1.9 11.8-3.9 17.6-5.9 27.2-9.2 55.4-18.8 80.9-23.1 28.2 15.1 60.3 24.8 82.1 24.8 21.6 0 30.1-12.8 33.3-20.5 5.6-13.5 2.9-30.5-6.2-39.6-13.2-13-45.3-16.4-95.3-10.2-24.6-15-40.7-35.4-52.4-65.8zM421.6 726.3c-13.9 20.2-24.4 30.3-30.1 34.7 6.7-12.3 19.8-25.3 30.1-34.7zm87.6-235.5c5.2 8.9 4.5 35.8.5 49.4-4.9-19.9-5.6-48.1-2.7-51.4.8.1 1.5.7 2.2 2zm-1.6 120.5c10.7 18.5 24.2 34.4 39.1 46.2-21.6 4.9-41.3 13-58.9 20.2-4.2 1.7-8.3 3.4-12.3 5 13.3-24.1 24.4-51.4 32.1-71.4zm155.6 65.5c.1.2.2.5-.4.9h-.2l-.2.3c-.8.5-9 5.3-44.3-8.6 40.6-1.9 45 7.3 45.1 7.4zm191.4-388.2L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494z"></path>
              </svg>
            </figure>
            <strong>500+</strong>Professional resumes delivered
          </div>
        </div>
        <div className="container">
          <div
            className="log"
            style={{
              width: "350px",
              padding: "55px 0px",
              margin: "0px 0px 0px 700px",
            }}
          >
            {" "}
            <div className="card" style={{ marginTop: "-500px" }}>
              <div class="container">
                <img
                  className="ssk"
                  src="./un .jpg"
                  style={{ width: "40%", height: "50px", margin: "8px 82px" }}
                />
                <div class="loginform" style={{ textAlign: "center" }}>
                  <h1
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                    }}
                  >
                    <b>Admin Login</b>
                  </h1>
                </div>
              </div>
              <hr />
              <div className="tx">
                <h6>
                  <MdOutlineMailOutline /> Sign in to your Account
                </h6>
              </div>
              <br />
              <Form
                onSubmit={(e) => {
                  onSubmit(e);
                }}
              >
                <Form.Label>User Id</Form.Label>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                  />

                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>

                {eye ?    <Form.Group className="mb-3" controlId="formBasicPassword" >
                  <Form.Label>Password</Form.Label>
                  <div style={{display:'flex',flexDirection:'row'}}>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <BsFillEyeSlashFill onClick={()=>seteye(false)} fontSize={30} style={{marginTop:5}}/>
                  </div>
                </Form.Group>
                 : <Form.Group className="mb-3" controlId="formBasicPassword" >
                 <Form.Label>Password</Form.Label>
                 <div style={{display:'flex',flexDirection:'row'}}>
                 <Form.Control
                   type="text"
                   name="password"
                   placeholder="Password"
                   value={password}
                   id="password"
                   onChange={(e) => setPassword(e.target.value)}
                 />
                 <BsEyeFill onClick={()=>seteye(true)} fontSize={30} style={{marginTop:5}}/>
                 </div>
               </Form.Group> }

                {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label=""  />
                  <h6 className="cck">Remember me</h6>
                </Form.Group> */}
                <Button variant="primary" type="submit">
                  Login
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
