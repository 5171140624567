import React from "react";
import { HiOutlineLightBulb } from "react-icons/hi";

function CareerGuidance() {
  return (
    <>
      <div className="career">
       
        <div className="container">
          <div class="ui-main col">
            <h2 class="heading3 mt-30" style={{
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#1f4a97",
            }}>How can we help you?</h2>
            <p>Make your choice to go ahead</p>
            <ul class="ui-list mt-40 mb-30">
              <li>
                <a href="/find-job/:jobs">
                  <figure>
                    <img
                      src="https://static1.shine.com/l/s/react/media/images/desktop/ui-list-icon1.png"
                      alt="Find the right job"
                    />
                  </figure>
                  <h3>Find the right job</h3>
                </a>
              </li>
              <li>
                <a href="/make-career-change">
                  <figure>
                    <img
                      src="https://static1.shine.com/l/s/react/media/images/desktop/ui-list-icon2.png"
                      alt="Make a career change"
                    />
                  </figure>
                  <h3>Make a career change</h3>
                </a>
              </li>
              {/* <li>
                <a href="/condidate-profile">
                  <figure>
                    <img
                      src="https://static1.shine.com/l/s/react/media/images/desktop/ui-list-icon3.png"
                      alt="Improve your profile"
                    />
                  </figure>
                  <h3>Improve your profile</h3>
                </a>
              </li> */}
              <li>
                <a href="/progress-your-career">
                  <figure>
                    <img
                      src="https://static1.shine.com/l/s/react/media/images/desktop/ui-list-icon4.png"
                      alt="Progress your career"
                    />
                  </figure>
                  <h3>Progress your career</h3>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default CareerGuidance;
