import React, { useEffect, useState } from "react";
import { HiUsers } from "react-icons/hi";
import { BsBook } from "react-icons/bs";
import { AiOutlineFilePdf } from "react-icons/ai";
import Header from "./Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Register() {
  let history = useNavigate();

  const [user, setUser] = useState({
    name: "",
    userName: "",
    email: "",
    mobile: "",
    industry: "",
    city: "",
    password: "",
    cpassword: "",
  });

  const {
    cpassword,
    email,
    password,
    mobile,
    userName,
    name,
    industry,
    city,
  } = user;

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { password, cpassword } = user;
     if (password !== cpassword) {
      alert("Passwords don't match");
    } else  {
      try {
        const config = {
          url: "/register",
          method: "post",
          baseURL: "https://univiindia.com/api/user",
          headers: { "content-type": "application/json" },
          data: user,
        };
        let res = await axios(config);
        if (res.status === 200) {
          history("/employee-login");
          // sessionStorage.setItem("user", JSON.stringify(res.data.user));
          alert("successfully added");
          // history.push("/");
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };
  const [alldata, setAlldata] = useState([]);

  const getAllcategory = async () => {
    try {
      let res = await axios.get("https://univiindia.com/api/admin/getAllCategory");
      if (res.status === 200) {
        setAlldata(res?.data?.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
    getAllcategory();
  }, []);

  return (
    <>
      <Header />
      <div className="container pr">
        <div
          class="login-page-bg-00"
        >
        
       
        {/* Login Start */}
        <div className="register-0 login-signup-box">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 class="modal-title" style={{
                  fontSize: "25px",
                  fontWeight: "600",
            }}>Register</h1>
            <h1 class="modal-title"  style={{
                  fontSize: "25px",
                  fontWeight: "600",
            }}>Employee</h1>
          </div>
          <form onSubmit={(e) => onSubmit(e)}>
            <fieldset>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  class="form-control"
                  id="name"
                  onChange={(e) => onInputChange(e)}
                  value={name}
                  required
                />
                <span class="error-txt"></span>
              </div>
              {/* <div className="form-group ">
                <input
                  type="text"
                  name="userName"
                  placeholder="User Name"
                  class="form-control"
                  id="userName"
                  onChange={(e) => onInputChange(e)}
                  value={userName}
                  required
                />
                <span class="error-txt"></span>
              </div> */}
              <div class="form-group">
                <input
                  type="text"
                  name="email"
                  class="form-control"
                  placeholder="Email"
                  id="id_email"
                  onChange={(e) => onInputChange(e)}
                  value={email}
                  required
                />
                <span class="error-txt"></span>
              </div>

              <div class="form-group">
                <input
                  type="text"
                  name="mobile"
                  placeholder="Mobile"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  class="form-control"
                  id="mobile"
                  required
                  onChange={(e) => onInputChange(e)}
                  value={mobile}
                />
                <span class="error-txt"></span>
              </div>
              {/* <div class="form-group">
                <select
                  class="form-control"
                  name="industry"
                  onChange={(e) => {
                    onInputChange(e);
                  }}
                  id="industry"
                >
                  <option value="">Select Category</option>

                  {alldata?.map((cat, ind) => {
                    return (
                      <option value={cat?.category}>{cat?.category}</option>
                    );
                  })}
                </select>
                <span class="error-txt"></span>
              </div> */}
              {/* <div class="form-group">
                <input
                  type="text"
                  name="city"
                  onKeyPress={(event) => {
                    if (!/[a-zA-Z ]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="City"
                  class="form-control"
                  id="city"
                  required
                  onChange={(e) => onInputChange(e)}
                  value={city}
                />
                <span class="error-txt"></span>
              </div> */}
              <div class="form-group ">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  class="form-control"
                  id="id_password"
                  onChange={(e) => onInputChange(e)}
                  value={password}
                  required
                />
                <span class="error-txt"></span>
              </div>
              <div class="form-group ">
                <input
                  type="text"
                  name="cpassword"
                  placeholder="Confirm Password"
                  class="form-control"
                  id="cpassword"
                  required
                  onChange={(e) => onInputChange(e)}
                  value={cpassword}
                />
                <span class="error-txt"></span>
              </div>
            </fieldset>
            <div className="form-check">
              <div class="form-check form-group error">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="term_conditions"
                    required
                  />
                  I agree to{" "}
                  <span onClick={()=>history("")} style={{cursor:"pointer",color:"#0d6efd"}}>
                    Terms &amp; Conditions
                  </span>{" "}
                  and
                  <span onClick={()=>history("")} style={{cursor:"pointer",color:"#0d6efd"}}>
                    {" "}
                    Privacy policy
                  </span>
                  <span class="error-txt"></span>
                </label>
              </div>
              <button type="submit" class="btn btn-primary submit" style={{
                 padding: "5px 20px",
                 borderRadius: "10px",
                 color: "white",
              }}>
                Register
              </button>
              <small class="form-text text-muted text-center" style={{
                paddingLeft:"25px"
              }}>
                Already have an account?<span onClick={()=>history("/employee-login")} style={{cursor:"pointer",color:"#0d6efd"}}>Login Now</span>
              </small>
            </div>
          </form>
        </div>
      </div>
      </div>
    </>
  );
}

export default Register;
