import React from "react";
import Wcs from "./Wcs";
import Button from "react-bootstrap/Button";
import { RxCross2 } from "react-icons/rx";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import exportFromJSON from "export-from-json";
import moment from "moment";
import Form from "react-bootstrap/Form";
import ReactPaginate from "react-paginate";
import { MdDelete } from "react-icons/md";
function Shortlisted() {
  const saveFile = (item, name) => {
    saveAs(`https://univiindia.com/user/${item}`, `${name}Resume.pdf`);
  };
  const [users, setusers] = useState([]);

  const employer = JSON.parse(sessionStorage.getItem("employer"));
  const getJobList = async () => {
    if (!employer) {
      alert("Please login");
    }
    let res = await axios.get("https://univiindia.com/api/user/AllAplliedDetals");
    if (res.status === 200) {
      //console.log(res.data);
      setusers(res.data.success);
    }
  };
  const deleteUser = async (id) => {
    let res = await axios.delete(
      "https://univiindia.com/api/user/deleteApply/" + id
    );
    if (res.status === 200) {
      alert(res.data.success);
      getJobList();
    }
  };
  const check = JSON.parse(sessionStorage.getItem("employer"));

  const loadUsers = async () => {
    const config = {
      url: "/user/getEmployerById/" + check?._id,
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        sessionStorage.removeItem("employer");
        sessionStorage.setItem("employer", JSON.stringify(result.data.success));
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  const [intrest, setintrest] = useState([]);
  
  const loadUsers1 = async () => {
    const config = {
      url: "/user/getInterestedUser/" + check?._id,
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setintrest(result.data.success);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  const addselect = async (userId, companyId) => {
    try {
      const config = {
        url: "/addSelect",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: {
          userId: userId,
          companyId: companyId,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          loadUsers();
          getJobList();
          getJobList1();
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };

  const rejectApply = async (userId, companyId) => {
    try {
      const config = {
        url: "/rejectApply",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: {
          userId: userId,
          companyId: companyId,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          loadUsers();
          getJobList();
          getJobList1();
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    getJobList();
    loadUsers();
    loadUsers1();
  }, []);
  const [user, setuser] = useState("");
  const [com, setcom] = useState("");

  const showModal = (userId, companyId) => {
    setuser(userId);
    setcom(companyId);
    setIsModalOpen(true);
  };
  //console.log(user, "gfdfd");
  const [reasion, setreasion] = useState("");
  const holdEmployer = async () => {
    const config = {
      url: "/user/callinterview",
      method: "post",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
      data: {
        schedule: reasion,
        userId: user._id,
        name: user.name,
        email: user.email,
        employerId: check._id,
        companyId: com,
      },
    };
    if(!reasion) {
      alert("Please Enter the schedule")
    } else {
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setIsModalOpen(false);
        alert(result.data.success);
        loadUsers();
        getJobList1();
        setreasion("");
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    holdEmployer();
  };
  const data = [];
  if (users?.length !== 0) {
    for (let i = 0; i < users.length; i++) {
      if (
        (users[i]?.companyId?.employerId == check?._id &&
          users[i]?.status === "Shortlisted") ||
        users[i]?.status === "Selected"
      ) {
        data.push({
          JobID: users[i]?.companyId?._id.slice(18, 24),
          CompanyName: users[i]?.companyId?.companyName,
          EmployeeName: users[i]?.userId?.name,
          EmployeeNumber: users[i]?.userId?.mobile,
          EmployeeEmail: users[i]?.userId?.email,
          status: users[i]?.status,

          ShortlistedOn: moment(users[i]?.updatedAt).fromNow(),
        });
      }
    }
  }
  if (intrest?.length !== 0) {
    for (let i = 0; i < intrest.length; i++) {
      data.push({
        EmployeeName: intrest[i]?.userId?.name,
        EmployeeNumber: intrest[i]?.userId?.mobile,
        EmployeeEmail: intrest[i]?.userId?.email,
        status: "Intrested",

        ShortlistedOn: moment(intrest[i]?.updatedAt).fromNow(),
      });
    }
  }
  const exportType = "xls";
  const [fileName, setfileName] = useState("ShortlistedDetails");
  const [users1, setusers1] = useState([]);
  const getJobList1 = async () => {
    let res = await axios.get(
      "https://univiindia.com/api/user/getcallinterview/" + employer._id
    );
    if (res.status === 200) {
      //console.log(res.data);
      setusers1(res.data.success);
      getJobList();
    }
  };
  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };
  const sched = users1.filter(
    (data) =>
      data.userId === user._id &&
      data.employerId === employer._id &&
      data.companyId === com
  );
  console.log(users, "users");
  useEffect(() => {
    getJobList1();
  }, []);
  const deleteApply = async (Id) => {
    try {
      const config = {
        url: "/deleteApply/" + Id,
        method: "delete",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getJobList();
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };
  const deleteIntrested = async (Id) => {
    try {
      const config = {
        url: "/deleteIntrestById/" + Id,
        method: "delete",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          loadUsers1();
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };
  console.log(users, "user");
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    users
    ?.filter((ele) => ele?.companyId?.employerId === check?._id)
    ?.filter(
      (el) =>
        el?.status === "Shortlisted" ||
        el?.status === "Selected" ||
        el?.status === "Rejected"
    ).length / usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <>
      {check?.status !== "Approved" ? (
        <div style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
          <br />
          <h3>Admin Not approved your profile please update.</h3>{" "}
          {check?.reasion ? <p>{check?.reasion}</p> : <></>}
        </div>
      ) : (
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            <div
              className="container"
              style={{
                border: "1px solid #24496a",
                borderRadius: "10px",
                marginBottom: "10px",
                boxShadow: " rgb(0 0 0 / 16%) 0px 1px 4px",
                border: " 1px solid rgb(36 73 106 / 21%)",
                width: "100%",
              }}
            >
              <div class="container">
                <div class="loginform" style={{ textAlign: "center" }}>
                  <h1
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                    }}
                  >
                    <b>Shortlisted Employees</b>
                  </h1>
                  {/* <button onClick={ExportToExcel} style={{backgroundColor:'green',color:'white',float:"right"}}>Export to Excel</button> */}
                </div>
              </div>
              <br />
              <div className="tble">
                <table style={{ width: "100%", marginBottom: "20px",zIndex:'auto' }}>
                  <tr>
                    <th>Job ID</th>
                    <th>Company Name</th>
                    <th>User Name</th>
                    <th>Contact</th>
                    {/* <th>Email ID</th> */}
                    {/* <th>Job Looking</th> */}
                    <th>Resume</th>
                    <th>ShortList on</th>
                    <th>Status</th>

                    <th>Action</th>
                    <th>Delete</th>
                  </tr>
                  {users
                    ?.filter((ele) => ele?.companyId?.employerId === check?._id)
                    ?.filter(
                      (el) =>
                        el?.status === "Shortlisted" ||
                        el?.status === "Selected" ||
                        el?.status === "Rejected"
                    ).slice(pagesVisited, pagesVisited + usersPerPage)
                    ?.map((items) => {
                      return (
                        <tr>
                          <td>
                            <Link
                              to={`/employeecompanydetails/${items?.companyId?._id}`}
                            >
                              {items?.companyId?._id.slice(18, 24)}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/employeecompanydetails/${items?.companyId?._id}`}
                            >
                              {items?.companyId?.companyName}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/user-profile-view1/${items?.userId?._id}`}
                            >
                              {items?.userId?.name}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/user-profile-view1/${items?.userId?._id}`}
                            >
                              {items?.userId?.mobile}
                            </Link>
                          </td>
                          {/* <td>
                            <Link
                              to={`/user-profile-view1/${items?.userId?._id}`}
                            >
                              {items?.userId?.email}
                            </Link>
                          </td> */}
                          {/* <td><Link to={`/user-profile-view1/${items?.userId?._id}`}>{items?.userId?.interest?.int ? (items?.userId?.interest?.int):(<RxCross2 style={{color:"red",fontSize:"20px"}}></RxCross2>)}</Link></td> */}
                          <td>
                            {items?.userId?.resume ? (
                              <a
                                onClick={() =>
                                  saveFile(
                                    items?.userId?.resume,
                                    items?.userId?.name
                                  )
                                }
                              >
                                {" "}
                                <img
                                  src="./pngwing.com.png"
                                  width="30"
                                  height="30"
                                  className="s"
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  alt="React Bootstrap logo"
                                />
                              </a>
                            ) : (
                              <RxCross2
                                style={{ color: "red", fontSize: "20px" }}
                              ></RxCross2>
                            )}
                          </td>
                          <td> {moment(items?.updatedAt).fromNow()}</td>
                          <td>
                            <Link
                              to={`/user-profile-view1/${items?.userId?._id}`}
                            >
                              {items?.status}
                            </Link>
                          </td>
                          <td>
                            {" "}
                            {items?.status !== "Rejected" ? (
                              <div style={{ flexDirection: "column" }}>
                                <Link
                                  onClick={() =>
                                    showModal(
                                      items?.userId,
                                      items?.companyId?._id
                                    )
                                  }
                                  class="btn btn-outline-primary"
                                >
                                  Call For an Interview
                                </Link>
                                <div style={{ marginTop: "10px" }}>
                                  {" "}
                                  <button
                                    onClick={() =>
                                      addselect(
                                        items?.userId?._id,
                                        items?.companyId?._id
                                      )
                                    }
                                    variant="primary"
                                    style={{
                                      color: "white",
                                      backgroundColor: "black",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    Select
                                  </button>
                                  <button
                                    onClick={() =>
                                      rejectApply(
                                        items?.userId?._id,
                                        items?.companyId?._id
                                      )
                                    }
                                    variant="danger"
                                    style={{
                                      color: "white",
                                      marginLeft: "20px",
                                      backgroundColor: "red",
                                      borderRadius: "5px",
                                      borderColor: "red",
                                    }}
                                  >
                                    Reject
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>{" "}
                          <td>
                            {" "}
                            {/* <Button
                              onClick={() => deleteApply(items?._id)}
                              variant="danger"
                              style={{ marginLeft: "10px" }}
                            >
                              Delete
                            </Button> */}
                              <MdDelete size={24}  onClick={() => deleteApply(items?._id)}
                                style={{ color: "red",marginLeft:'10px' }}/>
                          </td>
                        </tr>
                      );
                    })}
                  {intrest.map((items) => {
                    return (
                      <tr>
                        <td>
                          <Link
                            to={`/employeecompanydetails/${items?.companyId?._id}`}
                          >
                            {items?.companyId?._id.slice(18, 24)}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/employeecompanydetails/${items?.companyId?._id}`}
                          >
                            {items?.companyId?.companyName}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/user-profile-view1/${items?.userId?._id}`}
                          >
                            {items?.userId?.name}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/user-profile-view1/${items?.userId?._id}`}
                          >
                            {items?.userId?.mobile}
                          </Link>
                        </td>
                        {/* <td>
                          <Link
                            to={`/user-profile-view1/${items?.userId?._id}`}
                          >
                            {items?.userId?.email}
                          </Link>
                        </td> */}
                        {/* <td><Link to={`/user-profile-view1/${items?.userId?._id}`}>{items?.userId?.interest?.int ? (items?.userId?.interest?.int):(<RxCross2 style={{color:"red",fontSize:"20px"}}></RxCross2>)}</Link></td> */}
                        <td>
                          {items?.userId?.resume ? (
                            <a
                              onClick={() =>
                                saveFile(
                                  items?.userId?.resume,
                                  items?.userId?.name
                                )
                              }
                            >
                              {" "}
                              <img
                                src="./pngwing.com.png"
                                width="30"
                                height="30"
                                className="s"
                                style={{
                                  marginRight: "8px",
                                  cursor: "pointer",
                                }}
                                alt="React Bootstrap logo"
                              />
                            </a>
                          ) : (
                            <RxCross2
                              style={{ color: "red", fontSize: "20px" }}
                            ></RxCross2>
                          )}
                        </td>
                        <td> {moment(items?.updatedAt).fromNow()}</td>
                        <td>Interested</td>
                        <td></td>{" "}
                        <td>
                          {" "}
                          <MdDelete size={24}  onClick={() => deleteIntrested(items?._id)}
                                style={{ color: "red",marginLeft:'10px' }}/>
                          {/* <Button
                            onClick={() => deleteIntrested(items?._id)}
                            variant="danger"
                            style={{ marginLeft: "10px" }}
                          >
                            Delete
                          </Button> */}
                        </td>
                      </tr>
                    );
                  })}
                </table>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                /> 
              </div>
            </div>
            <p align="right" style={{ marginBottom: "2em" }}>
              <Button
                variant="primary"
                type="button"
                value="ShortList"
                onClick={ExportToExcel}
                style={{
                  backgroundColor: "rgb(5, 38, 78)",
                  color: "white",
                  border: "none",
                }}
              >
                <b style={{ color: "white" }}>Export to Excel</b>
              </Button>
            </p>
          </div>
          {/* <Modal
            title="Schedule"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              {!sched[0]?.schedule ? (
                <Form.Control
                  as="textarea"
                  type="text"
                  rows={3}
                  placeholder="Enter Interview date ,time and address "
                  value={reasion}
                  onChange={(e) => setreasion(e.target.value)}
                />
              ) : (
                sched[0]?.schedule
              )}
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </Modal> */}
          <Modal show={isModalOpen} onHide={handleCancel} >
                  <Modal.Header closeButton style={{backgroundColor:"#165c49"}}>
                    <Modal.Title style={{color:"white"}}>Schedule</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{backgroundColor:"#165c49"}}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                     
                    {!sched[0]?.schedule ? (  <Form.Control
                        type="text"
                        placeholder="Enter Interview date ,time and address "
                        value={reasion}
                onChange={(e) => setreasion(e.target.value)}
                      /> ) : (
                       <span style={{color:"white",fontSize:"20px"}}> {sched[0]?.schedule}</span>
                      )}
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer style={{backgroundColor:"#165c49"}}>
                    <Button variant="secondary" onClick={handleCancel}>
                      Close
                    </Button>
                    {!sched[0]?.schedule ?  <Button variant="primary" onClick={holdEmployer}>
                      Submit
                    </Button>:""}
                  </Modal.Footer>
                </Modal>
        </div>
      )}
    </>
  );
}

export default Shortlisted;
