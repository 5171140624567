import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

function Tabel() {
  const [unVerify, setUnVerify] = useState([]);

  const getVerifiedList = async () => {
    let res = await axios.get("https://univiindia.com/api/user/getUnvarifiedList");
    if (res.status === 200) {
      //console.log(res.data);
      setUnVerify(res.data.success);
    }
  };
  useEffect(()=>{
    getVerifiedList();
  },[])
  return (
    <Table striped bordered hover size="lg" style={{ width: "100%",zIndex:"auto" }}>
      <thead>
        <tr>
          <th>Job ID</th>
          <th>Company Name</th>
          <th>Job Title</th>

          <th>Email</th>
          
          <th>Posted on</th>
        </tr>
      </thead>
      <tbody>
        {unVerify?.map((item,i)=>{
          if(i<=2){
            return(
              <tr>
              <td>
                {" "}
                {item._id.slice(18,24)}
              </td>
              <td>
                <Link
                  to={`/admincompanyDetails/${item?._id}`}
                  name="name"
                  id="name"
                >
                  {item.companyName}
                </Link>
              </td>
              <td>
                <Link to={`/admincompanyDetails/${item?._id}`}>
                  {item.jobProfile}
                </Link>
              </td>
              <td>
                <Link to={`/admincompanyDetails/${item?._id}`}>
                  {item.email}
                </Link>
              </td>
              
              <td>{moment(item?.updatedAt).fromNow()}</td>
             
             
            </tr>
            )
          }
        })}
       
      </tbody>
       
     
    </Table>
  );
}

export default Tabel;