import React, { useEffect, useState } from "react";
import {
  MdDone,
  MdModeEditOutline,
  MdOutlineDeleteForever,
} from "react-icons/md";
import { GiCrossMark } from "react-icons/gi";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEye } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineBriefcase } from "react-icons/hi";
import { BsStopwatch } from "react-icons/bs";
import { DatePicker } from "antd";
import { Modal } from "antd";

import moment from "moment";
import { Form } from "react-bootstrap";

function EmployeeDetailsView1() {
  let users = JSON.parse(sessionStorage.getItem("employer"));

  const [endingDate, setEnding] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  // //console.log("amit date =======>",endingDate?.$y,startDate?.$y
  const [profile, setprofile] = useState(true);
  const [job, setjob] = useState(false);
  const [savejob, setsavejob] = useState(false);

  // //console.log(users);
  const formdata = new FormData();
  const [backgroud, setBackground] = useState("");
  const [resume, setResumes] = useState("");
  const [profileImage, setProimage] = useState("");
  const [user, setUser] = useState({});
  const [name, setname] = useState("");
  const [Email, setEmail] = useState("");
  const [mobile, setmobile] = useState("");
  const [bio, setbio] = useState("");
  const [Country, setCountry] = useState("");
  const [state, setstate] = useState("");
  const [City, setCity] = useState("");
  const [pincode, setpincode] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [int2, setint2] = useState("");
  const [int3, setint3] = useState("");
  const [educa, setEduca] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [skillset, setSkillset] = useState([]);
  const [editBtn, setEditbtn] = useState(false);
  const [show, setShow] = useState(false);
  const [joblist, setJobList] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [iner1, setIner1] = useState("");
  const [iner2, setIner2] = useState("");
  useEffect(() => {
    loadUsers();
  }, []);

  const history = useNavigate();

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Are you sure delete?
    </Tooltip>
  );

  const { id } = useParams();
  const loadUsers = async () => {
    const config = {
      url: "/user/getEmployerById/" + id,
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setUser(result.data.success);
        setEduca(result.data.success?.education);
        setCompanyData(result.data.success?.workAndExperience);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };

  const [reasion, setreasion] = useState("");

  const holdEmployer = async () => {
    const config = {
      url: "/user/makeVerifyUnverify",
      method: "post",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
      data: { status: "Hold", reasion: reasion, userId: id },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setIsModalOpen(false);
        alert("Successfully hold");
        loadUsers();
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };

  const ApprovedEmployee = async () => {
    const config = {
      url: "/user/makeVerifyUnverify",
      method: "post",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
      data: { status: "Approved", reasion: "", userId: id },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        alert("Successfully approved");
        loadUsers();
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    holdEmployer();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleOk1 = () => {
    setIsModalOpen1(false);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          <div className="profile">
            <section class="section-box-2">
              <div class="container">
                <div class="box-company-profile" style={{ padding: "unset" }}>
                  <div class="image-compay">
                    {user.profile ? (
                      <>
                        {" "}
                        <img
                          src={`https://univiindia.com/employer/${user?.profile}`}
                          alt="jobbox"
                          style={{
                            width: "98%",
                            borderRadius: "50%",
                            height: "81px",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src="/Images/profileIcon.png"
                          alt="jobbox"
                          style={{
                            width: "98%",
                            borderRadius: "50%",
                            height: "81px",
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div class="row mt-10">
                    <div class="col-lg-8 col-md-12">
                      <h5 class="f-18">
                        {user?.name}{" "}
                        <span class="card-location font-regular ml-20">
                          {user?.city} {user?.country}
                        </span>
                      </h5>
                      <p>
                        {user?.industry ? (
                          <span
                            style={{
                              border: "1px solid black",
                              borderRadius: "5px",
                              padding: "2px ",
                            }}
                          >
                            {user?.industry}
                          </span>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="section-box mt-50">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-8 col-sm-12">
                    <div className="content-single">
                      <div className="tab-content">
                        <div className="tab-pane fade show active">
                          <h3 class="mt-0 mb-15 color-brand-1">Account</h3>
                          <div style={{ color: "red", fontWeight: "bold" }}>
                            {" "}
                            {user?.reasion ? <p>{user?.reasion}</p> : <></>}
                          </div>
                          <a>
                            <strong> Employer profile</strong>
                          </a>

                          <div className="row form-contact">
                            <div className="col-lg-6 col-md-12">
                              <div class="form-group">
                                <label class="font-sm color-text-mutted mb-10">
                                  Full Name *
                                </label>
                                <br />
                                <span>{user.name}</span>
                              </div>
                              <div class="form-group">
                                <label class="font-sm color-text-mutted mb-10">
                                  Email *
                                </label>
                                <br />
                                <span>{user.email}</span>
                              </div>
                              <div class="form-group">
                                <label class="font-sm color-text-mutted mb-10">
                                  Contact Number *
                                </label>
                                <br />
                                <span>{user.mobile}</span>
                              </div>

                              <div class="form-group">
                                <label class="font-sm color-text-mutted mb-10">
                                  Industry *
                                </label>
                                <br />
                                <span>{user?.industry}</span>
                              </div>
                              <div class="row">
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Country
                                    </label>
                                    <br />
                                    <span>{user.country}</span>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      State
                                    </label>
                                    <br />
                                    <span>{user.state}</span>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      City
                                    </label>
                                    <br />
                                    <span>{user.city}</span>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label class="font-sm color-text-mutted mb-10">
                                      Zip code
                                    </label>
                                    <br />
                                    <span>{user.pincode}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex" style={{ gap: "20px" }}>
                                <button
                                  type="button"
                                  onClick={() => history(-1)}
                                  class="btn btn-dark"
                                >
                                  Back
                                </button>

                                {user?.status === "Approved" ? (
                                  // <p class="btn btn-dark"  style={{backgroundColor:"green", border:"1px solid green" }}><MdDone style={{    border: "1px solid", borderRadius: "50%"}}/>{" "}Approved</p>
                                  <button
                                    type="button"
                                    style={{
                                      backgroundColor: "green",
                                      border: "1px solid green",
                                    }}
                                    class="btn btn-dark"
                                  >
                                    <MdDone
                                      style={{
                                        border: "1px solid",
                                        borderRadius: "50%",
                                      }}
                                    />{" "}
                                    Approved
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    style={{
                                      backgroundColor: "green",
                                      border: "1px solid green",
                                    }}
                                    onClick={() => ApprovedEmployee()}
                                    class="btn btn-dark"
                                  >
                                    Approved
                                  </button>
                                )}

{user?.status === "Hold" ?   (
                                
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "red",
                                    border: "1px solid red",
                                  }}
                                  class="btn btn-dark "
                                >
                                  <MdDone
                                    style={{
                                      border: "1px solid",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  Hold
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "red",
                                    border: "1px solid red",
                                  }}
                                  onClick={showModal}
                                  class="btn btn-dark "
                                >
                                  Hold
                                </button>
                              )} 
                            </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                              <div
                                class="border-bottom  mb-30"
                                style={{
                                  borderBottom: "none",
                                  marginTop: "0px",
                                }}
                              >
                                <div class="form-group">
                                  <label class="font-sm color-text-mutted mb-10">
                                    We are hiring for *
                                  </label>
                                  <br></br>
                                  <span>{user?.hiring}</span>
                                </div>
                                {user?.hiring === "My Clients" ? (
                                  <div class="form-group">
                                    <div className="row">
                                      <p>
                                        <span class="font-sm color-text-mutted mb-10">
                                          Consultancy Name *&nbsp;{" "}
                                        </span>
                                        <br></br>
                                        <span>{user?.CompanyName}</span>
                                      </p>
                                      <p>
                                        <span class="font-sm color-text-mutted mb-10">
                                          Clients you normally hire *{" "}
                                        </span>
                                        <br></br>
                                        <span>{user?.CompanyInd}</span>
                                      </p>
                                      <p>
                                        <span class="font-sm color-text-mutted mb-10">
                                          Number Of Employees *{" "}
                                        </span>
                                        <br></br>
                                        <span>{user?.numberOfemp}</span>
                                      </p>
                                      <p>
                                        <span class="font-sm color-text-mutted mb-10">
                                          Company document *{" "}
                                        </span>
                                        <br></br>
                                        <span className="foeo">
                                          {user?.CompanydocType}
                                        </span>{" "}
                                        <span
                                          style={{
                                            color: "#164890",
                                            marginLeft: "20px",
                                          }}
                                        >
                                          <a
                                            href={`https://univiindia.com/employer/${user?.Companydoc}`}
                                            target="blank"
                                          >
                                            <FaEye className="dvd" />
                                          </a>
                                        </span>
                                      </p>
                                      <p>
                                        <span class="font-sm color-text-mutted mb-10">
                                          Your document *{" "}
                                        </span>
                                        <br></br>
                                        <span className="foeo">
                                          {user?.EmployerdocType}
                                        </span>{" "}
                                        <span
                                          style={{
                                            color: "#164890",
                                            marginLeft: "20px",
                                          }}
                                        >
                                          <a
                                            href={`https://univiindia.com/employer/${user?.Employerdoc}`}
                                            target="blank"
                                          >
                                            <FaEye className="dvd" />
                                          </a>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {user?.hiring === "My own Company" ? (
                                  <div class="form-group">
                                    <div className="row">
                                      <p>
                                        <span class="font-sm color-text-mutted mb-10">
                                          Company Name *&nbsp;{" "}
                                        </span>
                                        <br></br>
                                        <span>{user?.CompanyName}</span>
                                      </p>
                                      <p>
                                        <span class="font-sm color-text-mutted mb-10">
                                          Company website *{" "}
                                        </span>
                                        <br></br>
                                        <a
                                          href={user?.companyWebsite}
                                          target="_blank"
                                        >
                                          {user?.companyWebsite}
                                        </a>
                                      </p>
                                      <p>
                                        <span class="font-sm color-text-mutted mb-10">
                                          Your Industry *{" "}
                                        </span>
                                        <br></br>
                                        <span>{user?.CompanyInd}</span>
                                      </p>
                                      <p>
                                        <span class="font-sm color-text-mutted mb-10">
                                          Number Of Employees *{" "}
                                        </span>
                                        <br></br>
                                        <span>{user?.numberOfemp}</span>
                                      </p>
                                      <p>
                                        <span class="font-sm color-text-mutted mb-10">
                                          Company document *{" "}
                                        </span>
                                        <br></br>
                                        <span className="foeo">
                                          {user?.CompanydocType}
                                        </span>{" "}
                                        <span
                                          style={{
                                            color: "#164890",
                                            marginLeft: "20px",
                                          }}
                                        >
                                          <a
                                            href={`https://univiindia.com/employer/${user?.Companydoc}`}
                                            target="blank"
                                          >
                                            <FaEye className="dvd" />
                                          </a>
                                        </span>
                                      </p>
                                      <p>
                                        <span class="font-sm color-text-mutted mb-10">
                                          Your document *{" "}
                                        </span>
                                        <br></br>
                                        <span className="foeo">
                                          {user?.EmployerdocType}
                                        </span>{" "}
                                        <span
                                          style={{
                                            color: "#164890",
                                            marginLeft: "20px",
                                          }}
                                        >
                                          <a
                                            href={`https://univiindia.com/employer/${user?.Employerdoc}`}
                                            target="blank"
                                          >
                                            <FaEye className="dvd" />
                                          </a>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            title="Reason"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Enter reason"
                value={reasion}
                onChange={(e) => setreasion(e.target.value)}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default EmployeeDetailsView1;
