import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";

function Gallery1() {
  return (
    <>
      <div
        className="container"
        style={{
          border: "1px solid #24496a",
          background: "#f1f4fb",
          marginTop: "30px",
          borderRadius: "10px",
        }}
      >
        <div class="container">
          <div class="loginform" style={{ textAlign: "center" }}>
            <h1 style={{ color: "#24496a", marginLeft: "200px" }}>
              <b>
                Add Job{" "}
                <Button
                  href="/image1"
                  variant="success"
                  style={{ color: "white", marginLeft: "250px" }}
                >
                  +Add Job
                </Button>{" "}
              </b>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery1;
