import React from "react";
// back-e
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

function Wcs() {
  return (
    <>
      {/* <br />
      <div className="row">
        <div className="col-md-3"></div> */}
        {/* <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "600px" }}
        ></div> */}
        {/* card */}
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <MDBCard style={{ maxWidth: "540px" }}>
                <MDBRow className="g-0">
                  <MDBCol md="4">
                    <MDBCardImage src="e1.png" alt="..." fluid />
                  </MDBCol>
                  <MDBCol md="8">
                    <MDBCardBody>
                      <MDBCardTitle>Unverified Jobs</MDBCardTitle>
                      <MDBCardText>
                        <h></h>
                        <h1>19786</h1>
                      </MDBCardText>
                      <MDBCardText>
                        <small className="text-muted">
                          Last updated 3 mins ago
                        </small>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </div>
            <div className="col-md-4">
              <MDBCard style={{ maxWidth: "540px" }}>
                <MDBRow className="g-0">
                  <MDBCol md="4">
                    <MDBCardImage src="e3.png" alt="..." fluid />
                  </MDBCol>
                  <MDBCol md="8">
                    <MDBCardBody>
                      <MDBCardTitle>Approved Jobs</MDBCardTitle>
                      <MDBCardText>
                        <h1>$ 9789</h1>
                      </MDBCardText>
                      <MDBCardText>
                        <small className="text-muted">
                          Last updated 3 mins ago
                        </small>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </div>

            <div className="col-md-4">
              <MDBCard style={{ maxWidth: "540px" }}>
                <MDBRow className="g-0">
                  <MDBCol md="4">
                    <MDBCardImage src="e4.png" alt="..." fluid />
                  </MDBCol>
                  <MDBCol md="8">
                    <MDBCardBody>
                      <MDBCardTitle>Applied Jobs</MDBCardTitle>
                      <MDBCardText>
                        <h1>$ 9824</h1>
                      </MDBCardText>
                      <MDBCardText>
                        <small className="text-muted">
                          Last updated 3 mins ago
                        </small>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </div>
          </div>
        </div>
        {/* card */}
      {/* </div> */}
    </>
  );
}

export default Wcs;
