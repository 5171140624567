import React from "react";
import Viewprofile from "./Viewprofile";
import Wcs from "./Wcs";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function Changepassword() {
  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
        

          <div
            className="container"
            style={{
              border: "1px solid #24496a",
              background: "#f1f4fb",
              marginTop: "30px",
              borderRadius: "10px",
              height: "500px",
              overflow: "scroll",
              marginBottom: "10px",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Edit Login Details</b>
                </h1>
              </div>
            </div>
            {/*  */}
            <div className="row">
              <div className="col-md-4">
                <Viewprofile />
              </div>
              <div className="col-md-2">
                <div
                  id="profileDetailsWrap"
                  style={{
                    textAlign: "right",
                    marginTop: "50px",
                    color: "black",
                  }}
                >
                  <div class="profileLable">
                    <ul>
                      <br />
                      <h5>Old Password:</h5>
                      <br />
                      <h5>New Password:</h5>
                      <br />
                      <h5>Confirm Password:</h5>
                      <br />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div class="profileDetailBox">
                  <ul
                    style={{
                      fontSize: "12px",
                      marginTop: "50px",
                      color: "black",
                    }}
                  >
                    <br />
                    <h5>
                      <Form.Control
                        type="password"
                        placeholder="Old Password"
                        style={{ width: "300px" }}
                      />
                    </h5>
                    <br />
                    <h5>
                      <Form.Control
                        type="password"
                        placeholder="New Password"
                        style={{ width: "300px" }}
                      />
                    </h5>
                    <br />
                    <h5>
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        style={{ width: "300px" }}
                      />
                    </h5>
                  </ul>
                  <Button
                    style={{
                      marginLeft: "134px",
                      padding: "6px 20px",
                      backgroundColor: "rgb(5, 38, 78)",
                      color: "white",
                      border: "none",
                    }}
                    href="/profile"
                  >
                    Save
                  </Button>
          
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Changepassword;
