import React, { useEffect, useState } from "react";
import { MdModeEditOutline, MdOutlineDeleteForever } from "react-icons/md";
import { GiCrossMark } from "react-icons/gi";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
// import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import { Modal } from "react-bootstrap";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineBriefcase } from "react-icons/hi";
import { BsStopwatch } from "react-icons/bs";
import { DatePicker } from "antd";
import { Button, Modal } from "antd";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import moment from "moment";
import { Country, State, City } from "country-state-city";
import { AiOutlineLock } from "react-icons/ai";
import { FaEye } from "react-icons/fa";

function EmployerProfile() {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [users, setUsers] = useState(
    JSON.parse(sessionStorage.getItem("employer"))
  );

  const [weHire, setwehire] = useState("");

  const [changepwd, setchnagepwd] = useState(true);

  const [endingDate, setEnding] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  // //console.log("amit date =======>",endingDate?.$y,startDate?.$y
  const [profile, setprofile] = useState(true);
  const [job, setjob] = useState(false);
  const [savejob, setsavejob] = useState(false);

  // //console.log(users);
  const formdata = new FormData();
  const [backgroud, setBackground] = useState("");
  const [resume, setResumes] = useState("");
  const [profileImage, setProimage] = useState("");
  const [user, setUser] = useState({});
  const [name, setname] = useState(users.name);
  const [Email, setEmail] = useState(users.email);
  const [mobile, setmobile] = useState(users.mobile);
  const [bio, setbio] = useState("");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setpincode] = useState(users.pincode);
  const [password, setpassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [int2, setint2] = useState("");
  const [int3, setint3] = useState("");
  const [address, setaddress] = useState("");

  const [educa, setEduca] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [skillset, setSkillset] = useState([]);
  const [editBtn, setEditbtn] = useState(false);
  const [show, setShow] = useState(false);
  const [joblist, setJobList] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [iner1, setIner1] = useState("");
  const [iner2, setIner2] = useState("");
  const [Ourcompany, setOurCompany] = useState(false);
  const [OurClient, setOurClient] = useState(false);
  const [alldata, setAlldata] = useState([]);
  //console.log("setOurClient", Ourcompany, OurClient);
  const getAllcategory = async () => {
    try {
      let res = await axios.get("https://univiindia.com/api/admin/getAllIndustry");
      if (res.status === 200) {
        setAlldata(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const history = useNavigate();

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Are you sure delete?
    </Tooltip>
  );

  const loadUsers = async () => {
    const config = {
      url: "/user/getEmployerById/" + users._id,
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setUser(result.data.success);
        setEduca(result.data.success?.education);
        setCompanyData(result.data.success?.workAndExperience);
        setwehire(result.data.success?.hiring);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };

  const getJob = async () => {
    let obj = {};
    if (iner1) {
      obj["int"] = iner1;
    }
    if (iner2) {
      obj["int1"] = iner2;
    }
    const config = {
      url: "/user/getIntrestJob",
      method: "post",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
      data: obj,
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setJobList(result.data.success);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  if (job) {
    if (joblist.length == 0) {
      getJob();
    }
  }

  const [reasion1, setReasion1] = useState("");

  function onChangeValue(event) {
    setReasion1(event.target.value);
  }
  //console.log("reasion====>", reasion1);
  const deleteUser = async () => {
    const config = {
      url: "/user/deleteProfileEmployer",
      method: "post",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
      data: { userId: users._id, reasion: reasion1 },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        alert("Successfully deleted");
        sessionStorage.removeItem("employer");
        history("/");
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };

  const onSubmit = async () => {
    formdata.append("userId", users._id);
    formdata.append("backgroundImage", backgroud);
    formdata.append("profile", profileImage);

    try {
      const config = {
        url: "/editProfileEmployer",
        method: "put",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        // alert("successfully Updated");
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  // useEffect(()=>{
  //   setUsers(JSON.parse(sessionStorage.getItem("employer")))
  // },[])

  useEffect(() => {
    //console.log(selectedCountry);
    //console.log(selectedCountry?.isoCode);
    //console.log(State?.getStatesOfCountry(selectedCountry?.isoCode));

    loadUsers();
    getAllcategory();
  }, [selectedCountry]);
  if (backgroud) {
    onSubmit();
  }
  if (profileImage) {
    onSubmit();
  }

  const [company, setCompany] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyIndustry, setCompanyIndustry] = useState('');
  const [numberofEmp, setnumberofEmp] = useState("");
  const [cyourId, setcyourId] = useState("");
  const [cyourdoc, setcyourdoc] = useState("");
  const [comDocT, setcomDocT] = useState("");
  const [comDoc, setCompDoc] = useState("");

  const [Consultname, setConsultname] = useState('');
  const [clientNarmal, setclientNarmal] = useState('');
  const [website, setwebsite] = useState('');

  const [clientNumber, setclientNumber] = useState("");
  const [clientId, setclientId] = useState("");
  const [clientDoc, setclientDoc] = useState("");
  const [clintCompT, setclintCompT] = useState("");
  const [clientComp, setclientComp] = useState("");
  // const[comDoc,setCompDoc]=useState("")
  const addCompanyDeatails = async () => {
    try {
      if (Ourcompany) {
        const formdata = new FormData();
        formdata.append("hiring", "My own Company");
        formdata.append("CompanyName", company);
        formdata.append("companyWebsite", companyWebsite);
        formdata.append("CompanyInd", companyIndustry);
        formdata.append("numberOfemp", numberofEmp);
        formdata.append("CompanydocType", comDocT);
        formdata.append("Companydoc", comDoc);
        formdata.append("EmployerdocType", cyourId);
        formdata.append("Employerdoc", cyourdoc);
        formdata.append("userId", user?._id);
        const config = {
          url: "/editProfileEmployer",
          method: "put",
          baseURL: "https://univiindia.com/api/user",
          headers: { "content-type": "multipart/form-data" },
          data: formdata,
        };
        if (user?.hiring !== "My own Company") {
          if (!company) {
            alert("Please enter Company name!");
          } else if (!companyIndustry) {
            alert("Please enter your industry !");
          // } else if (!companyWebsite) {
          //   alert("Please enter website link!");
          } else if (!numberofEmp) {
            alert("Please enter number of employees!");
          } else if (!cyourId || !cyourdoc) {
            alert("Please attach your id!");
          } else if (!comDocT || !comDoc) {
            alert("Please attach your company document!");
          } else {
            let res = await axios(config);
            if (res.status === 200) {
              alert("successfully Updated");
              loadUsers();
              handleOk1();
            } else {
              alert("Something Wrong");
            }
          }
        } else {
          let res = await axios(config);
          if (res.status === 200) {
            alert("successfully Updated");
            loadUsers();
            handleOk1();
          } else {
            alert("Something Wrong");
          }
        }
      }
      if (OurClient) {
        const formdata = new FormData();
        formdata.append("hiring", "My Clients");
        formdata.append("CompanyName", Consultname);
        formdata.append("userId", user?._id);
        formdata.append("CompanyInd", clientNarmal);
        formdata.append("companyWebsiteclient", website);
        formdata.append("numberOfemp", clientNumber);
        formdata.append("CompanydocType", clintCompT);
        formdata.append("Companydoc", clientComp);
        formdata.append("EmployerdocType", clientId);
        formdata.append("Employerdoc", clientDoc);
        const config = {
          url: "/editProfileEmployer",
          method: "put",
          baseURL: "https://univiindia.com/api/user",
          headers: { "content-type": "multipart/form-data" },
          data: formdata,
        };
        if (user?.hiring !== "My Clients") {
          if (!Consultname) {
            alert("Please enter consultancy name!");
          } else if (!clientNarmal) {
            alert("Please enter clients you normally hire!");
          } else if (!clientNumber) {
            alert("Please enter number of employees!");
          } else if (!clientId || !clientDoc) {
            alert("Please attach your id!");
          } else if (!clintCompT || !clientComp) {
            alert("Please attach your consultancy document!");
          } else {
            let res = await axios(config);
            if (res.status === 200) {
              alert("successfully Updated");
              loadUsers();
              handleOk2();
            } else {
              alert("Something Wrong");
            }
          }
        } else {
          let res = await axios(config);
          if (res.status === 200) {
            alert("successfully Updated");
            loadUsers();
            handleOk2();
          } else {
            alert("Something Wrong");
          }
        }
      }
    } catch (error) {
      alert(error.response.data.error);
      //console.log(error);
    }
  };

  const editUser = async () => {
    let obj = {
      userId: users._id,
      name: name,
      mobile: mobile,
      email: Email,
      industry: bio,
address:address,
      pincode: pincode,
      resume: resume,
      password: password,
      cpassword: cpassword,
    };
   
    if (country?.name !== "undefinde") {
      obj["country"] = country.name;
    }
    if (state?.name !== "undefinde") {
      obj["state"] = state.name;
    }
    if (city?.name !== "undefinde") {
      obj["city"] = city.name;
    }
    if (!user?.hiring) {
      alert("Please complete your hiring for!");
    } else  if(!bio && !user?.industry){
      alert("Please Select Industry")
    }
    else  if(!address && !user?.address){
      alert("Please enter address")
    }else if (password) {
      try {
        const config = {
          url: "/editProfileEmployer",
          method: "put",
          baseURL: "https://univiindia.com/api/user",
          headers: { "content-type": "multipart/form-data" },
          data: obj,
        };
        let res = await axios(config);
        if (res.status === 200) {
          history("/employers-login");
          sessionStorage.removeItem("employer");

          alert("Successfully changed your password");
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    } else {
      try {
        const config = {
          url: "/editProfileEmployer",
          method: "put",
          baseURL: "https://univiindia.com/api/user",
          headers: { "content-type": "multipart/form-data" },
          data: obj,
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Successfully updated, Kindly wait for admin approval ");
          sessionStorage.removeItem("employer");
          sessionStorage.setItem("employer", JSON.stringify(res.data.success));
          window.location.reload(true);
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
    // }
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteUser();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  

  // our company
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  // our client
  // oho;hi
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
          {/* <Wcs /> */}

          <div
            className="container img"
            style={{
              background: "#f1f4fb",
              // marginTop: "30px",
              borderRadius: "10px",
              // height: "500px",
              // overflow: "scroll",
              width: "100%",
            }}
          ></div>
          <div className="profile">
            <section class="section-box-2">
              <div class="container">
                {/* <div
                  class="banner-hero banner-image-single"
                  style={{ padding: "unset" }}
                >
                  {user.backgroundImage ? (
                    <>
                      {" "}
                      <img
                        src={`https://univiindia.com/employer/${user?.backgroundImage}`}
                        alt="jobbox"
                        style={{ width: "100%", height: "400px" }}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      <img
                        src={"/Images/img.png"}
                        alt="jobbox"
                        style={{ width: "100%", height: "400px" }}
                      />
                    </>
                  )}
                  <a
                    class="btn-editor"
                    href="#"
                    htmlFor="upload1"
                    style={{ bottom: "11px", right: "31px" }}
                  >
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <input
                        hidden
                        type="file"
                        name="file"
                        id="upload1"
                        accept="image/*"
                        onChange={(e) => {
                          setBackground(e.target.files[0]);
                        }}
                      />
                      <PhotoCamera />
                    </IconButton>
                  </a>
                </div> */}

                <div class="box-company-profile" style={{ padding: "unset" }}>
                  <div class="image-compay">
                    {user.profile ? (
                      <>
                        {" "}
                        <img
                          src={`https://univiindia.com/employer/${user?.profile}`}
                          alt="jobbox"
                          style={{
                            width: "98%",
                            borderRadius: "50%",
                            height: "81px",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src="/Images/profileIcon.png"
                          alt="jobbox"
                          style={{
                            width: "98%",
                            borderRadius: "50%",
                            height: "81px",
                          }}
                        />
                      </>
                    )}

                    <a
                      style={{
                        position: "absolute",
                        top: "52px",
                        left: "41px",
                      }}
                    >
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                        htmlFor="upload1"
                      >
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          name="file"
                          id="upload1"
                          onChange={(e) => {
                            setProimage(e.target.files[0]);
                          }}
                        />
                        <PhotoCamera style={{ color: "white" }} />
                      </IconButton>
                    </a>
                  </div>
                  <div class="row mt-10">
                    <div class="col-lg-8 col-md-12">
                      <h5 class="f-18">
                        {user?.name}{" "}
                        <span class="card-location font-regular ml-20">
                          {user?.city} {user?.country}
                        </span>
                      </h5>
                      <p>
                        {user?.industry ? (
                          <span
                            style={{
                              border: "1px solid black",
                              borderRadius: "5px",
                              padding: "2px ",
                            }}
                          >
                            {user?.industry}
                          </span>
                        ) : (
                          <></>
                        )}{" "}
                      </p>
                      
                      {/* <p class="mt-0 font-md color-text-paragraph-2 mb-15">
                        {user.industry ? user?.industry : <></>}
                      </p> */}
                    </div>
                  </div>
                </div>
                <div class="" style={{ textAlign: "right" }}>
                  {/* <a
                      class="link-red"
                      href="#"
                      onClick={() => {
                        // deleteUser();
                      }}
                    >
                      Delete Account
                    </a> */}
                </div>
              </div>
            </section>

            {/* /////////////////// */}

            <div className="section-box ">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-8 col-sm-12">
                    {profile ? (
                      <>
                        {editBtn ? (
                          <>
                            <div className="content-single">
                              <div className="tab-content">
                                <div className="tab-pane fade show active">
                                  <h3
                                    class="mt-0 mb-15 color-brand-1"
                                    style={{ color: "black" }}
                                  >
                                    My Account
                                  </h3>
                                  <div
                                    style={{
                                      textAlign: "center",
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    {user?.reasion ? (
                                      <p>{user?.reasion}</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <button
                                    type="button"
                                    class="btn btn-dark"
                                    style={{
                                      float: "right",
                                      margin: "10px",
                                    }}
                                    onClick={() => {
                                      setEditbtn(!editBtn);
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <a
                                  // class="font-md color-text-paragraph-2"
                                  // href="#"
                                  >
                                    <strong>Update your profile</strong>
                                  </a>
                                

                                  <div className="row form-contact">
                                    <div className="col-lg-6 col-md-12">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Full Name *
                                        </label>
                                        <input
                                          class="form-control"
                                          type="text"
                                          name="name"
                                          placeholder={user.name}
                                          value={name}
                                          onChange={(e) => {
                                            setname(e.target.value);
                                          }}
                                        />
                                      </div>
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Email *
                                        </label>
                                        <input
                                          class="form-control"
                                          type="text"
                                          name="email"
                                          placeholder={user.email}
                                          value={Email}
                                          onChange={(e) => {
                                            setEmail(e.target.value);
                                          }}
                                        />
                                      </div>
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Contact Number *
                                        </label>
                                        <input
                                          class="form-control"
                                          type="text"
                                          name="mobile"
                                          placeholder={user.mobile}
                                          value={mobile}
                                          onChange={(e) => {
                                            setmobile(e.target.value);
                                          }}
                                        />
                                      </div>

                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Industry *
                                        </label>
                                        <Form.Select
                                          aria-label="Default select example"
                                          onChange={(e) => {
                                            setbio(e.target.value);
                                          }}
                                        >
                                          <option>{users.industry}</option>

                                          {alldata?.map((items) => {
                                            return (
                                              <option value={items?.Industry}>
                                                {items?.Industry}
                                              </option>
                                            );
                                          })}
                                        </Form.Select>
                                      </div>

                                      <label class="font-sm color-text-mutted mb-10">
                                        Address * :-
                                      </label>
                                      <textarea
                                              class="form-control"
                                              style={{ height: "41px" ,marginBottom:"20px"}}
                                              type="text"
                                             
                                              placeholder={user.address ? user.address : "Please enter your address"}
                                              value={address}
                                              onChange={(e) => {
                                                setaddress(e.target.value);
                                              }}
                                            />
                                      <div
                                        class="row"
                                        style={{ marginLeft: "3px" }}
                                      >
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              Country *
                                            </label>

                                            <Select
                                              options={Country.getAllCountries()}
                                              getOptionLabel={(options) => {
                                                return options["name"];
                                              }}
                                              getOptionValue={(options) => {
                                                return options["name"];
                                              }}
                                              value={selectedCountry}
                                              onChange={(item) => {
                                                setSelectedCountry(item);
                                                setcountry(item);
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              State *
                                            </label>
                                            <Select
                                              options={State?.getStatesOfCountry(
                                                selectedCountry?.isoCode
                                              )}
                                              getOptionLabel={(options) => {
                                                return options["name"];
                                              }}
                                              getOptionValue={(options) => {
                                                return options["name"];
                                              }}
                                              value={selectedState}
                                              onChange={(item) => {
                                                setSelectedState(item);
                                                setstate(item);
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              City *
                                            </label>
                                            <Select
                                              options={City.getCitiesOfState(
                                                selectedState?.countryCode,
                                                selectedState?.isoCode
                                              )}
                                              getOptionLabel={(options) => {
                                                return options["name"];
                                              }}
                                              getOptionValue={(options) => {
                                                return options["name"];
                                              }}
                                              value={selectedCity}
                                              onChange={(item) => {
                                                setSelectedCity(item);
                                                setCity(item);
                                              }}
                                            />
                                            {/* <input
                                          class="form-control"
                                          type="text"
                                          placeholder={user.city}
                                          value={City}
                                          onChange={(e) => {
                                            setCity(e.target.value);
                                          }}
                                        /> */}
                                          </div>
                                        </div>
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              Zip code *
                                            </label>
                                            <input
                                              class="form-control"
                                              style={{ height: "41px" }}
                                              type="text"
                                              placeholder={user.pincode}
                                              value={pincode}
                                              onChange={(e) => {
                                                setpincode(e.target.value);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <h6
                                        class="color-orange mb-20"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setchnagepwd(!changepwd);
                                        }}
                                      >
                                        Change your password{" "}
                                        <span>
                                          <AiOutlineLock />
                                        </span>
                                      </h6>
                                      {changepwd ? (
                                        <></>
                                      ) : (
                                        <>
                                          <div class="row">
                                            <div class="col-lg-6">
                                              <div class="form-group">
                                                <label class="font-sm color-text-mutted mb-10">
                                                  Password
                                                </label>
                                                <input
                                                  class="form-control"
                                                  type="password"
                                                  value={password}
                                                  onChange={(e) =>
                                                    setpassword(e.target.value)
                                                  }
                                                  placeholder="Enter your new password"
                                                />
                                              </div>
                                            </div>
                                            <div class="col-lg-6">
                                              <div class="form-group">
                                                <label class="font-sm color-text-mutted mb-10">
                                                  Confirm Password *
                                                </label>
                                                <input
                                                  class="form-control"
                                                  type="confirm password"
                                                  value={cpassword}
                                                  onChange={(e) =>
                                                    setCpassword(e.target.value)
                                                  }
                                                  placeholder="Enter confirm password"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <a
                                      // class="font-md color-text-paragraph-2"
                                      // href="#"
                                      >
                                        <strong>Please click on the save button to save the changes </strong>
                                      </a>
                                      <div
                                        class="box-button mt-15"
                                        style={{
                                          marginTop: "20px",
                                          marginBottom: "20px",
                                          // textAlign:"center"
                                          // marginLeft:"20px"
                                        }}
                                      >
                                        <button
                                          class="btn-apply-big font-md font-bold"
                                          onClick={() => editUser()}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                      <div
                                        class="border-bottom  mb-30"
                                        style={{
                                          borderBottom: "none",
                                          marginTop: "0px",
                                        }}
                                      >
                                        <div class="form-group">
                                          <label class="font-sm color-text-mutted mb-10">
                                            Whom are you hiring for ? *
                                          </label>
                                          <Form>
                                            {["radio"].map((type) => (
                                              <div
                                                key={`inline-${type}`}
                                                className="mb-3"
                                              >
                                                <Form.Check
                                                  onClick={() => {
                                                    showModal1();
                                                    setOurCompany(true);
                                                    setOurClient(false);
                                                  }}
                                                  inline
                                                  label="My own  Company"
                                                  name="group1"
                                                  value="My own Company"
                                                  checked={
                                                    weHire === "My own Company"
                                                  }
                                                  type={type}
                                                  id={`inline-${type}-1`}
                                                  onChange={() => {
                                                    setwehire("My own Company");
                                                  }}
                                                />
                                                <Form.Check
                                                  onClick={() => {
                                                    showModal2();
                                                    setOurCompany(false);
                                                    setOurClient(true);
                                                  }}
                                                  inline
                                                  label="My Clients"
                                                  value="My Clients"
                                                  name="group1"
                                                  checked={
                                                    weHire === "My Clients"
                                                  }
                                                  type={type}
                                                  id={`inline-${type}-2`}
                                                  onChange={() => {
                                                    setwehire("My Clients");
                                                  }}
                                                />
                                              </div>
                                            ))}
                                          </Form>
                                        </div>
                                        <div class="form-group"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="content-single">
                              <div className="tab-content">
                                <div className="tab-pane fade show active">
                                  <h3 class="mt-0 mb-15 color-brand-1">
                                    My Account
                                  </h3>
                                  <div
                                    style={{
                                      textAlign: "center",
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    {user?.reasion ? (
                                      <p>{user?.reasion}</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <button
                                    type="button"
                                    class="btn btn-dark"
                                    style={{
                                      float: "right",
                                      margin: "10px",
                                    }}
                                    onClick={() => {
                                      setEditbtn(!editBtn);
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <a
                                  // class="font-md color-text-paragraph-2"
                                  // href="#"
                                  >
                                    <strong>Your profile</strong>
                                  </a>

                                  <div className="row form-contact">
                                    <div className="col-lg-6 col-md-12">
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Full Name *
                                        </label>
                                        <br />
                                        <span>{user.name}</span>
                                      </div>
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Email *
                                        </label>
                                        <br />
                                        <span>{user.email}</span>
                                      </div>
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Contact Number *
                                        </label>
                                        <br />
                                        <span>{user.mobile}</span>
                                      </div>

                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Industry *
                                        </label>
                                        <br />
                                        <span>{user?.industry}</span>
                                      </div>
                                      <div class="form-group">
                                        <label class="font-sm color-text-mutted mb-10">
                                          Address *
                                        </label>
                                        <br />
                                        <span>{user?.address}</span>
                                      </div>
                                      <div class="row">
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              Country *
                                            </label>
                                            <br />
                                            <span>{user.country}</span>
                                          </div>
                                        </div>
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              State *
                                            </label>
                                            <br />
                                            <span>{user.state}</span>
                                          </div>
                                        </div>
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              City *
                                            </label>
                                            <br />
                                            <span>{user.city}</span>
                                          </div>
                                        </div>
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <label class="font-sm color-text-mutted mb-10">
                                              Zip code *
                                            </label>
                                            <br />
                                            <span>{user.pincode}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                      <div
                                        class="border-bottom  mb-30"
                                        style={{
                                          borderBottom: "none",
                                          marginTop: "0px",
                                        }}
                                      >
                                        {/* <div class="form-group">
                                          <label  class="font-sm color-text-mutted mb-10">
                                            Educations *
                                          </label>
                                          <br />
                                          {educa.map((data) => {
                                            return (
                                              <div className="row">
                                                <p>
                                                  <span className="amit-0">
                                                    Institue*&nbsp;:{" "}
                                                  </span>
                                                  <span>{data.Institue}</span>
                                                </p>
                                                <p>
                                                  <span className="amit-0">
                                                    Course*:{" "}
                                                  </span>
                                                  <span>
                                                    {data.Course} ({data.field})
                                                  </span>
                                                </p>
                                                <p>
                                                  <span className="amit-0">
                                                    Duration*:{" "}
                                                  </span>
                                                  <span>
                                                    {data.starting}-
                                                    {data.passOut}
                                                  </span>
                                                  <span
                                                    className="rahul"
                                                    onClick={() => {
                                                      removeED(data._id);
                                                    }}
                                                  >
                                                    <OverlayTrigger
                                                      placement="right"
                                                      delay={{
                                                        show: 250,
                                                        hide: 400,
                                                      }}
                                                      overlay={renderTooltip}
                                                    >
                                                      <div>
                                                        <MdOutlineDeleteForever />
                                                      </div>
                                                    </OverlayTrigger>
                                                  </span>
                                                </p>
                                              </div>
                                            );
                                          })}
                                        </div> */}
                                        <div class="form-group">
                                          <label class="font-sm color-text-mutted mb-10">
                                            We are hiring for *
                                          </label>
                                          <br></br>
                                          <span>{user?.hiring}</span>
                                        </div>
                                        {user?.hiring === "My Clients" ? (
                                          <div class="form-group">
                                            <div className="row">
                                              <p>
                                                <span class="font-sm color-text-mutted mb-10">
                                                  Consultancy Name *&nbsp;{" "}
                                                </span>
                                                <br></br>
                                                <span>{user?.CompanyName}</span>
                                              </p>
                                              {user?.companyWebsiteclient ? <p>
                                                <span class="font-sm color-text-mutted mb-10">
                                                  Company website 
                                                </span>
                                                <br></br>
                                                <a
                                                  href={user?.companyWebsiteclient}
                                                  target="_blank"
                                                >
                                                  {user?.companyWebsiteclient}
                                                </a>
                                              </p> : ""}
                                              <p>
                                                <span class="font-sm color-text-mutted mb-10">
                                                  Clients you normally hire *{" "}
                                                </span>
                                                <br></br>
                                                <span>{user?.CompanyInd}</span>
                                              </p>
                                              <p>
                                                <span class="font-sm color-text-mutted mb-10">
                                                  Number Of Employees *{" "}
                                                </span>
                                                <br></br>
                                                <span>{user?.numberOfemp}</span>
                                              </p>
                                              <p>
                                                <span class="font-sm color-text-mutted mb-10">
                                                  Company document *{" "}
                                                </span>
                                                <br></br>
                                                <span className="foeo">
                                                  {user?.CompanydocType}
                                                </span>{" "}
                                                <span
                                                  style={{
                                                    color: "#164890",
                                                    marginLeft: "20px",
                                                  }}
                                                >
                                                  <a
                                                    href={`https://univiindia.com/employer/${user?.Companydoc}`}
                                                    target="blank"
                                                  >
                                                    <FaEye className="dvd" />
                                                  </a>
                                                </span>
                                              </p>
                                              <p>
                                                <span class="font-sm color-text-mutted mb-10">
                                                  Your document *{" "}
                                                </span>
                                                <br></br>
                                                <span className="foeo">
                                                  {user?.EmployerdocType}
                                                </span>{" "}
                                                <span
                                                  style={{
                                                    color: "#164890",
                                                    marginLeft: "20px",
                                                  }}
                                                >
                                                  <a
                                                    href={`https://univiindia.com/employer/${user?.Employerdoc}`}
                                                    target="blank"
                                                  >
                                                    <FaEye className="dvd" />
                                                  </a>
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {user?.hiring === "My own Company" ? (
                                          <div class="form-group">
                                            <div className="row">
                                              <p>
                                                <span class="font-sm color-text-mutted mb-10">
                                                  Company Name *&nbsp;{" "}
                                                </span>
                                                <br></br>
                                                <span>{user?.CompanyName}</span>
                                              </p>
                                              {user?.companyWebsite ? <p>
                                                <span class="font-sm color-text-mutted mb-10">
                                                  Company website 
                                                </span>
                                                <br></br>
                                                <a
                                                  href={user?.companyWebsite}
                                                  target="_blank"
                                                >
                                                  {user?.companyWebsite}
                                                </a>
                                              </p> : ""}
                                              <p>
                                                <span class="font-sm color-text-mutted mb-10">
                                                  Your Industry *{" "}
                                                </span>
                                                <br></br>
                                                <span>{user?.CompanyInd}</span>
                                              </p>
                                              <p>
                                                <span class="font-sm color-text-mutted mb-10">
                                                  Number Of Employees *{" "}
                                                </span>
                                                <br></br>
                                                <span>{user?.numberOfemp}</span>
                                              </p>
                                              <p>
                                                <span class="font-sm color-text-mutted mb-10">
                                                  Company document *{" "}
                                                </span>
                                                <br></br>
                                                <span className="foeo">
                                                  {user?.CompanydocType}
                                                </span>{" "}
                                                <span
                                                  style={{
                                                    color: "#164890",
                                                    marginLeft: "20px",
                                                  }}
                                                >
                                                  <a
                                                    href={`https://univiindia.com/employer/${user?.Companydoc}`}
                                                    target="blank"
                                                  >
                                                    <FaEye className="dvd" />
                                                  </a>
                                                </span>
                                              </p>
                                              <p>
                                                <span class="font-sm color-text-mutted mb-10">
                                                  Your document *{" "}
                                                </span>
                                                <br></br>
                                                <span className="foeo">
                                                  {user?.EmployerdocType}
                                                </span>{" "}
                                                <span
                                                  style={{
                                                    color: "#164890",
                                                    marginLeft: "20px",
                                                  }}
                                                >
                                                  <a
                                                    href={`https://univiindia.com/employer/${user?.Employerdoc}`}
                                                    target="blank"
                                                  >
                                                    <FaEye className="dvd" />
                                                  </a>
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <Button
                                    className="pt-2 pb-2"
                                    type="primary"
                                    onClick={showModal}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <Modal
                      // title="Basic Modal"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <h6>Select reason Why deleting Account?</h6>
                      <div className="checkbox" onChange={onChangeValue}>
                        <input
                          type="radio"
                          value="I got candidate"
                          name="reasion1"
                          checked={reasion1 === "I got candidate"}
                        />
                        <label className="px-2">I got candidate</label>
                        <br />
                        <input
                          type="radio"
                          value="No vacancy"
                          name="reasion1"
                          checked={reasion1 === "No vacancy"}
                        />
                        <label className="px-2">No vacancy</label>
                        <br />
                        <input
                          type="radio"
                          value="Not found candidates"
                          name="reasion1"
                          checked={reasion1 === "Not found candidates"}
                        />
                        <label className="px-2">Not found candidates</label>
                        <br />
                        <input
                          type="radio"
                          value="Other reason"
                          name="reasion1"
                          checked={reasion1 === "Other reason"}
                        />
                        <label className="px-2">Other reason</label>
                        <br />
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* lpvcldp]s */}
      {/* <Button type="primary" onClick={showModal1}>
        Open Modal
      </Button> */}
      {/* our company */}
      <Modal
       
        open={isModalOpen1}
        onOk={addCompanyDeatails}
        onCancel={handleCancel1}
        >
          <div style={{fontSize:"20px",fontWeight:900}}>Job Details</div>
        <div class="form-group">
          <label class="color-text-mutted mb-10" style={{fontSize:"18px"}}>Company Name *</label>
          <input
            class="form-control"
            type="text"
            name="name"
            placeholder={
              user?.hiring === "My own Company"
                ? user.CompanyName
                : "Please enter company name"
            }
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
          />
        </div>
        <div class="form-group">
          <label class=" color-text-mutted mb-10" style={{fontSize:"18px"}}>Your Industry *</label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setCompanyIndustry(e.target.value)}
          >
            {user?.hiring === "My own Company" ? (
              <option>{user?.CompanyInd}</option>
            ) : (
              <option>Select your industry</option>
            )}

            {alldata?.map((items) => {
              return <option value={items?.Industry}>{items?.Industry}</option>;
            })}
          </Form.Select>
        </div>
        <div class="form-group">
          <label class=" color-text-mutted mb-10" style={{fontSize:"18px"}}>
            Company website 
          </label>
          <input
            class="form-control"
            type="text"
            name="name"
            placeholder={
              user?.hiring === "My own Company"
                ? user.companyWebsite
                : "Enter your website"
            }
            // placeholder={"Enter your website"}
            value={companyWebsite}
            onChange={(e) => {
              setCompanyWebsite(e.target.value);
            }}
          />
        </div>

        <div class="form-group">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setnumberofEmp(e.target.value)}
          >
            {user?.hiring === "My own Company" ? (
              <option>{user?.numberOfemp}</option>
            ) : (
              <option>Number of employees working </option>
            )}
            <option value="0 to 10">0 to 10</option>
            <option value="10 to 50">10 to 50</option>
            <option value="50 to 100">50 to 100</option>
            <option value="100 to 500">100 to 500</option>
            <option value="Above 500">Above 500</option>
            <option value="Above 1000">Above 1000</option>
            <option value="Not sure">Not sure</option>
          </Form.Select>
        </div>
        <label class=" color-text-mutted mb-10" style={{fontSize:"18px"}}>Attach your Id *</label>
        <div className="row">
          <div className="col-md-6">
            <div class="form-group">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setcyourId(e.target.value)}
              >
                {user?.hiring === "My own Company" ? (
                  <option>{user?.EmployerdocType}</option>
                ) : (
                  <option>Type of Id</option>
                )}
                <option value="Employee Id">Employee Id</option>
                <option value="Aadhar Card">Aadhar Card</option>
                <option value="Pan card">Pan card</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group" htmlFor="upload2">
              <input
                class="form-control"
                type="file"
                accept="image/*"
                name="file"
                id="upload2"
                placeholder={"Choose your Id"}
                onChange={(e) => {
                  setcyourdoc(e.target.files[0]);
                }}
              />
            </div>
          </div>
        </div>

        <label class=" color-text-mutted mb-10" style={{fontSize:"18px"}}>
          Attach your company document *
        </label>
        <div className="row">
          <div className="col-md-6">
            <div class="form-group">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setcomDocT(e.target.value)}
              >
                {user?.hiring === "My own Company" ? (
                  <option>{user?.CompanydocType}</option>
                ) : (
                  <option>Type of document</option>
                )}
                <option value="Company RC">Company RC</option>
                <option value="Company Pan">Company Pan</option>
                <option value="GST Certificate">GST Certificate</option>
                <option value="Other documen">Other document</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group" htmlFor="upload3">
              <input
                class="form-control"
                type="file"
                accept="image/*"
                name="file"
                id="upload3"
                placeholder={"Choose your Id"}
                onChange={(e) => {
                  setCompDoc(e.target.files[0]);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      {/* our client */}
      <Modal
       
        open={isModalOpen2}
        onOk={addCompanyDeatails}
        onCancel={handleCancel2}
      >
          <div style={{fontSize:"20px",fontWeight:900}}>Consultancy Details</div>
        <div class="form-group">
          <label class=" color-text-mutted mb-10" style={{fontSize:"18px"}}>
            Consultancy Name *
          </label>
          <input
            class="form-control"
            type="text"
            name="name"
            placeholder={
              user?.hiring === "My Clients"
                ? user.CompanyName
                : "Enter consultancy name"
            }
            value={Consultname}
            onChange={(e) => {
              setConsultname(e.target.value);
            }}
          />
        </div>
        <div class="form-group">
          <label class=" color-text-mutted mb-10" style={{fontSize:"18px"}}>
            Clients you normally hire *
          </label>
          <input
            class="form-control"
            type="text"
            name="name"
            // placeholder={"Eg: Swiggy , Dominos , Amazon"}
            placeholder={
              user?.hiring === "My Clients"
                ? user.CompanyInd
                : "Eg: Swiggy , Dominos , Amazon"
            }
            value={clientNarmal}
            onChange={(e) => {
              setclientNarmal(e.target.value);
            }}
          />
        </div>
        <div class="form-group">
          <label class=" color-text-mutted mb-10" style={{fontSize:"18px"}}>
            Client website 
          </label>
          <input
            class="form-control"
            type="text"
            name="name"
            placeholder={
              user?.hiring === "My Clients"
                ? user.companyWebsite
                : "Enter your website"
            }
            // placeholder={"Enter your website"}
            value={website}
            onChange={(e) => {
              setwebsite(e.target.value);
            }}
          />
        </div>
        <div class="form-group">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setclientNumber(e.target.value)}
          >
            {user?.hiring === "My Clients" ? (
              <option>{user?.numberOfemp}</option>
            ) : (
              <option>Number of employees working </option>
            )}
            <option value="0 to 10">0 to 10</option>
            <option value="10 to 50">10 to 50</option>
            <option value="50 to 100">50 to 100</option>
            <option value="100 to 500">100 to 500</option>
            <option value="Above 500">Above 500</option>
            <option value="Above 1000">Above 1000</option>
            <option value="Not sure">Not sure</option>
          </Form.Select>
        </div>
        <label class=" color-text-mutted mb-10" style={{fontSize:"18px"}}>Attach your Id *</label>
        <div className="row">
          <div className="col-md-6">
            <div class="form-group">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setclientId(e.target.value)}
              >
                {user?.hiring === "My Clients" ? (
                  <option>{user?.EmployerdocType}</option>
                ) : (
                  <option>Type of Id</option>
                )}
                <option value="Employee Id">Employee Id</option>
                <option value="Aadhar Card">Aadhar Card</option>
                <option value="Pan card">Pan card</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group" htmlFor="upload4">
              <input
                class="form-control"
                type="file"
                accept="image/*"
                name="file"
                id="upload4"
                placeholder={"Choose your Id"}
                onChange={(e) => {
                  setclientDoc(e.target.files[0]);
                }}
              />
            </div>
          </div>
        </div>

        <label class=" color-text-mutted mb-10" style={{fontSize:"18px"}}>
          Attach your consultancy document *
        </label>
        <div className="row">
          <div className="col-md-6">
            <div class="form-group">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setclintCompT(e.target.value)}
              >
                {user?.hiring === "My Clients" ? (
                  <option>{user?.CompanydocType}</option>
                ) : (
                  <option>Type of document</option>
                )}
                <option value="Consultancy RC">Consultancy RC</option>
                <option value="Consultancy Pan">Consultancy Pan</option>
                <option value="GST Certificate">GST Certificate</option>
                <option value="Other document">Other document</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group" htmlFor="upload5">
              <input
                class="form-control"
                type="file"
                accept="image/*"
                name="file"
                id="upload5"
                placeholder={"Choose your Id"}
                onChange={(e) => {
                  setclientComp(e.target.files[0]);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EmployerProfile;
