import React, { useState } from "react";
import { HiUsers } from "react-icons/hi";
import { BsBook } from "react-icons/bs";
import { AiOutlineFilePdf } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { style } from "@mui/system";
import {  BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
function EmployersLogin() {
  let history = useNavigate();

const [email,setEmail]=useState("");
const [password,setpassword]=useState("")
const [eye,seteye]=useState("true")

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/loginEmployer",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
         data:{email:email,
          password:password},
      };
      let res = await axios(config);
      if (res.status === 200) {
        sessionStorage.setItem("employer", JSON.stringify(res.data.success));
        history("/Employer-home");
        // alert("successfully Login");
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.success);
      }
    }
  };
   const forgetPassword = async () => {

    try {
      const config = {
        url: "/emaployerForgetPWD",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: {email:email},
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("successfully send new password in your mail");
        window.location.reload(true);
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  return (
    <>
      <div className="container pr">
      <div className="ad-lo" >
      <div className="add-login">
          <div className="login-img">
             <figcaption>
              <img src="/login.png" alt="" style={{width:'100%',height:"385px"}}/>
             </figcaption>
          </div>
        {/* Login Start */}
        <div className="register login-signup-box">
          <div className="d-flex justify-content-center">
              <div className="img-title">
               <img src="/un .jpg" alt="" style={{width:"100%",height:"61px"}} />
              </div>
            </div>
            <h1 class="modal-title">Hello Employer</h1>
          <form onSubmit={(e) => onSubmit(e)}>
            <fieldset>
              <div class="form-group ">
                <input
                  type="text"
                  name="email"
                  class="form-control"
                  placeholder="Email/Mobile number"
                  maxlength="50"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  id="email"
                />
                <span class="error-txt"></span>
              </div>
              {eye ?     <div class="form-group " style={{display:'flex',flexDirection:'row'}}>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  class="form-control"
                  maxlength="16"
                  id="password"
                  onChange={(e) => setpassword(e.target.value)}
                  value={password}
                />
                 <BsFillEyeSlashFill onClick={()=>seteye(false)} fontSize={30} style={{marginTop:10}}/>
                <span class="error-txt"></span>
              </div>
              : <div class="form-group " style={{display:'flex',flexDirection:'row'}}>
              <input
                type="text"
                name="password"
                placeholder="Password"
                class="form-control"
                maxlength="16"
                id="password"
                value={password}
                onChange={(e) => setpassword(e.target.value)}
              
              />
                <BsEyeFill onClick={()=>seteye(true)} fontSize={30} style={{marginTop:10}}/>
              <span class="error-txt"></span>
            </div>}
            </fieldset>
            <div className="form-check">
              {/* <label class="form-check-label" for="l_box1">
                <input
                  type="checkbox"
                  name="remember_me"
                  value="locationbox"
                  id="l_box1"
                  class="form-check-input"
                />
                Remember me:
              </label> */}
              <button type="submit" class="btn btn-primary submit">
                Login
              </button>
             <small class="form-text text-muted text-center" style={{display:"flex",justifyContent:"space-between",color:"blue"}}>
                <p  className="p-hero"  onClick={()=>history("/employers-registers")} style={{cursor:"pointer",}}>Register</p>{" "}
                <p onClick={()=>forgetPassword()} style={{cursor:"pointer",color:"#0d6efd"}}>Forgot Password</p>
              </small>
            </div>
          </form>
        </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default EmployersLogin;
