import * as React from "react";
import TextField from "@mui/material/TextField";
import { TiShoppingBag } from "react-icons/ti";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// search text
import parse from "html-react-parser";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DirectionsIcon from "@mui/icons-material/Directions";
import Management from "./Management";
import { useState, useEffect } from "react";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineBriefcase } from "react-icons/hi";
import { BsStopwatch } from "react-icons/bs";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function MakeCareerChange() {
  const values = [true];
  // const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  function handleShow() {
    if (!exYear && !dipartment && !JobRole && skill) {
      alert("Please fill the fields");
    } else {

      setShow(true);
    }
  }

  const [JobRole, setJobRole] = useState("");
  const [exYear, setexYear] = useState("");
  const [dipartment, setdipartment] = useState("");
  const [skill, setskill] = useState("");
  const [job, setjob] = useState([]);
  const [user, setUser] = useState([]);

  console.log("Swagat to karo hamara", job);
  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const config = {
      url: "/user/getAllJobs",
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);

      if (result.status === 200) {
        setUser(result.data.success);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const unique = [...new Set(user.map((item) => item.category))];

  const searchJob = async () => {
    const config = {
      url: "/user/getJobByfilter",
      method: "post",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
      data: {
        experience: exYear,
        skill: skill,
        jobProfile: JobRole,
        category: dipartment,
      },
    };
    try {
      const result = await axios(config);

      if (result.status === 200) {
        setjob(result.data.success);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  };
  let history = useNavigate();
  let check = JSON.parse(sessionStorage.getItem("user"));

  const apply1 = (id) => {
    if (!check) {
      alert("Please login.");
      history("/employee-login");
    } else {
      window.location.assign(`/job-profile-details/${id}`);
    }
  };
  const applyJob = async (id) => {
    try {
      if (!check) {
        alert("Please login.");
        history("/employee-login");
      } else if (check.resume === "") {
        alert("Please upload your resume") } else if (
        check?.skillSet.length === 0 || check?.education.length === 0 
      ) {
        alert("Please update your profile to apply");
      } else {
        const config = {
          url: "/user/applyForJob",
          method: "post",
          baseURL: "https://univiindia.com/api",
          headers: { "content-type": "application/json" },
          data: { companyId: id, userId: check._id },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert(result.data.success);
        } else {
          alert("Something went worng");
        }
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  return (
    <>
      <div className="career">
        <div className="ui-bg">
          <div
            class="container ui-header-content mt-30"
            style={{ paddingTop: "20px" }}
          >
            <figure class="mr-20">
              <TiShoppingBag style={{ fontSize: "21px" }} />
            </figure>
            <h1
              class="heading1"
              style={{ fontSize: "35px", paddingLeft: "10px" }}
            >
              Make a career change
            </h1>
          </div>
        </div>
        <div className="container">
          <div class="ui-main col">
            <h2 class="heading3 mt-30">What do you have in mind</h2>
            <p></p>

            <div className="row">
              <div className="col-md-8">
                <div class="list mt-40">
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Preferred Role"
                    Preferred
                    Role
                    value={JobRole}
                    onChange={(e) => setJobRole(e.target.value)}
                    maxRows={4}
                    style={{ width: "70%", marginBottom: "10px" }}
                  />
                </div>

                <div class="form-group">
                  <div class="custom-select-box">
                    <select
                      name="experience"
                      class="custom-select"
                      onChange={(e) => setexYear(e.target.value)}
                      style={{
                        width: "70%",
                        padding: "15px",
                        border: "1px solid #D3D3D3",
                        background: "transparent",
                        borderRadius: "5px",
                      }}
                    >
                      <option value="">Total Experience</option>
                      <option value="0-1 year">0-1 year</option>
                      <option value="1-3 year">1-3 year</option>
                      <option value="3-7 year">3-7 year</option>
                      <option value="7 year Above">7 year Above</option>

                    </select>
                  </div>
                </div>
                {/* department */}
                <div class="form-group">
                  <div class="custom-select-box">
                    <select
                      name="department"
                      class="custom-select"
                      aria-label="Department"
                      onChange={(e) => setdipartment(e.target.value)}
                      style={{
                        width: "70%",
                        padding: "15px",
                        border: "1px solid #D3D3D3",
                        background: "transparent",
                        borderRadius: "5px",
                      }}
                    >
                      <option value="">Category</option>
                      {unique?.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                </div>
                {/* Skill */}
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 480,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Enter Skills"
                    value={skill}
                    onChange={(e) => setskill(e.target.value)}
                    inputProps={{ "aria-label": "Enter Skills" }}
                  />
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                    <DirectionsIcon />
                  </IconButton>
                </Paper>
                {/* btn */}
                <div class="mt-40">
                  <div class="wow animate__animated animate__fadeInUp">
                    {values.map((v, idx) => (
                      <Button
                        key={idx}
                        className="me-2 mb-2"
                        onClick={() => {
                          handleShow(v);
                          searchJob();
                        }}
                      >
                        View Jobs
                        {typeof v === "string" && `below ${v.split("-")[0]}`}
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
              {/* */}
              <div className="col-md-4">
                <figure class="find-job-bg">
                  <img
                    src="https://static1.shine.com/l/s/react/media/images/desktop/find-right-job.png"
                    class="img-fluid"
                    alt="Let’s get you to the right job"
                  />
                </figure>
              </div>
              {/* cards */}
              <div class="dropdown mt-40">
                <>
                  <Modal
                    show={show}
                    size="xl"
                    onHide={() => setShow(false)}
                    style={{ backgroundColor: "white" }}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title style={{ fontWeight: "bold" }}>
                        Jobs
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="mangament">
                        <div className="row">
                          {job.length > 0 ?
                            job?.filter(
                              (data) =>
                                exYear == data.experience &&
                                dipartment == data.category &&
                                 data.skill.toLocaleLowerCase().includes(skill.toLocaleLowerCase()) &&
                                data.jobProfile.toLocaleLowerCase().includes(JobRole.toLocaleLowerCase())
                            )
                            .map((items) => {
                              return (
                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 ">
                                  <div class="card-grid-2 hover-up">
                                    <div class="card-grid-2-image-left">
                                     

                                      <div class="right-info">
                                        <a
                                          class="name-job"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => apply1(items?._id)}
                                        >
                                          {items?.companyName.slice(0, 17)}
                                        </a>
                                        <span class="location-small">
                                          {" "}
                                          <span style={{ paddingRight: "4px" }}>
                                            <CiLocationOn />
                                          </span>
                                          {items?.location}
                                        </span>
                                      </div>
                                    </div>
                                    <div class="card-block-info">
                                      <h6>
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() => apply1(items?._id)}
                                        >
                                          {items?.jobProfile.slice(0, 18)}
                                        </a>
                                      </h6>
                                      <div class="mt-3">
                                        <span class="card-briefcase">
                                          <span style={{ paddingRight: "4px" }}>
                                            <HiOutlineBriefcase />
                                          </span>
                                          {items?.typeofjob}
                                        </span>
                                        <span class="card-time">
                                          {" "}
                                          <span style={{ paddingRight: "4px" }}>
                                            <BsStopwatch />
                                          </span>
                                          {moment(items?.updatedAt).fromNow()}
                                          {/* <span> minutes ago</span> */}
                                        </span>
                                        <p>
                                          <HiOutlineBriefcase />{" "}
                                          {items.experiencerequired ===
                                              "Fresher" ||
                                            items.experiencerequired ===
                                              "Any" ? (
                                              <span>
                                                {items.experiencerequired}
                                              </span>
                                            ) : (
                                              <span>
                                                {items?.experience
                                                  ? items?.experience
                                                  : items.experiencerequired}
                                              </span>
                                            )}
                                        </p>
                                      </div>
                                      {/* <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() => apply1(items?._id)}
                                      >
                                        <p class="font-sm color-text-paragraph mt-3 mb-3">
                                          {parse(items.description?.slice(0, 20))}...
                                        </p>
                                      </a> */}
                                      {/* <div class="mt-30 mb-3">
                                        
                                        <a
                                          class="btn btn-grey-small mr-5"
                                          onClick={() => apply1(items?._id)}
                                        >
                                          {items?.skill.slice(0, 40)}
                                        </a>
                                      </div> */}
                                      <div class="card-2-bottom mt-30">
                                        <div class="row">
                                          <div class="col-lg-7 col-7">
                                            <span class="card-text-price">
                                              {"₹ "}
                                              {items?.minSalary}-
                                              {items?.maxSalary}
                                            
                                            </span>
                                            <span class="text-muted">
                                              /{items?.period}
                                            </span>
                                          </div>
                                          <div class="col-lg-5 col-5 text-end">
                                            <div
                                              class="btn btn-apply-now"
                                              data-bs-toggle="modal"
                                              data-bs-target="#ModalApplyJobForm"
                                              onClick={() => {
                                                applyJob(items?._id);
                                              }}
                                            >
                                              Apply now
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }): <div style={{display : "flex",justifyContent:"center",alignItems:"center",fontSize:"30px",color:"red"}}>No Jobs</div>}
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </>
              </div>
              {/* cards */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MakeCareerChange;
