import React from "react";
import "./Mp.css";
// back-e
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

function Wcs1() {
  const [unVerify, setUnVerify] = useState([]);

  const getVerifiedList = async () => {
    let res = await axios.get("https://univiindia.com/api/user/getAllJobs");
    if (res.status === 200) {
      //console.log(res.data);
      setUnVerify(res.data.success);
    }
  };
  const [UnvarifiedList, setUnvarifiedList] = useState([]);

  const getJobList = async () => {
    let res = await axios.get(
      "https://univiindia.com/api/user/getUnvarifiedList"
    );
    if (res.status === 200) {
      //console.log(res.data);
      setUnvarifiedList(res.data.success);
    }
  };

  const [AppliedDetails, setAppliedDetails] = useState([]);

  const getAppliedDetails = async (id) => {
    let res = await axios.get(
      "https://univiindia.com/api/user/AllAplliedDetals"
    );
    if (res.status === 200) {
      //console.log(res.data);
      setAppliedDetails(res.data.success);
    }
  };
  const [allUser, setAllUser] = useState([]);
  const userData = async () => {
    try {
      let res = await axios.get("https://univiindia.com/api/user/getAllProfile");
      if (res.status === 200) {
        //console.log(res.data);
        setAllUser(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const [allEmp, setAllEmp] = useState([]);
  const empData = async () => {
    try {
      let res = await axios.get(
        "https://univiindia.com/api/user/getAllProfileEmployer"
      );
      if (res.status === 200) {
        setAllEmp(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  // getAllProfileEmployer
  useEffect(() => {
    getVerifiedList();
    getJobList();
    getAppliedDetails();
    userData();
    empData();
  }, []);
  let subadmin=JSON.parse(sessionStorage.getItem("subadmin"))
  return (
    <>
     
      <div className="container">
        <div className="row" style={{ marginTop: "-17px" }}>
        {subadmin?.employee==="true" ? (<div className="col-md-2">
            <Link to="/sub-adminuser">
              <MDBCard style={{ maxWidth: "540px", height:"70%" }}>
                <MDBRow className="g-0">
                  <MDBCol md="4">
                    <MDBCardImage src="user.png" alt="..." fluid />
                  </MDBCol>
                  <MDBCol md="8">
                 
                 <MDBCardTitle>Employees</MDBCardTitle>
                 <MDBCardText>
                   <h4 style={{ marginLeft: "7px" }}> {allUser?.length}</h4>
                 </MDBCardText>
               
               </MDBCol>
              
                </MDBRow>
              </MDBCard>
            </Link>
          </div>):(<></>)}
          
          {subadmin?.employer==="true" ? (  <div className="col-md-2">
            <Link to="/sub-adminemployee">
              <MDBCard style={{ maxWidth: "540px", height:"70%" }}>
                <MDBRow className="g-0">
                  <MDBCol md="4">
                    <MDBCardImage src="employer.png" alt="..." fluid />
                  </MDBCol>
                  <MDBCol md="8">
                    {" "}
                   
                    <MDBCardTitle>Employers</MDBCardTitle>
                    <MDBCardText>
                      <h4 > {allEmp?.length}</h4>
                    </MDBCardText>
                    {/* </MDBCardBody> */}
                  </MDBCol>

                  {/* <MDBCardText>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </MDBCardText> */}
                </MDBRow>
              </MDBCard>
            </Link>
          </div>):(<></>)}
        

                {subadmin?.PostedJob==="true" ? (  <div className="col-md-2">
            {" "}
            <Link to="/sub-adminunverified">
              <MDBCard style={{ maxWidth: "540px",height:"70%" }}>
                <MDBRow className="g-0">
                  <MDBCol md="4">
                    <MDBCardImage src="e1.png" alt="..." fluid />
                  </MDBCol>
                  <MDBCol md="8">
                    {" "}
                    {/* <MDBCardBody> */}
                    <MDBCardTitle>Posteded Jobs</MDBCardTitle>
                    <MDBCardText>
                      <h4>{UnvarifiedList?.length}</h4>
                    </MDBCardText>
                    {/* </MDBCardBody> */}
                  </MDBCol>

                  {/* <MDBCardText>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </MDBCardText> */}
                </MDBRow>
              </MDBCard>
            </Link>
          </div>):(<></>)}
          {subadmin?.verifiedJob==="true" ? (  <div className="col-md-2">
            <Link to="/sub-adminverified">
              <MDBCard style={{ maxWidth: "540px", height:"70%" }}>
                <MDBRow className="g-0">
                  <MDBCol md="4">
                    <MDBCardImage src="e3.png" alt="..." fluid />
                  </MDBCol>
                  <MDBCol md="8">
                    {/* <MDBCardBody> */}
                    <MDBCardTitle>Approved Jobs</MDBCardTitle>
                    <MDBCardText>
                      <h4> {unVerify?.length}</h4>
                    </MDBCardText>
                    {/* </MDBCardBody> */}
                  </MDBCol>

                  {/* <MDBCardText>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </MDBCardText> */}
                </MDBRow>
              </MDBCard>
            </Link>
          </div>):(<></>)}
        
                {subadmin?.appliedDetails==="true" ? (   <div className="col-md-2">
            <Link to="/sub-adminapplieddetails">
              <MDBCard style={{ maxWidth: "540px", height:"70%" }}>
                <MDBRow className="g-0">
                  <MDBCol md="4">
                    <MDBCardImage src="e4.png" alt="..." fluid />
                  </MDBCol>
                  <MDBCol md="8">
                    {" "}
                    {/* <MDBCardBody> */}
                    <MDBCardTitle>Applied Jobs</MDBCardTitle>
                    <MDBCardText>
                      <h4> {AppliedDetails?.length}</h4>
                    </MDBCardText>
                    {/* </MDBCardBody> */}
                  </MDBCol>

                  {/* <MDBCardText>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </MDBCardText> */}
                </MDBRow>
              </MDBCard>
            </Link>
          </div>):(<></>)}
       
        </div>
      </div>
      {/* card */}
      {/* </div> */}
    </>
  );
}

export default Wcs1;
