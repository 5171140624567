import React from "react";
import { GoLocation } from "react-icons/go";

import {AiOutlineUserAdd} from "react-icons/ai"
import {BsLinkedin, BsTelephoneX} from "react-icons/bs"
import {MdOutlineMailOutline} from "react-icons/md"
import {FaPinterestSquare} from "react-icons/fa"
import {FaInstagramSquare} from "react-icons/fa"
import {FaFacebookSquare} from "react-icons/fa"
import { Link } from "react-router-dom";


function Footer() {
  return (
    <>
      <div className="use_hero">
        <div className="container">
          <div className="hro+p">
            <div className="row">
              <div className="col-md-4">
                <div className="H_u_O">
                  <div className="los">
                    10 Lakhs+ <br />
                    User
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="H_u_O">
                  <div className="los">
                    5 Lakhs+ <br />
                    Clients
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="H_u_O">
                  <div className="los">
                    10 Lakhs+ <br />
                    Jobs
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="foot_heo">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5">
              <div className="footers-Hewo">
                {/* <div className="lo-footer0">
                  <img src="/un-f.png" alt="" style={{ height: "75px" }} />
                </div> */}
                <div className="ab-tes">
                  <h3>Univi India HR Team</h3>
                  <p>
                    In this competitive market Univi India HR Team is one
                    of the most aggressive management team in the market
                    & managed multinational team with expertise in markets
                    spanning the globe.{" "}
                  </p>
                  <div style={{display:"flex",flexDirection:'row'}}>
                  <div className="socila-fo">
                    <ul>
                      <li className="linkedin"><BsLinkedin style={{fontSize:"25px",color:"white"}}/></li>
                      <li className="facebook"><a href="https://www.facebook.com/Univiindia?mibextid=ZbWKwL"><FaFacebookSquare style={{fontSize:"25px",color:"white"}}/></a></li>
                      <li className="insta"><FaInstagramSquare style={{fontSize:"25px",color:"white"}}/></li>
                      <li className="pinterest"><FaPinterestSquare style={{fontSize:"25px",color:"white"}}/></li>
                     
                    </ul>
                     </div>
                    <div className="img_he">
              <a class="name-job" href="https://univiindia.com/">   <img
                  src="/Images/qrcode.png"
                  alt=""
                  style={{ width: "100px", height: "100px",marginLeft:"15px",marginTop:"-20px",marginBottom:"20px" }}
                /></a>
               
             
              </div>
             
             
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
            <div className="add_eh">
            <div className="cont-0">
                <h3>Contact Us</h3>
                <div className="contact_us">
                  <ul>
                    <li>
                      <span>
                        <AiOutlineUserAdd />
                      </span>
                      Nethra.Y
                    </li>
                    <li>
                      <span>
                        <BsTelephoneX />
                      </span>
                      9886040444
                    </li>
                    <li>
                      <span>
                        <MdOutlineMailOutline />
                      </span>
                      univi.india@gmail.com
                    </li>
                    {/* <li>
                     <Link to="/discliamer">
                      Disclaimer</Link>
                    </li>
                    <li>
                     <Link to="/terms&condition">
                      Terms & Conditions</Link>
                    </li>
                    <li>
                     <Link to="/privacypolicy">
                      Privacy & Policy</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="img_he" >
              {/* <p style={{color:'white',fontSize:'18px'}}>
                    Scan to download the application 
                  </p> */}
                   <a class="name-job" href="https://play.google.com/store/apps/details?id=com.univi">   <img
                  src="/Images/playstores.png"
                  alt=""
                  style={{ width: "170px", height: "100px",marginLeft:"15px",marginTop:"-20px",marginBottom:"20px" }}
                /></a>
                  <img
                  src="/Images/univi app.png"
                  alt=""
                  style={{ width: "100px", height: "100px",marginLeft:"15px",marginTop:"-20px",marginBottom:"20px" }}
                />
             
               
             
              </div>
            </div>
            </div>
            <div className="col-md-4">
              <div className="add_eh">
              <h3>Location : </h3>
                <div className="address_hero-1">
                  <span>
                    <GoLocation />
                  </span>{" "}
                  Add - # 42/118, Venkatappa complex,
                  <br />
                  Bandepalya Main Road, GB Palya, Bommanahalli,
                  <br />
                  Bangalore - 560068
                </div>
                <div className="address_hero-1 pt-2">
                  <span>
                    <GoLocation />
                  </span>
                  Service location - Hyderabad, Andhra Pradesh, Chennai, Coimbatore
                
                </div>
              </div>
               
            </div>
            
          </div>
          <div className="">
        <div class="footer-bottom mt-1">
          <div class="row">
            <div class="col-md-4">
              <span class="font-xs color-text-paragraph">
                Copyright © 2023-24. All right reserved
              </span>
            </div>
            <div class="col-md-4">
              <span class="font-xs color-text-paragraph">
                Designed & Developed by{" "}
                <span>
                  <a href="https://parnetsgroup.com/" style={{ color: "blue" }}>
                    Par<sapn>Nets</sapn> Group
                  </a>
                </span>
              </span>
            </div>
            <div class="col-md-4 text-md-end text-start">
              <div class="footer-social">
                <a class="font-xs color-text-paragraph" href="/privacypolicy">
                  Privacy Policy
                </a>
                <a class="font-xs color-text-paragraph mr-30 ml-30" href="/terms&condition">
                  Terms &amp; Conditions
                </a>
                <a class="font-xs color-text-paragraph" href="/discliamer">
                  Disclaimer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>
    
    </>
  );
}

export default Footer;
