import React from 'react'
import Form from "react-bootstrap/Form";

import "./MajorProduct.scoped.scss";


// back-e
import axios from "axios";
import { useState ,useEffect} from "react";
import { useNavigate } from "react-router-dom";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Modal, Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { Button } from 'antd';
import ReactPaginate from 'react-paginate';


function Industries() {
  const [Industry, setIndustry] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const [show1, setShow1] = useState(false);
  const [Delete, setDelete] = useState();


  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    setDelete(data);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [alldata, setAlldata] = useState([]);

  const getAllindustry = async () => {
    try {
      let res = await axios.get("https://univiindia.com/api/admin/getAllIndustry");
      if (res.status === 200) {
        setAlldata(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    getAllindustry();
  }, []);
  // //console.log("swagat to karo hmara",alldata)
  const addIndustry = async (e) => {
    e.preventDefault();
    if (Industry) {
      try {
        const config = {
          url: "/addIndustry",
          method: "post",
          baseURL: "https://univiindia.com/api/admin",
          headers: { "content-type": "application/json" },
          data: { Industry: Industry },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert(res.data.success);
            handleCancel();
            setIndustry("");
    setIsModalOpen(false);

            getAllindustry();
          }
        });
      } catch (error) {
        //console.log(error);
        alert(error.response.data.error);
      }
    } else {
      alert("Please enter Industry!");
    }
  };
  const deleteIndustry = async () => {
    try {
      const config = {
        url: "/deleteIndustry/" + Delete._id,
        method: "delete",
        baseURL: "https://univiindia.com/api/admin",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setShow1(false);
          getAllindustry();
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };
  const handleOk = () => {
    addIndustry();
  };
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    alldata.length /
      usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
   <>
   <div className="townhub">
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            {/* <Wcs /> */}

            <div
              className="container img"
              style={{
                border: "1px solid rgba(36, 73, 106, 0.21)",
                borderRadius:" 10px",
                marginBottom: "10px",
                boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
                width: "100%",
              }}
            >
              <div class="container">
                <div class="loginform" style={{ textAlign: "center" }}>
                  <h1
                    className="heading-banner wow animate__animated animate__fadeInUp"
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                    }}
                  >
                    <b>Industries</b>
                  </h1>
                </div>
                <div className="">
                <p align="right">
           
           <Button
             variant="primary"
             type="button"
             value="ShortList"
             style={{
               backgroundColor: "rgb(5, 38, 78)",
               color: "white",
               border: "none",
             }}
           >
             <b style={{ color: "white"}} onClick={showModal}>Add Industries</b>
           </Button>
        </p>
                </div>
              </div>
            
              <br />
              <Table striped>
                <thead>
                  <tr>
                    <th>Industry ID</th>
                    <th>Industry Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                 
                {alldata?.slice(pagesVisited, pagesVisited + usersPerPage).map((item, index) => {
                    return (  <tr>
                      <td>{index+1}</td>
                      <td>{item.Industry}</td>
                      <td><AiOutlineDelete   style={{color:"red",cursor:"pointer"}}  onClick={() => {
                                            handleShow1(item);
                                          }}/></td>
                    </tr>)})}
                    
                 
                </tbody>
              </Table>
              <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {/* <Modal  open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
       <div className=''>
       <div className="form-group">
         <label className="mt-1">Add Industries</label><br/>
          <input type="text" placeholder='Enter Industries...'  className="form-control"  value={Industry}
                        onChange={(e) => setIndustry(e.target.value)}/>
         </div>
       </div>
      </Modal> */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton style={{ backgroundColor: "#165c49" }}>
          <Modal.Title style={{ color: "white", fontWeight: "900" }}>
            Delete Industry
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#165c49" }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label style={{ color: "white", fontSize: "20px" }}>
              Are you sure?
            </Form.Label>

            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#165c49" }}>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "red" }}
            onClick={deleteIndustry}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
        <Modal show={isModalOpen} onHide={handleCancel} >
                  <Modal.Header closeButton style={{backgroundColor:"#165c49"}}>
                    <Modal.Title style={{color:"white"}}>Job Industry</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{backgroundColor:"#165c49"}}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                     
                      <Form.Control
                        type="text"
                        placeholder="Enter Industry"
                        value={Industry}
                        onChange={(e) => setIndustry(e.target.value)}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer style={{backgroundColor:"#165c49"}}>
                    <Button variant="secondary" onClick={handleCancel}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={addIndustry}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
   </>
  )
}

export default Industries