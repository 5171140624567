import React from "react";
import Wcs from "./Wcs";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
// back-e
import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
import {
    FaBuilding,
    FaPhoneAlt,
    FaMailBulk,
    FaBookReader,
    FaCity,
    FaLocationArrow,
    FaMoneyBillAlt,
    FaMoneyCheckAlt,
    FaMoneyBillWaveAlt,
    FaGlobeAsia,
    FaSuitcase,
    FaLanguage,
    FaUserClock,
  } from "react-icons/fa";
  import {
    BsFillPersonCheckFill,
    BsFillPersonPlusFill,
    BsLink45Deg,
    BsPinMapFill,
    BsCalendarDate,
    BsFacebook,
    BsInstagram,
    BsTwitter,
    BsLinkedin,
    BsFillChatSquareTextFill,
    BsFillTelephoneInboundFill,
  } from "react-icons/bs";
  import { FcBusinessman } from "react-icons/fc";
  import { TbBrandJavascript } from "react-icons/tb";
  import { GiSkills, GiMatterStates, GiStreetLight, GiOpeningShell, GiTakeMyMoney, GiLevelTwoAdvanced } from "react-icons/gi";
  import { BiCategoryAlt } from "react-icons/bi";
import { MdNightlight } from "react-icons/md";
import { IoLocationSharp, IoLogoWhatsapp } from "react-icons/io5";
import { RiLuggageDepositLine } from "react-icons/ri";
import { GrUserExpert } from "react-icons/gr";

function Myjob() {
    const [item, setCompanyDetails] = useState({});
    const { id } = useParams();
    let history = useNavigate();

    const getVerifiedList = async () => {
      let res = await axios.get(
        "https://univiindia.com/api/user/getJobById/" + id
      );
      if (res.status === 200) {
        //console.log(res.data);
        setCompanyDetails(res.data.success);
      }
    };
  
    useEffect(() => {
      getVerifiedList();
    }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
   

          <div
            className="container img"
            style={{
              border: "1px solid #24496a",
              background: "#f1f4fb",
              marginTop: "30px",
              borderRadius: "10px",
              height: "500px",
              overflow: "scroll",
              width: "100%",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Job Details</b>
                </h1>
              </div>
              <div className="d-flex " style={{justifyContent:"space-between"}}>
              <p align="left">
        
                  <Button
                    type="button"
                    style={{
                      backgroundColor: "rgb(5, 38, 78)",
                      color: "white",
                      border: "none",
                    }}
                    onClick={() => history(-1)}
                  >
                    Back
                  </Button>
    
              </p>
              <p align="right">
                <Link to={`/companyeditemp/${id}`}>
                  <Button
                    type="button"
                    style={{
                      backgroundColor: "rgb(5, 38, 78)",
                      color: "white",
                      border: "none",
                    }}
                  >
                    +Edit
                  </Button>
                </Link>
              </p></div>
            </div>
           <div className="t">
           <Table striped bordered hover style={{ width: "100%",zIndex:"auto" }}>
                <thead>
                  <tr>
                    {/* <th>#</th> */}
                    <th>Logo</th>
                    <th>Name</th>
                    <th></th>
                    <th>Details</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    {/* <td>1</td> */}

                    <td>
                      <FaBuilding />
                    </td>
                    <td>Company Name</td>
                    <td>:</td>
                    <td>{item?.companyName}</td>
                  </tr>
                  <tr>
                    {/* <td>2</td> */}
                    <td>
                      <TbBrandJavascript />
                    </td>
                    <td>Job Title </td>
                    <td>:</td>
                    <td>{item?.jobProfile}</td>
                  </tr>
                  <tr>
                    {/* <td>3</td> */}
                    <td>
                      <FaSuitcase />
                    </td>
                    <td>Type Of Job</td>
                    <td>:</td>
                    <td>{item?.typeofjob}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiOpeningShell />
                    </td>
                    <td>Openings</td>
                    <td>:</td>
                    <td>{item?.openings}</td>
                  </tr>
                  <tr>
                    <td>
                      <MdNightlight />
                    </td>
                    <td>Shift Type</td>
                    <td>:</td>
                    <td>{item?.night }</td>
                  </tr>

                
                  <tr>
                    <td>
                      <GiSkills />
                    </td>
                    <td>Skill</td>
                    <td>:</td>
                    <td>{item?.skill}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaBookReader />
                    </td>
                    <td>Work Type</td>
                    <td>:</td>
                    <td>{item?.typeofwork}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaBookReader />
                    </td>
                    <td>Experience</td>
                    <td>:</td>
                    {item.experiencerequired === "Fresher" || item.experiencerequired === "Any" ? (
                      <td>{item.experiencerequired}</td>
                    ) : (
                      <td>
                        {item?.experience
                          ? item?.experience
                          : item.experiencerequired}
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>
                      <FaBookReader />
                    </td>
                    <td>Education</td>
                    <td>:</td>
                    <td>{item?.typeofeducation}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaBookReader />
                    </td>
                    <td>Qualification</td>
                    <td>:</td>
                    <td>{item?.typeofqualification}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaUserClock />
                    </td>
                    <td>Who can apply</td>
                    <td>:</td>
                    <td>{item?.gendertype} </td>
                  </tr>
                  <tr>
                  
                    <td>
                      <FaLocationArrow />
                    </td>
                    <td>Location</td>
                    <td>:</td>
                    <td>{item?.location}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiTakeMyMoney />
                    </td>
                    <td>Salary Type</td>
                    <td>:</td>
                    <td>{item?.salarytype}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiTakeMyMoney />
                    </td>
                    <td>Min Salary-Max Salary</td>
                    <td>:</td>
                   <td> {item.minSalary}-{item.maxSalary}{item.minSalary ? "/" :""}{ item.period}</td>
                  </tr>
                  <tr>
                    <td>
                      <GiTakeMyMoney />
                    </td>
                    <td>Incentive</td>
                    <td>:</td>
                    <td>{item?.averageIncentive ? item?.averageIncentive : 0}</td>
                  </tr>
                  <tr>
                    <td><GiLevelTwoAdvanced/></td>
                    <td>Benefit</td>
                    <td>:</td>
                    <td>{item?.benefits}</td>
                  </tr>
                  
                  <tr>
                    <td>
                      <RiLuggageDepositLine />
                    </td>
                    <td>Deposit</td>
                    <td>:</td>
                    <td>{item?.fee ? item?.fee : "No Deposit" }{item?.fee ?<div style={{color:"#05264e",fontSize:"16px",fontWeight:'900'}}>Reason</div>:""}:{item.reason}</td>
                  </tr>
                  {/* <tr>
                    <td>
                      <GrUserExpert />
                    </td>
                    <td>Experience Required</td>
                    <td>:</td>
                    <td>
                     {item.experiencerequired}
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      <FaLanguage />
                    </td>
                    <td>English Level Preference</td>
                    <td>:</td>
                    <td>{item?.english}</td>
                  </tr>
                  
                  <tr>
                    <td>
                      <FaUserClock />
                    </td>
                    <td>Interviewer Name</td>
                    <td>:</td>
                    <td>{item?.interviewername} </td>
                  </tr>
                  <tr>
                    <td>
                      <IoLogoWhatsapp />
                    </td>
                    <td>Whatsapp</td>
                    <td>:</td>
                    <td>{item?.whatsapp}</td>
                  </tr>
                  <tr>
                    <td>
                      <FaMailBulk />
                    </td>
                    <td>Email</td>
                    <td>:</td>
                    <td>{item?.email}</td>
                  </tr>
                  <tr>
                    <td>
                      <BsFillTelephoneInboundFill />
                    </td>
                    <td>Call Us</td>
                    <td>:</td>
                    <td>{item.time}</td>
                  </tr>
                
                  <tr>
                    <td>
                      <FaLocationArrow />
                    </td>
                    <td>Address</td>
                    <td>:</td>
                    <td>{item.address ? item.address : "Telephonic Interview"}</td>
                  </tr>
                  <tr>
                    <td>
                      <BsFillChatSquareTextFill />
                    </td>
                    <td>Description</td>
                    <td>:</td>
                    <td>{item?.description ? parse(item?.description) : "" }</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Myjob;
