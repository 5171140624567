import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Form } from "react-router-dom";

import { CiLocationOn } from "react-icons/ci";
import { HiOutlineBriefcase } from "react-icons/hi";
import { BsStopwatch } from "react-icons/bs";
import { BiRefresh } from "react-icons/bi";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";
function Findjob() {
  const { jobs } = useParams();
 
  const [user, setUser] = useState([]);
  const [searchView, setSearchView] = useState(false);
  const [search, setsearch] = useState([]);
  // const [check, setcheck] = useState([]);
  const [category1, setCategory1] = useState("");
  let [jobProfile, setjobProfile] = useState("");
  let [value, setValue] = useState("hghjvgh");
  const [showcat, setShowcat] = useState(true);

  const location = useLocation();

  let onInputChange = (e) => {
    setSearchView(true);
    setjobProfile(e.target.value);
  };
  let obj;
  const inpuData = (e) => {
    e.preventDefault();
    setCategory1("");
  };

  useEffect(() => {
    setCategory1(location?.state?.category);
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const config = {
      url: "/user/getAllJobs",
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);

      if (result.status === 200) {
        setUser(result.data.success);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      
    }
  };
  const [SelectedCategory, setSelectedCategory] = useState();

  const history = useNavigate();
  let check = JSON.parse(sessionStorage.getItem("user"));
  const apply1 = (id) => {
    if (!check) {
      alert("Please login.");
      history("/employee-login");
    } else {
      window.location.assign(`/job-profile-details/${id}`);
    }
  };
  
  const applyJob = async (id) => {
    try {
      if (!check) {
        alert("Please login.");
        history("/employee-login");
      } else if (!check?.resume) {
        alert("Please upload your resume");
      } else if (check.industry === "") {
        alert("Please update your profile to apply");
      } else if (
        check?.skillSet.length === 0 ||
        check?.education.length === 0
      ) {
        alert("Please update your profile to apply");
      } else {
        const config = {
          url: "/user/applyForJob",
          method: "post",
          baseURL: "https://univiindia.com/api",
          headers: { "content-type": "application/json" },
          data: { companyId: id, userId: check._id },
        };
        const result = await axios(config);
        if (result.status === 200) {
          alert(result.data.success);
        } else {
          alert("Something went worng");
        }
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };

  //position filter data
  const [internship, setinternship] = useState(false);
  const [fresher, setfresher] = useState(false);
  const [junior, setjunior] = useState(false);
  const [senior, setsenior] = useState(false);
  const [senior1, setsenior1] = useState(false);

  const [searchinput, setsearchinput] = useState(false);

  let fillterData = [];
  if (internship) {
    
    const internshipdata = user.filter(
      (data) => data.typeofeducation === "10th or Below 10th"
    );

    for (let i = 0; i < internshipdata.length; i++) {
      fillterData.push(internshipdata[i]);
     
    }
   
  }
  if (fresher) {
    const internshipdata = user.filter(
      (data) => data.typeofeducation === "12th pass"
    );
    for (let i = 0; i < internshipdata.length; i++) {
      fillterData.push(internshipdata[i]);
    }
  }
  if (junior) {
    const internshipdata = user.filter(
      (data) => data.typeofeducation === "Degree/Diploma"
    );
    for (let i = 0; i < internshipdata.length; i++) {
      fillterData.push(internshipdata[i]);
    }
  }
  if (senior) {
    const internshipdata = user.filter(
      (data) => data.typeofeducation === "Graduate"
    );
    for (let i = 0; i < internshipdata.length; i++) {
      fillterData.push(internshipdata[i]);
    }
  }
  if (senior1) {
    const internshipdata = user.filter(
      (data) => data.typeofeducation === "Post Graduate"
    );
    for (let i = 0; i < internshipdata.length; i++) {
      fillterData.push(internshipdata[i]);
    }
  }
  // Experience Level
  const [exp1, setexp1] = useState(false);
  const [exp2, setexp2] = useState(false);
  const [exp3, setexp3] = useState(false);
  const [exp4, setexp4] = useState(false);

  if (exp1) {
    const experience = user.filter(
      (data) =>
        data.experience === "0-1 year" &&
        data.experiencerequired !== "Fresher" &&
        data.experiencerequired !== "Any"
    );
    for (let i = 0; i < experience.length; i++) {
      fillterData.push(experience[i]);
    }
  }
  if (exp2) {
    const experience = user.filter(
      (data) =>
        data.experience === "1-3 year" &&
        data.experiencerequired !== "Fresher" &&
        data.experiencerequired !== "Any"
    );
    for (let i = 0; i < experience.length; i++) {
      fillterData.push(experience[i]);
    }
  }
  if (exp3) {
    const experience = user.filter(
      (data) =>
        data.experience === "3-7 year" &&
        data.experiencerequired !== "Fresher" &&
        data.experiencerequired !== "Any"
    );
    for (let i = 0; i < experience.length; i++) {
      fillterData.push(experience[i]);
    }
  }
  if (exp4) {
    const experience = user.filter(
      (data) =>
        data.experience === "7 year Above" &&
        data.experiencerequired !== "Fresher" &&
        data.experiencerequired !== "Any"
    );
    for (let i = 0; i < experience.length; i++) {
      fillterData.push(experience[i]);
    }
  }
  // Job type

  const [jobtype2, setjobtype2] = useState(false);
  const [jobtype3, setjobtype3] = useState(false);
  const [jobtype4, setjobtype4] = useState(false);

  if (jobtype2) {
    const jobtyper = user.filter((data) => data.typeofjob === "Part time");
    for (let i = 0; i < jobtyper.length; i++) {
      fillterData.push(jobtyper[i]);
    }
  }
  if (jobtype3) {
    const jobtyper = user.filter((data) => data.typeofjob === "Full time");
    for (let i = 0; i < jobtyper.length; i++) {
      fillterData.push(jobtyper[i]);
    }
  }
  if (jobtype4) {
    const jobtyper = user.filter((data) => data.typeofjob === "Both");
    for (let i = 0; i < jobtyper.length; i++) {
      fillterData.push(jobtyper[i]);
    }
  }
  //

  const unique = [...new Set(user?.map((item) => item?.category))];

  // price
  
  const [price1, setprice1] = useState(false);
  const [price2, setprice2] = useState(false);
  const [price3, setprice3] = useState(false);
  const [price4, setprice4] = useState(false);
  if (price1) {
    const pricetypes = user.filter(
      (data) => data.minSalary >= 10000 && data.maxSalary <= 20000
    );
    for (let i = 0; i < pricetypes.length; i++) {
      fillterData.push(pricetypes[i]);
    }
  }
  if (price2) {
    const pricetypes = user.filter(
      (data) => data.minSalary >= 20000 && data.maxSalary <= 40000
    );
    for (let i = 0; i < pricetypes.length; i++) {
      fillterData.push(pricetypes[i]);
    }
  }
  if (price3) {
    const pricetypes = user.filter(
      (data) => data.minSalary >= 40000 && data.maxSalary <= 70000
    );
    for (let i = 0; i < pricetypes.length; i++) {
      fillterData.push(pricetypes[i]);
    }
  }
  if (price4) {
    const pricetypes = user.filter((data) => data.minSalary >= 70000);
    for (let i = 0; i < pricetypes.length; i++) {
      fillterData.push(pricetypes[i]);
    }
  }
  const [work1, setwork1] = useState(false);
  const [work2, setwork2] = useState(false);
  const [work3, setwork3] = useState(false);
  const [work4, setwork4] = useState(false);
  if (work1) {
    const worktype = user.filter(
      (data) => data.typeofwork === "Work from home"
    );
    for (let i = 0; i < worktype.length; i++) {
      fillterData.push(worktype[i]);
    }
  }
  if (work2) {
    const worktype = user.filter(
      (data) => data.typeofwork === "Work from office"
    );
    for (let i = 0; i < worktype.length; i++) {
      fillterData.push(worktype[i]);
    }
  }
  if (work3) {
    const worktype = user.filter((data) => data.typeofwork === "Field jobs");
    for (let i = 0; i < worktype.length; i++) {
      fillterData.push(worktype[i]);
    }
  }
  if (work4) {
    const worktype = user.filter((data) => data.typeofwork === "Freelancer");
    for (let i = 0; i < worktype.length; i++) {
      fillterData.push(worktype[i]);
    }
  }
  const [expp1, setexpp1] = useState(false);
  const [expp2, setexpp2] = useState(false);
  const [expp3, setexpp3] = useState(false);

  if (expp1) {
    const exptype = user.filter(
      (data) => data.experiencerequired === "Fresher"
    );
    for (let i = 0; i < exptype.length; i++) {
      fillterData.push(exptype[i]);
    }
  }
  if (expp2) {
    const exptype = user.filter(
      (data) => data.experiencerequired === "Experience"
    );
    for (let i = 0; i < exptype.length; i++) {
      fillterData.push(exptype[i]);
    }
  }
  if (expp3) {
    const exptype = user.filter((data) => data.experiencerequired === "Any");
    for (let i = 0; i < exptype.length; i++) {
      fillterData.push(exptype[i]);
    }
  }

    const values = user.filter((data) => data.jobProfile === value);
    for (let i = 0; i < values.length; i++) {
      fillterData.push(values[i]);
    }
  
  
    const cate = user.filter((data) => data.category === jobs);
    for (let i = 0; i < cate.length; i++) {
      fillterData.push(cate[i]);
    }
    const uniqueplace = [];
    const unique1 = user.filter(element => {
      const isDuplicate = uniqueplace.includes(element.jobProfile);
    
      if (!isDuplicate) {
        uniqueplace.push(element.jobProfile);
    
        return true;
      }
    
      return false;
    });
  return (
    <>
      <div className="">
        <section className="section-box-2">
          <div className="container">
            <div className="banner-hero banner-single banner-single-bg">
              <div className="block-banner text-center">
                <h3 class="wow animate__animated animate__fadeInUp">
                  <span class="color-brand-2"> Jobs</span>{" "}
                  Available Now
                </h3>
                <div className="font-sm color-text-paragraph-2 mt-10 wow animate__animated animate__fadeInUp">
                  Find the most recent jods Available here,{" "}
                  <br className="d-none d-xl-block" />
                  "According to your desire job?"
                </div>
                <div className="form-find text-start mt-40 wow animate__animated animate__fadeInUp">
                  <form onSubmit={(e) => inpuData(e)}>
                    {/* <div className="box-industry">
                      <select
                        class="form-input mr-10  input-industry"
                        name="category"
                        value={SelectedCategory}
                        onChange={(e) => {
                          setSelectedCategory(e.target.value);
                          onInputChange(e);
                        }}
                      > {
                        category1 ? ( <option value={category1} >{category1}</option>):( <option value="">Select Category</option>)
                      }
                       
                        {unique.map((cat, ind) => {
                          return <option value={cat}>{cat}</option>;
                        })}
                      </select>
                    </div>

                    <div className="box-industry">
                      <select
                        class="form-input mr-10 select-active input-location"
                        name="city"
                        value={city}
                        onChange={(e) => {
                          onInputChange(e);
                        }}
                      >
                        <option value="0">Select City</option>

                        {user
                          .filter((item) => SelectedCategory === item?.category)
                          .map((data, ind) => {
                            return (
                              <option value={data?.city}>{data?.city}</option>
                            );
                          })}
                      </select>
                    </div> */}
                    <input
                      class="form-input input-keysearch mr-10"
                      type="text"
                      placeholder="Search by job title... "
                      value={jobProfile}
                      name="jobProfile"
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    ></input>
                    {searchView ? (
                      <div
                        className="BoxShadow1"
                        style={{
                          position: "absolute",
                          zIndex: 1000,
                          background: "white",
                          width: "98%",
                          marginTop: "35px",
                          padding: "2px",
                          borderBottom: "2px solid #2bb792",
                        }}
                      >
                        {unique1
                          .filter((item) => {
                            if (jobProfile == "") {
                              return null;
                            } else if (
                              item.jobProfile?.toLowerCase()
                                .includes(jobProfile?.toLowerCase())
                            ) {
                              return item;
                            }
                          })
                          .map((item) => (
                            <div className="searchItem">
                              {" "}
                              <Link
                                onClick={() => (
                                  setValue(item.jobProfile),
                                  setjobProfile(""),
                                  setSearchView(false),
                                  setShowcat(false)
                                 
                                )}
                              >
                                <h6 style={{ textAlign: "left" }}>
                                  {item.jobProfile}
                                </h6>
                              </Link>
                            </div>
                          ))}
                      </div>
                    ) : null}
                    {/* <button class="btn btn-default btn-find font-sm">
                      Search
                    </button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* filterpart */}
        <div className="section-box mt-30">
          <div className="container">
            <div className="row ">
              <div className="col-lg-3 col-md-12 col-sm-12 col-12 ">
                <div className="sidebar-shadow none-shadow mb-30">
                  <div
                    className="sidebar-filters"
                    style={{ paddingTop: "0px" }}
                  >
                    <div className="filter-block head-border mb-30">
                      <h5>
                        Advance Filter
                        <a
                          class="link-reset"
                          href="#"
                          onClick={() => {
                            window.location.reload(true);
                          }}
                        >
                          <BiRefresh />
                        </a>
                      </h5>
                    </div>

                    {/* Position  */}
                    <div class="filter-block mb-30">
                      <h5 class="medium-heading mb-15">Education</h5>
                      <div class="form-group">
                        <ul class="list-checkbox">
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) =>( setinternship(!internship) ,setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">10th or Below 10th</span>
                            </label>

                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) =>
                                    data.typeofeducation ===
                                    "10th or Below 10th"
                                ).length
                              }
                            </span>
                          </li>
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setfresher(!fresher),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">12th pass</span>
                            </label>

                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) => data.typeofeducation === "12th pass"
                                ).length
                              }
                            </span>
                          </li>
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setjunior(!junior),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">Degree/Diploma</span>
                            </label>

                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) => data.typeofeducation === "Degree/Diploma"
                                ).length
                              }
                            </span>
                          </li>
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => ( setsenior(!senior),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">Graduate</span>
                            </label>

                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) => data.typeofeducation === "Graduate"
                                ).length
                              }
                            </span>
                          </li>
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setsenior1(!senior),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">Post Graduate</span>
                            </label>

                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) =>
                                    data.typeofeducation === "Post Graduate"
                                ).length
                              }
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* Experience Level*/}
                    <div class="filter-block mb-30">
                      <h5 class="medium-heading mb-15">Experience Level</h5>
                      <div class="form-group">
                        <ul class="list-checkbox">
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setexp1(!exp1),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">0-1 Years</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) =>
                                    data.experience === "0-1 year" &&
                                    data.experiencerequired !== "Fresher" &&
                                    data.experiencerequired !== "Any"
                                ).length
                              }
                            </span>
                          </li>
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setexp2(!exp2),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">1-3 Years</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) =>
                                    data.experience === "1-3 year" &&
                                    data.experiencerequired !== "Fresher" &&
                                    data.experiencerequired !== "Any"
                                ).length
                              }
                            </span>
                          </li>

                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setexp3(!exp3),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">3-7 Years</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) =>
                                    data.experience === "3-7 year" &&
                                    data.experiencerequired !== "Fresher" &&
                                    data.experiencerequired !== "Any"
                                ).length
                              }
                            </span>
                          </li>

                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setexp4(!exp4),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">7 Years Above</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) =>
                                    data.experience === "7 year Above" &&
                                    data.experiencerequired !== "Fresher" &&
                                    data.experiencerequired !== "Any"
                                ).length
                              }
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* Job Posted */}
                    <div class="filter-block mb-30">
                      <h5 class="medium-heading mb-15">Job type</h5>
                      <div class="form-group">
                        <ul class="list-checkbox">
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  setjobtype2(true);
                                  setjobtype3(true);
                                  setjobtype4(true);
                                  
                                }}
                              />
                              <span class="text-small">All</span>
                              {/* <span class="checkmark"></span> */}
                            </label>
                            <span class="number-item">{search.length}</span>
                          </li>

                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setjobtype2(!jobtype2),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">part time</span>
                              {/* <span class="checkmark"></span> */}
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) => data.typeofjob === "Part time"
                                ).length
                              }
                            </span>
                          </li>
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setjobtype3(!jobtype3),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">Full time</span>
                              {/* <span class="checkmark"></span> */}
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) => data.typeofjob === "Full time"
                                ).length
                              }
                            </span>
                          </li>
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setjobtype4(!jobtype4),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">Both</span>
                              {/* <span class="checkmark"></span> */}
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter((data) => data.typeofjob === "Both")
                                  .length
                              }
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* price */}
                    {/* <div class="filter-block mb-20">
                      <h5 class="medium-heading mb-15">Salary Type</h5>

                      <div>
                        <Box sx={{ width: "90%" }}>
                          <Slider
                            aria-label="Temperature"
                            defaultValue={1000000}
                            getAriaValueText={valuetext}
                            valueLabelDisplay="auto"
                            step={10000}
                            min={min}
                            max={max}
                          />
                        </Box>
                      </div>
                    </div> */}
                    <div class="filter-block mb-30">
                      <h5 class="medium-heading mb-15">Salary Type</h5>
                      <div class="form-group">
                        <ul class="list-checkbox">
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) =>( setprice1(!price1),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">10000-20000</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) =>
                                    data.minSalary >= 10000 &&
                                    data.maxSalary <= 20000
                                ).length
                              }
                            </span>
                          </li>
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setprice2(!price2),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">20000-40000</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) =>
                                    data.minSalary >= 20000 &&
                                    data.maxSalary <= 40000
                                ).length
                              }
                            </span>
                          </li>

                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setprice3(!price3),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">40000-70000</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) =>
                                    data.minSalary >= 40000 &&
                                    data.maxSalary <= 70000
                                ).length
                              }
                            </span>
                          </li>

                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setprice4(!price4),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">70000 +</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter((data) => data.minSalary >= 70000)
                                  .length
                              }
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="filter-block mb-30">
                      <h5 class="medium-heading mb-15">Work Type</h5>
                      <div class="form-group">
                        <ul class="list-checkbox">
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setwork1(!work1),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">Work from home</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) => data.typeofwork === "Work from home"
                                ).length
                              }
                            </span>
                          </li>
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setwork2(!work2),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">Work from office</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) =>
                                    data.typeofwork === "Work from office"
                                ).length
                              }
                            </span>
                          </li>

                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setwork3(!work3),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">Field jobs</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) => data.typeofwork === "Field jobs"
                                ).length
                              }
                            </span>
                          </li>

                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setwork4(!work4),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">Freelancer</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) => data.typeofwork === "Freelancer"
                                ).length
                              }
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="filter-block mb-30">
                      <h5 class="medium-heading mb-15">Experience Required</h5>
                      <div class="form-group">
                        <ul class="list-checkbox">
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setexpp1(!expp1),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">Fresher Only</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) =>
                                    data.experiencerequired === "Fresher"
                                ).length
                              }
                            </span>
                          </li>
                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setexpp2(!expp2),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">Experience Only</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter(
                                  (data) =>
                                    data.experiencerequired === "Experience"
                                ).length
                              }
                            </span>
                          </li>

                          <li>
                            <label class="cb-container">
                              <input
                                type="checkbox"
                                onChange={(e) => (setexpp3(!expp3),setShowcat(false),setValue(""))}
                              />
                              <span class="text-small">Any</span>
                            </label>
                            <span class="number-item">
                              {
                                user.filter((data) => data.isBlock === false).filter((data) => data.isBlock === false).filter(
                                  (data) => data.experiencerequired === "Any"
                                ).length
                              }
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 col-12 ">
                <div className="sidebar-shadow none-shadow mb-30">
                  <div className="content-page">
                    <div className="box-filters-job">
                      <div className="row" >
                        {showcat ? user.filter((data) => data.isBlock === false)
                              .map((items, index) => {
                                return (
                                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                                    <div class="card-grid-2 hover-up">
                                      <div class="card-grid-2-image-left">
                                        <div class="right-info">
                                          <a
                                            class="name-job"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => apply1(items?._id)}
                                          >
                                            {items?.companyName.slice(0, 17)}
                                          </a>
                                          <span class="location-small">
                                            {" "}
                                            <span
                                              style={{ paddingRight: "8px" }}
                                            >
                                              <CiLocationOn />
                                            </span>
                                            {items?.location}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="card-block-info">
                                        <h6>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={() => apply1(items?._id)}
                                          >
                                            {items?.jobProfile.slice(0, 18)}
                                          </a>
                                        </h6>
                                        <div class="mt-3">
                                          <span class="card-briefcase">
                                            <span
                                              style={{ paddingRight: "4px" }}
                                            >
                                              <HiOutlineBriefcase />
                                            </span>
                                            {items?.typeofjob}
                                          </span>
                                          <span class="card-time">
                                            {" "}
                                            <span
                                              style={{ paddingRight: "4px" }}
                                            >
                                              <BsStopwatch />
                                            </span>
                                            {moment(items?.updatedAt).fromNow()}
                                            {/* <span> minutes ago</span> */}
                                          </span>
                                          <p>
                                            <HiOutlineBriefcase />{" "}
                                            {items.experiencerequired ===
                                              "Fresher" ||
                                            items.experiencerequired ===
                                              "Any" ? (
                                              <span>
                                                {items.experiencerequired}
                                              </span>
                                            ) : (
                                              <span>
                                                {items?.experience
                                                  ? items?.experience
                                                  : items.experiencerequired}
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                        {/* <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() => apply1(items?._id)}
                                        > */}
                                          {/* <p class="font-sm color-text-paragraph mt-3 mb-3">
                                            {items.description
                                              ? parse(
                                                  items?.description.slice(
                                                    0,
                                                    20
                                                  )
                                                )
                                              : ""}
                                            ...
                                          </p> */}
                                        {/* </a> */}
                                        {/* <div class="mt-30 mb-3">
                                          
                                          <a
                                            class="btn btn-grey-small mr-5"
                                            onClick={() => apply1(items?._id)}
                                          >
                                            {items?.skill.slice(0, 40)}
                                          </a>
                                        </div> */}
                                        <div class="card-2-bottom mt-30">
                                          <div class="row">
                                            <div class="col-lg-7 col-7">
                                             {items?.minSalary ? <span class="card-text-price">
                                                {"₹ "}
                                                {items?.minSalary}-
                                                {items?.maxSalary}
                                              </span> : ""}
                                              {items?.minSalary ? <span class="text-muted">
                                                /{items?.period}
                                              </span> :""}
                                            </div>
                                            <div class="col-lg-5 col-5 text-end">
                                              <div
                                                class="btn btn-apply-now"
                                                data-bs-toggle="modal"
                                                data-bs-target="#ModalApplyJobForm"
                                                onClick={() => {
                                                  applyJob(items?._id);
                                                }}
                                              >
                                                Apply now
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }) :fillterData.length  
                          ? [...new Set(fillterData)]
                              .filter((data) => data.isBlock === false)
                              .map((items, index) => {
                                return (
                                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                                    <div class="card-grid-2 hover-up">
                                      <div class="card-grid-2-image-left">
                                        <div class="right-info">
                                          <a
                                            class="name-job"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => apply1(items?._id)}
                                          >
                                            {items?.companyName.slice(0, 17)}
                                          </a>
                                          <span class="location-small">
                                            {" "}
                                            <span
                                              style={{ paddingRight: "8px" }}
                                            >
                                              <CiLocationOn />
                                            </span>
                                            {items?.location}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="card-block-info">
                                        <h6>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={() => apply1(items?._id)}
                                          >
                                            {items?.jobProfile.slice(0, 18)}
                                          </a>
                                        </h6>
                                        <div class="mt-3">
                                          <span class="card-briefcase">
                                            <span
                                              style={{ paddingRight: "4px" }}
                                            >
                                              <HiOutlineBriefcase />
                                            </span>
                                            {items?.typeofjob}
                                          </span>
                                          <span class="card-time">
                                            {" "}
                                            <span
                                              style={{ paddingRight: "4px" }}
                                            >
                                              <BsStopwatch />
                                            </span>
                                            {moment(items?.updatedAt).fromNow()}
                                            {/* <span> minutes ago</span> */}
                                          </span>
                                          <p>
                                            <HiOutlineBriefcase />{" "}
                                            {items.experiencerequired ===
                                              "Fresher" ||
                                            items.experiencerequired ===
                                              "Any" ? (
                                              <span>
                                                {items.experiencerequired}
                                              </span>
                                            ) : (
                                              <span>
                                                {items?.experience
                                                  ? items?.experience
                                                  : items.experiencerequired}
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                        {/* <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() => apply1(items?._id)}
                                        >
                                          <p class="font-sm color-text-paragraph mt-3 mb-3">
                                            {items.description
                                              ? parse(
                                                  items?.description.slice(
                                                    0,
                                                    20
                                                  )
                                                )
                                              : ""}
                                            ...
                                          </p>
                                        </a> */}
                                        {/* <div class="mt-30 mb-3">
                                          
                                          <a
                                            class="btn btn-grey-small mr-5"
                                            onClick={() => apply1(items?._id)}
                                          >
                                            {items?.skill.slice(0, 40)}
                                          </a>
                                        </div> */}
                                        <div class="card-2-bottom mt-30">
                                          <div class="row">
                                          <div class="col-lg-7 col-7">
                                             {items?.minSalary ? <span class="card-text-price">
                                                {"₹ "}
                                                {items?.minSalary}-
                                                {items?.maxSalary}
                                              </span> : ""}
                                              {items?.minSalary ? <span class="text-muted">
                                                /{items?.period}
                                              </span> :""}
                                            </div>
                                            <div class="col-lg-5 col-5 text-end">
                                              <div
                                                class="btn btn-apply-now"
                                                data-bs-toggle="modal"
                                                data-bs-target="#ModalApplyJobForm"
                                                onClick={() => {
                                                  applyJob(items?._id);
                                                }}
                                              >
                                                Apply now
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                          : "No Jobs"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Findjob;
