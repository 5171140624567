import React, { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import {
  AiFillStar,
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { FiFacebook,FiPhoneOutgoing } from "react-icons/fi";
import {MdOutlineMailOutline} from "react-icons/md"
import { Link } from "react-router-dom";
import Client from "./Client";

function About() {
  const [Mission, setMission] = useState("true");
  const [Vision, setVision] = useState("false");
  const [Objectives, setObjectives] = useState("false");

  const [State, setState] = useState(false);
  const [State1, setState1] = useState(false);
  const [State2, setState2] = useState(false);

  return (
    <>
      <div className="about-00">
        <section class="section-box">
          <div
            class="breacrumb-cover bg-img-about"
            style={{
              backgroundImage: "url('/Images/pic-1.jpg')",
              backgroundRepeat: "no repeat",
              backgroundSize: "cover",
              position: "relative",
              padding: "120px 0px",
              // backgroundPosition: "center",
            }}
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <h2 class="mb-10">About Us</h2>
                  <p class="font-lg color-text-paragraph-2">
                    Get the latest news, updates and tips
                  </p>
                </div>
                {/* <div class="col-lg-6 text-lg-end">
                  <Breadcrumb className="breadcrumbs ">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Data</Breadcrumb.Item>
                  </Breadcrumb>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* ===================== */}
        <section class="section-box mt-50">
          <div class="post-loop-grid">
            <div class="container">
              <div class="text-center">
                {/* <h6 class="f-18 color-text-mutted text-uppercase">
                  Our company
                </h6> */}
                <h2
                  class="mt-15 mb-10 wow animate__animated animate__fadeInUp"
                  style={{ fontSize: "36px" }}
                >
                  Univi India HR Team

                </h2>
                <p class="font-md color-text-paragraph mt-20 ">
                  In this competitive market Univi India  is one
                  of the most aggressive management team in the market &
                  managed multinational team with expertise in markets spanning
                  the globe. The Univi delivers on unparalleled range of
                  flexible staffing and career resources to the client and
                  associates. Process Skills and Expertise to meet the client's
                  requirements round the clock assuring business as usual.
                </p>
              </div>
              <div class="row mt-70">
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <img
                    src="/Images/office.jpg"
                    alt="joxBox"
                    style={{ width: "100%" }}
                  />
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <h3 class="mt-15">About </h3>
                  <div class="mt-20">
                    <p class="font-md color-text-paragraph mt-20">
                      <span
                        style={{ color: "rgb(5, 38, 78)", fontWeight: "800" }}
                      >
                        {" "}
                        Univi India
                      </span>{" "}
                      headquartered at Bommanahalli Bangalore is a leading
                      end-to-end HR solutions company with a focus on
                      Recruitment, Executive Search, Learning and Temporary
                      Staffing services to the client organizations from 2012.
                      Strength is low staff costs, a large pool of skilled,
                      speaking workforce and contributive policy environment for
                      customers seeking outsourced services & effective
                      management resources to the corporate, as their
                      requirements in the competitive markets (HR’S Operation,
                      Tele-sales , Marketing person & sales person). Univi India
                      is an Business Process Outsourcing company, Banking
                      services Customer Lifecycle, Back-office Lifecycle
                      Management, IT outsourcing Services which provides
                      integrated solutions and outsourcing solutions.
                    </p>
                  </div>
                  {/* <div class="mt-30">
                    <button
                      class="btn btn-brand-1"
                      style={{
                        padding: "12px 25px",
                        background: "#05264e",
                        color: "white",
                        bordeRadius: "8px",
                        border: "none",
                      }}
                    >
                      Read More
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ==================== */}
        <div className="container mt-80">
          <div className="row">
           
            <div className="col">
            <div className="category-header" style={{ display: "flex",justifyContent:"end" }}>
              <p className="d-flex">
                <Link to="#"
                 
                    className="sbtn"
                    onClick={() => {
                      setMission(true);
                      setVision(false);
                      setObjectives(false);
                    }}
              >
                    <h3 class="mt-15" style={{fontSize:"25px"}}>Vision </h3>
                  
                </Link>
                <Link to="#"
                  
                    className="sbtn"
                    onClick={() => {
                      setMission(false);
                      setVision(true);
                      setObjectives(false);
                    }}
                   >
                    <h3 class="mt-15" style={{marginLeft:"35px",fontSize:"25px"}} > Mission </h3>
                  
                </Link>
                <Link to="#"
                  
                    className="sbtn"
                    onClick={() => {
                      setMission(false);
                      setVision(false);
                      setObjectives(true);
                    }}
                    >
                    <h3 class="mt-15"  style={{marginLeft:"35px",fontSize:"25px"}}> Objectives </h3>
                  
                </Link>
              </p>
            </div>
              {Mission ? (
                <>
                  {/* <div className=" recidentian-page"> */}
                  <div className="rec-header">
                    <div className="row">
                      <div className="col-md-6">
                        <img
                          src="/Images/12084748_20943892.jpg"
                          style={{ height: "100%", width: "50%" }}
                        />
                      </div>
                      <div className="col-md-6">
                        <h6 className="f-18 color-text-mutted text-uppercase mt-50">
                          Vision
                        </h6>
                        <p class="font-md color-text-paragraph mt-20">
                       To be the best and most effective Service Provider of Banks,Retailer market,Production,Hospitality
                  BPO, ITES, Health care, Trading and Sales & Marketing
                  companies enabled services in India at large.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </>
              ) : (
                <>
                  {Vision ? (
                    <>
                      {/* <div className="rec-header"> */}
                      <div className="row">
                        <div className="col-md-6">
                          <img
                            src="/Images/25273851_na_feb_25.jpg"
                            style={{ height: "100%", width: "50%" }}
                          />
                        </div>
                        <div className="col-md-6">
                          <h6 className="f-18 color-text-mutted text-uppercase mt-50">
                            Mission
                          </h6>
                          <p class="font-md color-text-paragraph mt-20">
                            To partner with our clients to integrate “flexibility” into
                  their workforce and take care of their routine people
                  management activities, helping them concentrate on their core
                  business. Promoting and enhancing outsourcing services by
                  deriving the utility of Indian markets and strictly adhere to
                  data security and data protection measures that will ensure
                  maximum security of our clients' data.
                          </p>
                        </div>
                      </div>
                      {/* </div> */}
                    </>
                  ) : (
                    <>
                      {Objectives ? (
                        <>
                          <div className="rec-header">
                            <div className="row">
                              <div className="col-md-6">
                                <img
                                  src="/Images/goal.png"
                                  style={{ height: "100%", width: "50%" }}
                                />
                              </div>
                              <div className="col-md-6">
                                <h6 className="f-18 color-text-mutted text-uppercase mt-50">
                                  Objectives
                                </h6>
                                <p class="font-md color-text-paragraph mt-20">
                                  Employment - To create employment amongst the
                                  Indian youth and skilled workforce at large,
                                  through the Univi India (BPO, ITES, Health
                                  care, Trading Sales & Marketing companies
                                  Hospitals).
                                  <ul>
                                    <li>
                                      <span>Growth: </span>To grow the business
                                      at a rate that is both demanding and
                                      manageable, leading the market with
                                      innovation and adaptability.
                                    </li>
                                    <li>
                                      <span>Citizenship: </span>To be an
                                      intellectual and social asset to the
                                      community and environment.
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <> </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {/* ===================== */}
        <section className="section-box mt-80">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h3 class="mt-15">Leadership Team</h3>
                <img src="/Images/pngegg.png" style={{ width: "50%" }} />
                <p class="font-md color-text-paragraph mt-20">
                  He Univi India leadership team is a blend of experts from
                  diverse backgrounds. Throughout their careers at some of the
                  most established firms in the world, their hallmark has been
                  exceptional achievement. Our leaders are commissiontted to
                  build a top-notch organization capable of delivering best of
                  breed solutions bringing sustainable value to customers.
                </p>
              </div>
              <div className="col-md-4">
                <h3 class="mt-15">Univi India </h3>
                <img src="/Images/hr.png" style={{ width: "50%" }} />
                <p class="font-md color-text-paragraph mt-20">
                  Outsourcing to Univi India completely elimissionnates the
                  costs of recruiting, hiring, training and payroll taxes. You
                  don't have to deal with the stress of finding and hiring new
                  people or reducing your work force when they aren't needed.
                  Univi India provide high quality,
                </p>

              
        

                      {State1 ? (
                  <>
                      <p class="font-md color-text-paragraph mt-20"> Time effective outsourcing for banks, IT’s, other industrial services
                      through offshore facilities in Bangalore. Changing and
                      challenging all other business environment and
                      technological and legislative changes act as a catalyst to
                      this trend.
                    </p>
                    <p class="font-md color-text-paragraph mt-20">
                      Univi India has built its value proposition as a client
                      partner rather than a service provider with the ability to
                      provide customissionzed delivery solutions based on the
                      client's requirements and enabling process improvements
                      using industry benchmarks and standard quality processes.
                      Listed below are few differentiators.
                    </p>
                    <div class="wow animate__animated animate__fadeInUp">
                    <button
                      className="btn btn-default"
                      onClick={() => setState1(false)}
                      style={{padding: "4px 25px"}} >
                      Read less
                    </button></div>
                  </>
                ) : (
                  <> <div class="wow animate__animated animate__fadeInUp">
                    <button
                      className="btn btn-default"
                      onClick={() => setState1(true)}
                      style={{padding: "4px 25px"}}
                    >
                      Read More
                    </button></div>
                  </>
                )}
                <br />
              </div>
              <div className="col-md-4">
                {" "}
                <h3 className="mt-15">Operational Procedure</h3>
                <img
                  src="/Images/Procedure.png"
                  style={{ height: "20%", width: "50%" }}
                />
                <p class="font-md color-text-paragraph mt-20">
                  An order to meet the individual needs of clients, Univi India
                  maintain a wide range of Qualifications. We excel in our
                  Banks, Shops out Lets, BPO, ITES, Sales & Marketing and other
                  Operational & Quality skills. Our extensive knowledge base
                  entails us in planning and executing 24 X 7 Operations
                  effectively.</p>
                    {/* ==============read more===== */}
                {State ? (
                  <>
                  <p class="font-md color-text-paragraph mt-20"> Moreover, our functional and technical experience
                  extends to interfacing our CRM with our Clients.
                </p>
              
                    <p class="font-md color-text-paragraph mt-20">
                      Applications and various call based functions. At Univi
                      India we are continually expanding upon our knowledge and
                      services to assist clients with successfully implementing
                      of their “Business Solutions” across.
                    </p>

                    <div class="btn-container"></div>
                    <p class="font-md color-text-paragraph mt-20">
                      We at Univi India follow a robustly defined operational
                      procedure to ensure process integrity and
                      missionnimissionze inherent outsourcing risks. We help
                      clients establish the right outsourcing strategy, and help
                      them in devising a plan to implement the strategy and
                      De-risking the plan to ensure success.
                    </p>
                    <ul class="font-md color-text-paragraph mt-20">
                      <li>
                        At this stage we try and understand clients business,
                        identify outsource opportunities, assess requirements,
                        review environment and confirm validity.
                      </li>
                      <li>
                        For analyzing, we gather documentation, identify
                        solution and costing, confirm and validate with client
                        and prepare the process implementation plan.
                      </li>

                      <li>
                        At this juncture, we adapt process plan, emulate client
                        process, document operating plan, and confirm
                        performance requirements.
                      </li>
                    </ul>
                    <div class="wow animate__animated animate__fadeInUp">
                    <button
                      className="btn btn-default"
                      onClick={() => setState(false)}
                      style={{padding: "4px 25px"}} >
                      Read less
                    </button></div>
                  </>
                ) : (
                  <>
                  <div class="wow animate__animated animate__fadeInUp">
                    <button
                      className="btn btn-default"
                      onClick={() => setState(true)}
                      style={{padding: "4px 25px"}} >
                      Read More
                    </button></div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row" style={{ marginTop: "0%" }}>
            <h3 class="mt-15">
              IT’S, Banks and Sales & Marketing enabled Services
            </h3>
            <div className="col-md-6">
              <img
                src="/Images/bank.webp"
                style={{ height: "100%", width: "50%" }}
              />
            </div>
            <div className="col-md-6 mt-70">
              <p class="font-md color-text-paragraph mt-20">
                IT, Banks and sales & marketing’s Enabled Services has
                drastically increased over years. It is more being recognized
                not merely as a tool of cost reduction but is also being
                considered as a management tool for achieving higher
                organizational effectiveness.
              </p>
              <p class="font-md color-text-paragraph mt-20">
                IT, Banks and sales & marketing’s enabled Services is a
                revolutionary offshoot which can look after all outsourcing
                needs whether it's Customer Relationship Management (CRM),
                Finance and Accounting services, Transcription/Translation
                services, data processing, Document handling or call centers.
              </p>

              {State2 ? (
                <>
                  <p class="font-md color-text-paragraph mt-20">
                    Outsourcing helps organization to focus on developing its
                    core business. Outsourcing, Integrates all the tasks that
                    are not part of the organization's main line activity or
                    core process and streamlines business processes by improving
                    operating performance, increase efficiency productivity and
                    control cost in order to help management better focus on
                    increasing sales and market share, developing new and
                    improve products and enhancing customer service and
                    satisfaction.
                  </p>

                  <p class="font-md color-text-paragraph mt-20">
                    Univi India has built its value proposition as a client
                    partner rather than a service provider with the ability to
                    provide customissionzed delivery solutions based on the
                    client's requirements and enabling process improvements
                    using industry benchmarks and standard quality processes.
                  </p>
                  <p class="font-md color-text-paragraph mt-20">
                    IT, Banks and sales & marketing’s Enabled Services has
                    drastically increased over years. It is more being
                    recognized not merely as a tool of cost reduction but is
                    also being considered as a management tool for achieving
                    higher organizational effectiveness.
                  </p>
                  <div class="wow animate__animated animate__fadeInUp">
                  <button
                    className="btn btn-default"
                    onClick={() => setState2(false)}
                    style={{padding: "4px 25px"}} >
                    Read less
                  </button></div>
                </>
              ) : (
                <>
                <div class="wow animate__animated animate__fadeInUp">
                  <button
                    className="btn btn-default"
                    onClick={() => setState2(true)}
                    style={{padding: "4px 25px"}} >
                    Read More
                  </button></div>
                </>
              )}
            </div>
            <div className="col-md-6">
              <h3>Univi India Efficiency</h3>
              <p class="font-md color-text-paragraph mt-20">
                An organization can undergo the following benefits by
                outsourcing their processes.
              </p>
              <ul class="font-md color-text-paragraph mt-20">
                <li>Gain a competitive edge.</li>
                <li>Better Process Maturity and Resource Flexibility.</li>
                <li>Control cost through significant reductions.</li>
                <li>Improve customer satisfaction.</li>
                <li>Increase market share and revenues.</li>
                <li>Improve productivity and operating efficiency.</li>
                <li>Manage security, privacy and risk.</li>
                <li>Increase flexibility and capacity.</li>
                <li>Enhance overall performance.</li>
              </ul>
              <h3>Core Values & Differentiators</h3>
              <ul class="font-md color-text-paragraph mt-20">
                <li>
                  Recognizes its employees as the foundation of its success
                </li>
                <li>Constantly surpasses the client's expectations</li>
                <li>
                  Leads by example through personal accountability and
                  credibility
                </li>
                <li>Shows transparency in all dealings</li>
                <li>Strives to improve by seeking feedback from others</li>
                <li>
                  Actively fosters the Mission, Vision and values of the
                  organization
                </li>
                <li>Does not compromissionse on ethical values</li>
                <li>
                  Reflects upon own experiences for continuous learning and
                  growth
                </li>
                <li>
                  Recognizes its employees as the foundation of its success
                </li>
              </ul>
            </div>
            <div className="col-md-6 mt-70">
              <img
                src="/Images/bussiness.png"
                style={{ height: "90%", width: "70%" }}
              ></img>
            </div>
            <div className="col-md-6 mt-40">
              <h3>Univi India Business Model.</h3>
              {/* <img
                  src="/Images/bussiness.png"
                  style={{ height: "15%", width: "15%" }}
                /> */}

              <p class="font-md color-text-paragraph mt-20">
                The management team at Univi India has a vast extensive
                experience of transitioning global business processes to India
                and running effective service operations for global
                organizations.
              </p>
              <p class="font-md color-text-paragraph mt-20">
                Bangalore has one of the best education systems in India and
                enjoys over 80% of Literacy. Univi India recruits from a rich
                pool of talented, energetic and ambitious who are keen to be
                part of the growing success.
              </p>
              <p class="font-md color-text-paragraph mt-20">
                We have one of the best learning and training facilities in the
                industry to train them.{" "}
              </p>
            </div>
            <div className="col-md-6">
              {" "}
              <h3 className="mt-5">Costing Models</h3>
              <p class="font-md color-text-paragraph mt-20">
                At Univi India we offer various Costing models based on our
                Customer needs to match the labor arbitrage
              </p>
              <ul class="font-md color-text-paragraph mt-20">
                <li>Fixed cost pricing</li>
                <li>Resource based pricing</li>
                <li>Transaction based pricing</li>
                <li>Analysis based pricing</li>
              </ul>
            </div>
          </div>

          <h3 className="mt-15">Univi India  Pvt Ltd</h3>
          <h3 className="mt-15">Nethra. Y</h3>
         <div className="row">
          <div className="col-md-4">
          <div class="font-md color-text-paragraph mt-20"><span style={{paddingRight:"10px"}}><FiPhoneOutgoing/></span> 9886040444</div>
          <div
            class="font-md color-text-paragraph "
            style={{ fontWeight: "bold" }}
          >
           <ul style={{listStyle:"none",paddingLeft:"0px"}}>
            <li><a href="#"><span style={{paddingRight:"10px"}}><MdOutlineMailOutline/></span >univi.india@gmail.com</a></li>
            {/* <li><a href="#"><span  style={{paddingRight:"10px"}}><MdOutlineMailOutline/></span>srinivas@univiindia.in</a></li> */}
            <li> <a href="https://univiindia.com/"><span  style={{paddingRight:"10px"}}><MdOutlineMailOutline/></span>www.univiindia.com</a></li>
            <li><a href="#"><span  style={{paddingRight:"10px"}}><IoLocationOutline/></span> Service location- Hyderabad, Andhra Pradesh, Chennai, Coimbatore</a></li>
           
            </ul>
          </div>
          
          </div>
          <div className="col-md-4">
          <p class="font-md color-text-paragraph mt-20">
           <span style={{fontSize:"20px",fontWeight:"600"}}> Add </span>- # 42/118, Venkatappa complex, <br />
            Bandepalya Main Road,<br/> GB Palya, Bommanahalli,<br/> 
            Bangalore - 560068
          </p>
          

          </div>
          <div className="col-md-4">
          <p class="font-md color-text-paragraph mt-20">
          <span style={{fontSize:"20px",fontWeight:"600"}}> Add </span>- # 39, 1st Main 1cross, 
            <br />
            Jakkasandra Extn, Koramangala 1st Block, <br/> Bangalore – 560034
            <br />
            Near – Wipro Park / Kabab Magic
          </p>
          </div>
         </div>
        </div>

      </div>
      <section class="section-box overflow-visible mt-50 mb-50">
        <div class="container">
          <div >
         <Client/>
          </div>
        </div>
        </section>
    </>
  );
}

export default About;