import React from "react";

import Home1 from "./Home1";

function Home() {
  return (
    <>
      <Home1 />
    </>
  );
}

export default Home;
