import axios from "axios";
import React from "react";

function ResumeWriting() {
  let check = JSON.parse(sessionStorage.getItem("user"));

  const onSubmit = async () => {
    if(check){
      let obj={
        name:check?.name,
        userId:check._id,
        email:check?.email,
        mobile:check?.mobile 
      }
    try {
      const config = {
        url: "/resumeRegister",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: obj,
      };
      let res = await axios(config);
      if (res.status === 200) {
        window.location.assign("/resume-create")
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  }else{
    alert("Please login")
    window.location.assign("/employee-login")
  }
  };

  return (
    <>
      <div className="container">
        <div class="cs-banner-2 right-resume-banner">
          <div class="banner-left">
            <div class="content">
              <h1 class="banner-heading">Resume Writing</h1>{" "}
              <div class="sub-heading">
                Get professionally written resume which helps you get your dream
                job
              </div>
            </div>
          </div>{" "}
          <div class="banner-right">
            <div className="">
            <button class="btn btn-secondary w-100" onClick={()=>onSubmit()}>Let’s Start</button>
             
            </div>
            <div class="banner-img">
              <img
                src="//media.monsterindia.com/trex/public/default/images/career-services/right-resume-banner.png"
                width="154"
                height="166"
                alt="Resume Writing"
                class="load-lazily"
              />
            </div>
          </div>
        </div>

        <div>
          <div class="key-feature">
            <div class="key-feature-heading">Key Features</div>{" "}
            <div class="row key-feature-content">
              <div class="col-xxs-6 col-lg-3">
                <div class="key-feature-content-box">
                  <div class="key-feature-content-inner">
                    <img
                      src="//media.monsterindia.com/trex/public/default/images/career-services/write-resume-feature-1.png"
                      alt="feature1"
                      class="load-lazily"
                    />{" "}
                    <div class="key-feature-content-inner-text">
                      Recruiter-friendly resume
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xxs-6 col-lg-3">
                <div class="key-feature-content-box">
                  <div class="key-feature-content-inner">
                    <img
                      src="//media.monsterindia.com/trex/public/default/images/career-services/write-resume-feature-2.png"
                      alt="feature2"
                      class="load-lazily"
                    />{" "}
                    <div class="key-feature-content-inner-text">
                      Increase your visibility to Recruiters
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xxs-6 col-lg-3">
                <div class="key-feature-content-box">
                  <div class="key-feature-content-inner">
                    <img
                      src="//media.monsterindia.com/trex/public/default/images/career-services/write-resume-feature-3.png"
                      alt="feature3"
                      class="load-lazily"
                    />{" "}
                    <div class="key-feature-content-inner-text">
                      Right keywords for better selection
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xxs-6 col-lg-3">
                <div class="key-feature-content-box">
                  <div class="key-feature-content-inner">
                    <img
                      src="//media.monsterindia.com/trex/public/default/images/career-services/write-resume-feature-4.png"
                      alt="feature4"
                      class="load-lazily"
                    />{" "}
                    <div class="key-feature-content-inner-text">
                      Impress recruiters with a professionally written resume
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResumeWriting;
