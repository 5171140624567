import React, { useEffect, useState } from "react";
import ProSidebar from "./ProSidebar";
import Button from "react-bootstrap/Button";
import { BiLogOut, BiLogOutCircle } from "react-icons/bi";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./MajorProducts.css";
import { FaPager } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { BsArrowsFullscreen, BsFlagFill } from "react-icons/bs";
import { FcApproval, FcApprove } from "react-icons/fc";
import {CgProfile} from "react-icons/cg"
import {MdOutlineAppRegistration, MdOutlineSearch, MdWorkOutline} from "react-icons/md"
import { Link } from "react-router-dom";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import axios from "axios";

function Admin(props) {
  let users=JSON.parse(sessionStorage.getItem("employer"));
  if(!users){
    alert('please Login');
    window.location.assign("employers-login")
  }
  const [check,setcheck]=useState({});
  const loadUsers = async () => {
    const config = {
      url: "/user/getEmployerById/" + users._id,
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setcheck(result.data.success);
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  const logout=()=>{
    sessionStorage.removeItem("employer");
    window.location.assign("/")
  }
  useEffect(()=>{
    loadUsers();
  },[])
 
 
  return (
    <>
      <div
        style={{
          zIndex: "100",
          top: "0px",
          width: "100%",
          position: "fixed",
        }}
      >
        <Navbar
          bg=""
          expand="lg"
          style={{
            backgroundColor:" #2bb792",
            marginBottom:"35px"
          }}
        >
          <Container fluid>
            <div style={{ justifyContent: "flex-start" }}>
              <a href="/Employer-home">
                <img
                  src="/admin.logo.png"
                  alt=""
                  width="114px"
                  class="hoverZoomLink"
                  // style={{ height: "50px", borderRadius: "8px" }}
                />
              </a>
            </div>

            <h6
              className="nav-link "
              data-rr-ui-event-key="/career-guidance"
              style={{ marginLeft: "895px" }}
            >
              {" "}
              {/* <BsFlagFill style={{ margin: "0px 10px", fontSize: "22px" }} /> */}
              {/* <BsArrowsFullscreen
                style={{ margin: "0px 5px", fontSize: "22px" }}
              /> */}
              <Link to={"/employerprofile"}>
              {check?.profile ? ( <img
                src={`https://univiindia.com/employer/${check?.profile}`}
                width="30"
                height="30"
                className="rounded-circle"
                alt=""
                style={{ marginRight: "10px" }}
              />):( <img
                src="./../Images/profileIcon.png"
                width="30"
                height="30"
                className="rounded-circle"
                alt=""
                style={{ marginRight: "10px" }}
              />)}
             
              {check?.userName}</Link>
              {/* <AiOutlineSetting
                style={{ margin: "0px 10px", fontSize: "22px" }}
              /> */}
            </h6>

            <Button
              variant="outline-info"
              onClick={()=>logout()}
              style={{
                color: "rgb(5, 38, 78)",
                border: "1px solid rgb(5, 38, 78)",
              }}
            >
              {" "}
              <BiLogOutCircle />
              Logout
            </Button>
          </Container>
        </Navbar>{" "}
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-md-3 fixed"
              style={{
                // border: "1px solid aliceblue",
                // height: "700px",
                // backgroundColor: "aliceblue",
                color: "color",
                // width: "20%",
                paddingTOp:"10px",
                height: "100%",
                position: "fixed",
              }}
            >
              {/* <div className="container"> */}
              <div className="row">
                <div className="col-md-12">
                  <h4 style={{ fontSize: "20px", color: " #4f5e64" }}>
                    Welcome back! {"  "}<span>{users?.name}</span>
                  </h4>
                  {/* <h5 style={{color:"#3053a7"}}>DASHBOARDS</h5> */}
                </div>
             
              </div>
              {/* {check?.profile ? ( <img
                src={`https://univiindia.com/employer/${check?.profile}`}
                class="rounded-circle"
                alt="avatar2"
                style={{
                  height: "100px",
                  margin: "10px 105px",
                  border: "4px solid white",
                  width:"100px"
                }}
               
              />):( <img
                class="rounded-circle"
                alt="avatar2"
                src="./../Images/profileIcon.png"
                style={{
                  height: "100px",
                  margin: "10px 97px",
                  border: "4px solid white",
                }}
              
              />)} */}
             
              
              {/* <div
                className="bg-white"
                style={{
                  padding: "30px 10px",
                  marginTop: "-50px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <h1
                  className="adm textalign-center"
                  style={{
                    textAlign: "center",
                    color: "#36454F",
                    fontSize: "20px",
                    fontFamily: "",
                    marginTop: "15px",
                  }}
                >
                  {check?.name}
                </h1>

                <div
                  className="row"
                  style={{
                    marginLeft: "-148px",
                    marginTop: "-4px",
                    color: "black",
                  }}
                >                 

              <Link to={`/employerprofile`}>
                <Button
                  style={{
                    marginLeft: "134px",
                    padding: "6px 20px",
                    backgroundColor: "rgb(5, 38, 78)",
                    color: "white",
                    border: "none",
                  }}
                >
                  View Profile
                </Button></Link>
                </div>
              </div>
              <hr></hr> */}
            
              
          
              

           
              <div
                className="container"
                style={{
                  textAlign: "left",
                  lineHeight: "1.7rem",
                  color: "#e0e6f7",
                }}
              >
                <ul style={{
                  listStyle:"none",
                  padding:"0px"
                }}>
                <li className="hero_link_j">
            <Link to={`/Employer-home`}>
                                  
                      <AiOutlineHome style={{ marginRight: "15px" }} />
                      Home
                  </Link></li>
                  <li className="hero_link_j">
            <Link to={`/employerprofile`}>
                                  
                      <CgProfile style={{ marginRight: "15px" }} />
                      My Profile
                  </Link></li>
                 <li className="hero_link_j">
                  <Link to={`/unverifed-employer-job`}>
                
                   
                      <MdWorkOutline style={{ marginRight: "15px" }} />
                      Posted Job
                   
                  </Link> </li>
             
                  <li className="hero_link_j">
                  <Link to={`/myjob`}>
                                  
                      <FcApproval style={{ marginRight: "15px" }} />
                      Approved Jobs
                  </Link></li>
                  <li className="hero_link_j"> <Link to={`/applieddetails`}  >
                      <MdOutlineAppRegistration style={{ marginRight: "15px" }} />
                      Applied Details
              </Link></li>
                 <li className="hero_link_j">
                  <Link to={`/find-user`} >                   
                      <MdOutlineSearch style={{ marginRight: "15px" }} />
                     Search Employees
                  </Link></li>
                  <li className="hero_link_j"> <Link to={`/shortlisted`}>
                
                    {" "}
                   
                      <FcApprove style={{ marginRight: "15px" }} />
                      Shortlisted Employees
              
                 </Link></li>
                 
              {/* <li>
                  <Link to={`/interested-employees`}>
                  <a style={{ color: "rgb(36, 73, 106)",fontSize:"23px" }}>
                      <FaPager style={{ marginRight: "8px" }} />
                      Interested Employees
                  </a></Link></li> */}
               
               
                </ul>
                {/* </div> */}
              </div>
            </div>
            <div className="col-md-17">
              {props.children}

              {/* card end */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
