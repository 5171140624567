import React, { useEffect } from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import Wcs from "./Wcs";
import { Link } from "react-router-dom";
// back-e
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { saveAs } from "file-saver";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { BiUserCircle } from "react-icons/bi";
import ReactPaginate from "react-paginate";
function Applieddetails() {
  // rejectApply
  const check = JSON.parse(sessionStorage.getItem("employer"));

  const [details, setAlldetails] = useState([]);

  const getAllAplliedDetals = async () => {
    try {
      let res = await axios.get("https://univiindia.com/api/user/AllAplliedDetals");
      if (res.status === 200) {
        //console.log(res.data);
        setAlldetails(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const loadUsers1 = async () => {
    const config = {
      url: "/user/getEmployerById/" + check?._id,
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        sessionStorage.removeItem("employer");
        sessionStorage.setItem("employer", JSON.stringify(result.data.success));
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };

  useEffect(() => {
    loadUsers1();
    getAllAplliedDetals();
  }, []);

  const addShortList = async (userId, companyId) => {
    try {
      const config = {
        url: "/addShortList",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: {
          userId: userId,
          companyId: companyId,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAllAplliedDetals();
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };
  const rejectApply = async (userId, companyId) => {
    try {
      const config = {
        url: "/rejectApply",
        method: "post",
        baseURL: "https://univiindia.com/api/user",
        headers: { "content-type": "application/json" },
        data: {
          userId: userId,
          companyId: companyId,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAllAplliedDetals();
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };
  const saveFile = (item, name) => {
    saveAs(`https://univiindia.com/user/${item}`, `${name}Resume.pdf`);
  };

  const data = [];
  if (details?.length !== 0) {
    for (let i = 0; i < details.length; i++) {
      if (
        details[i]?.companyId?.employerId == check?._id &&
        details[i]?.status === "Applied"
      ) {
        data.push({
          JobID: details[i]?.companyId?._id.slice(18, 24),
          CompanyName: details[i]?.companyId?.companyName,
          EmployeeName: details[i]?.userId?.name,
          EmployeeNumber: details[i]?.userId?.mobile,
          EmployeeEmail: details[i]?.userId?.email,
          AppliedOn: moment(details[i]?.updatedAt).fromNow(),
        });
      }
    }
  }

  const exportType = "xls";
  const [fileName, setfileName] = useState("AppliedDetails");

  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };
  //console.log("amity====>", details);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    details
      ?.filter((ele) => ele?.companyId?.employerId === check?._id)
      ?.filter((el) => el?.status === "Applied").length / usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <>
      {" "}
      {check.status !== "Approved" ? (
        <div style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
          <br></br>
          <h3>Admin Not approved your profile please update.</h3>{" "}
          {check?.reasion ? <p>{check?.reasion}</p> : <></>}
        </div>
      ) : (
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            <div
              className="container"
              style={{
                border: "1px solid #24496a",
                borderRadius: "8px",
                marginBottom: "10px",
                boxShadow: " rgb(0 0 0 / 16%) 0px 1px 4px",
                border: " 1px solid #00000047",
              }}
            >
              <div class="container">
                <div class="loginform" style={{ textAlign: "center" }}>
                  <h1
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                    }}
                  >
                    <b>Applied Details</b>
                  </h1>
                  {/* <button onClick={ExportToExcel} style={{backgroundColor:'green',color:'white',float:"right"}}>Export to Excel</button> */}
                </div>
              </div>
              <br />
              <div className="tble">
                <table style={{ width: "100%", marginBottom: "20px" ,zIndex:"auto"}}>
                  <tr>
                    <th>Job ID</th>
                    <th>Company Name</th>
                    <th>Employees Name</th>
                    <th>Employee Contact</th>
                    {/* <th>Job Looking</th> */}
                    <th>Resume</th>
                    <th>ShortList</th>
                    <th>Reject</th>
                    <th>Applied on</th>
                  </tr>
                  {details
                    ?.filter((ele) => ele?.companyId?.employerId === check?._id)
                    ?.filter((el) => el?.status === "Applied")
                    .slice(pagesVisited, pagesVisited + usersPerPage)
                    ?.map((items) => {
                      return (
                        <tr>
                          <td>
                            <Link
                              to={`/employeecompanydetails/${items?.companyId?._id}`}
                            >
                              {items?.companyId?._id.slice(18, 24)}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/employeecompanydetails/${items?.companyId?._id}`}
                            >
                              {items?.companyId?.companyName}
                            </Link>
                          </td>
                          <td>
                            {items?.userId?.profile ? (
                              <img
                                src={`https://univiindia.com/user/${items?.userId?.profile}`}
                                width="30"
                                height="30"
                                className="rounded-circle"
                                alt="React Bootstrap logo"
                                style={{ marginRight: "10px" }}
                              />
                            ) : (
                              <BiUserCircle
                                style={{
                                  margin: "0px 10px",
                                  fontSize: "22px",
                                  color: "#24496a",
                                }}
                              />
                            )}
                            <Link
                              to={`/user-profile-view1/${items?.userId?._id}`}
                            >
                              {items?.userId?.name}
                            </Link>
                          </td>
                          <td>
                            {" "}
                            <Link
                              to={`/user-profile-view1/${items?.userId?._id}`}
                            >
                              {" "}
                              {items?.userId?.mobile}
                            </Link>
                          </td>
                          {/* <td><Link to={`/user-profile-view1/${items?.userId?._id}`}>{items?.userId?.interest?.int ? (items?.userId?.interest?.int):(<RxCross2 style={{color:"red",fontSize:"20px"}}></RxCross2>)}</Link></td> */}

                          <td>
                            {items?.userId?.resume ? (
                              <a
                                onClick={() =>
                                  saveFile(
                                    items?.userId?.resume,
                                    items?.userId?.name
                                  )
                                }
                              >
                                <img
                                  src="./pngwing.com.png"
                                  width="30"
                                  height="30"
                                  className="s"
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  alt="React Bootstrap logo"
                                />
                              </a>
                            ) : (
                              <RxCross2
                                style={{ color: "red", fontSize: "20px" }}
                              ></RxCross2>
                            )}
                          </td>
                          <td>
                            <button
                              variant="primary"
                              style={{
                                color: "white",
                                backgroundColor: "black",
                                borderRadius: "5px",
                              }}
                              onClick={() =>
                                addShortList(
                                  items?.userId?._id,
                                  items?.companyId?._id
                                )
                              }
                            >
                              ShortList
                            </button>
                          </td>
                          <td>
                            {" "}
                            <button
                              variant="danger"
                              style={{
                                color: "white",
                                marginLeft: "20px",
                                backgroundColor: "red",
                                borderRadius: "5px",
                                borderColor: "red",
                              }}
                              onClick={() =>
                                rejectApply(
                                  items?.userId?._id,
                                  items?.companyId?._id
                                )
                              }
                            >
                              Reject
                            </button>{" "}
                          </td>
                          <td> {moment(items?.createdAt).fromNow()}</td>
                        </tr>
                      );
                    })}
                </table>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
                <p align="right" style={{ marginBottom: "2em" }}>
                  <Button
                    variant="primary"
                    type="button"
                    value="ShortList"
                    onClick={ExportToExcel}
                    style={{
                      backgroundColor: "rgb(5, 38, 78)",
                      color: "white",
                      border: "none",
                    }}
                  >
                    <b style={{ color: "white" }}>Export to Excel</b>
                  </Button>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Applieddetails;
