import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import "./MajorProduct.scoped.scss";

// back-e
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Table } from "react-bootstrap";
// import { Modal } from 'antd';
import Modal from "react-bootstrap/Modal";
import { AiOutlineDelete } from "react-icons/ai";
import { RiDeleteBin2Fill } from "react-icons/ri";

function GraphS() {
  const [category, setCategory] = useState("");
  const [alldata, setAlldata] = useState([]);
  const [alldata1, setAlldata1] = useState([]);

  const getAllcategory = async () => {
    try {
      let res = await axios.get("https://univiindia.com/api/admin/getGraph");
      if (res.status === 200) {
        setAlldata(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const getAllcategory1 = async () => {
    try {
      let res = await axios.get("https://univiindia.com/api/admin/getHeadingText");
      if (res.status === 200) {
        setAlldata1(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
    getAllcategory();
    getAllcategory1();
  }, []);

  const [image1, setImage1] = useState("");
  const [link, setlink] = useState("");
  const [heading1, setheading1] = useState("");
  const [heading2, setheading2] = useState("");
  const [text1, settext1] = useState("");
  const [text2, settext2] = useState("");

  let formdata = new FormData();
  let formdata1 = new FormData();

  const addhome = async () => {
    if (!image1) {
      alert("Select a banner");
    } else {
      formdata.append("image1", image1);
      formdata.append("link", link);

      formdata.append("graphId", alldata?._id);
      try {
        const config = {
          url: "/addGraph",
          method: "post",
          baseURL: "https://univiindia.com/api/admin",
          headers: { "content-type": "multipar/form-data" },
          data: formdata,
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert("success");
            setIsModalOpen(false);
            setIsModalOpen1(false);
            setlink("");
            getAllcategory();
            setImage1("");
          }
        });
      } catch (error) {
        //console.log(error);
        alert(error.response.data.error);
      }
    }
  };
  const addhome1 = async (e) => {
    e.preventDefault();
    if (!text1) {
      alert("Enter the text");
    } else {
      try {
        const config = {
          url: "/addHeadingText",
          method: "post",
          baseURL: "https://univiindia.com/api/admin",
          headers: { "content-type": "application/json" },
          data: { text1: text1 },
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert("success");
            settext1("");
            setIsModalOpen1(false);
            getAllcategory1();
          }
        });
      } catch (error) {
        //console.log(error);
        alert(error.response.data.error);
      }
    }
  };
  const deletebanner = async (data) => {
    try {
      axios
        .delete(`https://univiindia.com/api/admin/deleteGraph/` + data._id)
        .then(function(res) {
          if (res.status === 200) {
            //console.log(res.data);
            // window.location.reload();
            alert("Banner Deleted");
            getAllcategory();
          }
        });
    } catch (error) {
      //console.log(error);
    }
  };
  const deletetext = async (data) => {
    try {
      axios
        .delete(`https://univiindia.com/api/admin/deleteHeadingText/` + data._id)
        .then(function(res) {
          if (res.status === 200) {
            //console.log(res.data);
            alert("Text Deleted");
            getAllcategory1();
          }
        });
    } catch (error) {
      //console.log(error);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  };
  return (
    <>
      {" "}
      <div className="townhub">
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            {/* <Wcs /> */}

            <div className="container img">
              <div
                class="container"
                style={{
                  border: "1px solid rgba(36, 73, 106, 0.21)",
                  borderRadius: " 10px",
                  marginBottom: "10px",
                  boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
                  width: "100%",
                }}
              >
                <div class="loginform" style={{ textAlign: "center" }}>
                  <h1
                    className="heading-banner wow animate__animated animate__fadeInUp"
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                    }}
                  >
                    <b>Home Graph</b>
                  </h1>
                  <br />
                </div>
                <div className="">
                  <p align="right">
                    <Button
                      variant="primary"
                      type="button"
                      value="ShortList"
                      style={{
                        backgroundColor: "rgb(5, 38, 78)",
                        color: "white",
                        border: "none",
                        marginRight: "20px",
                      }}
                    >
                      <b style={{ color: "white" }} onClick={showModal}>
                        Add Banner
                      </b>
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      value="ShortList"
                      style={{
                        backgroundColor: "rgb(5, 38, 78)",
                        color: "white",
                        border: "none",
                      }}
                    >
                      <b style={{ color: "white" }} onClick={showModal1}>
                        Add Text
                      </b>
                    </Button>
                  </p>
                </div>

                <div className="slide_hero mb-3">
                  <OwlCarousel
                    className="owl-theme"
                    responsive={responsive}
                    items={4}
                    loop
                    margin={8}
                    nav={false}
                    dots={false}
                    autoplay={true}
                  >
                    {alldata?.map((data) => {
                      return (
                        <div class="item">
                          <a href={data?.link}>
                            <img
                              src={`https://univiindia.com/graph/${data?.image1}`}
                              alt=""
                              style={{ width: "80%", height: "115px" }}
                            />
                          </a>
                          <RiDeleteBin2Fill
                            style={{
                              marginTop: "-80%",
                              marginLeft: "-6%",
                              color: "red",
                              fontSize: "20px",
                            }}
                            onClick={() => deletebanner(data)}
                          />
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>

                <div className="slide_hero mb-3">
                  {alldata1?.map((data, index) => {
                    return (
                      <div class="item">
                        <div className="esw">
                          {index + 1}){data?.text1}
                        </div>
                        <RiDeleteBin2Fill
                          style={{
                            marginTop: "-2%",
                            // marginLeft: "0%",
                            color: "red",
                            fontSize: "20px",
                          }}
                          onClick={() => deletetext(data)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div style={{marginBottom:90}}></div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal show={isModalOpen} onHide={handleCancel}>
        <Modal.Header closeButton style={{ backgroundColor: "#165c49" }}>
          <Modal.Title style={{ color: "white" }}>Add Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#165c49" }}>
          <div className="">
            <div className="form-group">
              <label className="mt-1" style={{ color: "white" }}>
                Choose Banner
              </label>
              <br />
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={(e) => setImage1(e.target.files[0])}
              />
            </div>
            <div className="form-group">
              <label
                className="mt-1"
                style={{ color: "white" }}
                value={link}
                onChange={(e) => setlink(e.target.value)}
              >
                Redirect URL Link
              </label>
              <br />
              <input
                type="text"
                placeholder="Page URL Link..."
                className="form-control"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#165c49" }}>
          <Button variant="secondary" onClick={handleCancel}>
            Close
          </Button>
          <Button variant="primary" onClick={addhome}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* dncldjz */}
      <Modal show={isModalOpen1} onHide={handleCancel1}>
        <Modal.Header closeButton style={{ backgroundColor: "#165c49" }}>
          <Modal.Title style={{ color: "white" }}>Add Text</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#165c49" }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Enter Text"
              value={text1}
              onChange={(e) => settext1(e.target.value)}
            />
            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#165c49" }}>
          <Button variant="secondary" onClick={handleCancel1}>
            Close
          </Button>
          <Button variant="primary" onClick={addhome1}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GraphS;
