import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import "./MajorProduct.scoped.scss";
// import { Modal } from "antd";
import Wcs from "./Wcs1";
// import {Modal } from 'antd';

// back-e
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Modal, Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import ReactPaginate from "react-paginate";

function Category() {
  const [show, setShow] = useState(false);
  const [Industry, setIndustry] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [category, setCategory] = useState("");
  const [alldata, setAlldata] = useState([]);
  const [show1, setShow1] = useState(false);
  const [Delete, setDelete] = useState();
 

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (data) => {
    setShow1(true);
    setDelete(data);
  };
  const getAllcategory = async () => {
    try {
      let res = await axios.get("https://univiindia.com/api/admin/getAllCategory");
      if (res.status === 200) {
        setAlldata(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const [alldata1, setAlldata1] = useState([]);

  const getAllindustry = async () => {
    try {
      let res = await axios.get("https://univiindia.com/api/admin/getAllIndustry");
      if (res.status === 200) {
        setAlldata1(res.data.success);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    getAllcategory();
    getAllindustry();
  }, []);
  // //console.log("swagat to karo hmara",alldata)
  const addCategory = async (e) => {
    e.preventDefault();
    // if(Industry){
    if (category) {
      try {
        const config = {
          url: "/addCategory",
          method: "post",
          baseURL: "https://univiindia.com/api/admin",
          headers: { "content-type": "application/json" },
          data: { category: category ,Industry:Industry},
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert(res.data.success);
            handleClose();
            setCategory("");
            getAllcategory();
            setIsModalOpen(false)
          }
        });
      } catch (error) {
        //console.log(error);
        alert(error.response.data.error);
      }
    } else {
      alert("Please enter category!");
    }
  // } else {
  //   alert("Please select industry!");
  // }
  };
  const deleteCategory = async () => {
    try {
      const config = {
        url: "/deleteCategory/" + Delete._id,
        method: "delete",
        baseURL: "https://univiindia.com/api/admin",
        headers: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setShow1(false)
          getAllcategory();
        }
      });
    } catch (error) {
      //console.log(error);
      alert(error.response.data.error);
    }
  };

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    alldata.length /
      usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      {" "}
      <div className="townhub">
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            {/* <Wcs /> */}

            <div
              className="container img"
              style={{
                border: "1px solid rgba(36, 73, 106, 0.21)",
                borderRadius: " 10px",
                marginBottom: "10px",
                boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
                width: "100%",
              }}
            >
              <div class="container">
                <div class="loginform" style={{ textAlign: "center" }}>
                  <h1
                    className="heading-banner wow animate__animated animate__fadeInUp"
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                    }}
                  >
                    <b>Category</b>
                  </h1>
                </div>
              </div>
              <div className="d-flex" style={{ gap: "20px" }}>
                <Button variant="primary" onClick={showModal}>
                  Category +
                </Button>
                

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Job Category</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Category</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={addCategory}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <br />
              <Table striped style={{ width: "100%",zIndex:"auto" }}>
                <thead>
                  <tr>
                    <th>Index</th>
                    {/* <th>Industry Name</th> */}
                    <th>Category Name</th>
                    
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {alldata?.slice(pagesVisited, pagesVisited + usersPerPage).map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        {/* <td>{item?.Industry}</td> */}
                        <td>{item?.category}</td>
                        <td>
                          <AiOutlineDelete
                            style={{ color: "red", cursor: "pointer" }}
                           onClick={() => {
                              handleShow1(item)}}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      {/* <Button type="primary">Open Modal</Button> */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton style={{ backgroundColor: "#165c49" }}>
          <Modal.Title style={{ color: "white", fontWeight: "900" }}>
            Delete Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#165c49" }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label style={{ color: "white", fontSize: "20px" }}>
              Are you sure?
            </Form.Label>

            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#165c49" }}>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "red" }}
            onClick={deleteCategory}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isModalOpen}  onHide={handleCancel}>
      <Modal.Header closeButton style={{backgroundColor:"#165c49"}}>
                    <Modal.Title style={{color:"white"}}>Job Category</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{backgroundColor:"#165c49"}}>
      {/* <Form.Label style={{color:"white"}}>Industries</Form.Label> */}
      {/* <Form.Select aria-label="Default select example"  onChange={(e) => setIndustry(e.target.value)}>
      <option> Select Industries</option>
     {alldata1.map((item)=>( <option value={item.Industry}>{item.Industry}</option>))}
      
    </Form.Select> */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label style={{color:"white"}}>Category</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor:"#165c49"}}>
                    <Button variant="secondary" onClick={handleCancel}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={addCategory}>
                      Submit
                    </Button>
                  </Modal.Footer>
      </Modal>

      
    </>
  );
}

export default Category;
