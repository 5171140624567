import React, { useEffect } from "react";


function QrcodeS() {

  
  return (
    <>
      {" "}
      <div className="townhub">
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            {/* <Wcs /> */}

            <div className="container img">
              <div
                class="container"
                style={{
                  border: "1px solid rgba(36, 73, 106, 0.21)",
                  borderRadius: " 10px",
                  marginBottom: "10px",
                  boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
                  width: "100%",
                }}
              >
                <div class="loginform" style={{ textAlign: "center" }}>
                  <h1
                    className="heading-banner wow animate__animated animate__fadeInUp"
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                    }}
                  >
                    <b>QR Code</b>
                  </h1>
                  <br />
                </div>
               

               

               
                <div className="img_he" style={{display:'flex',justifyContent:'center'}}>
              <a class="name-job" download="qrcode.png" href="/Images/qrcode.png">   <img
                  src="/Images/qrcode.png"
                  alt=""
                  style={{ width: "300px", height: "300px",marginBottom:"30px"}}
                /></a>
               
             
              
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
    </>
  );
}

export default QrcodeS;
