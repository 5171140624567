import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./MajorProducts.css";
import { Modal } from "antd";

import axios from "axios";

import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Link, useNavigate } from "react-router-dom";
import exportFromJSON from "export-from-json";
import moment from "moment";
import ReactPaginate from "react-paginate";

function UnVerified() {
  const [unVerify, setUnVerify] = useState([]);
  const [Selectall, setSelectall] = useState(false);
  

  const [singleselected, setsingleselect] = useState([]);
  let check = JSON.parse(sessionStorage.getItem("employer"));
  const [user, setUser] = useState("");
  const loadUsers = async () => {
    const config = {
      url: "/user/getEmployerById/" + check?._id,
      method: "get",
      baseURL: "https://univiindia.com/api",
      headers: { "content-type": "application/json" },
    };
    try {
      const result = await axios(config);
      if (result.status === 200) {
        setUser(result.data.success);
        sessionStorage.removeItem("employer");
        sessionStorage.setItem("employer", JSON.stringify(result.data.success));
      } else {
        alert("Something went worng");
      }
    } catch (err) {
      //console.log(err.message);
    }
  };
  const [deleteid, setdeleteid] = useState("");
  const getVerifiedList = async () => {
    let res = await axios.get(
      "https://univiindia.com/api/user/getJobByEmployerId/" + check?._id
    );
    if (res.status === 200) {
      //console.log(res.data);
      setUnVerify(res.data.success);
      //console.log(res.data.success, "verify");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (id) => {
    setIsModalOpen(true);
    setdeleteid(id);
  };
  useEffect(() => {
    getVerifiedList();
    loadUsers();
  }, []);

  const data = [];
  if (unVerify?.length !== 0) {
    for (let i = 0; i < unVerify.length; i++) {
      if (unVerify[i]?.isVerify === false) {
        data.push({
          JobID: unVerify[i]?._id.slice(18, 24),
          CompanyName: unVerify[i]?.companyName,
          ContactPersonName: unVerify[i]?.interviewername,
          Email: unVerify[i]?.email,
          Contact: unVerify[i]?.whatsapp,
          Category: unVerify[i]?.category,
          JobProfile: unVerify[i]?.jobProfile,
          PostedOn: moment(unVerify[i]?.updatedAt).fromNow(),
        });
      }
    }
  }
  const handleOk = () => {
    removeJob();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const history = useNavigate();
  const exportType = "xls";
  const [fileName, setfileName] = useState("UnVerified Jobs");

  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };
  // if(user?.status!=="Approved"){
  //   history("/employerprofile")
  //   // alert("Admin Not approved your profile")

  // }else
  //console.log("user?.status", user?.status, check);
  const removeJob = async () => {
    let res = await axios.delete(
      "https://univiindia.com/api/user/deleteJob/" + deleteid
    );
    if (res.status === 200) {
      alert(res.data.success);
      getVerifiedList();
    }
  };
  const removeJob1 = async (id) => {
    let res = await axios.delete(
      "https://univiindia.com/api/user/deleteJob/" + id
    );
    if (res.status === 200) {
      // alert(res.data.success);
      window.location.reload();
      getVerifiedList();
    }
  };
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(
    unVerify?.filter((ele) => ele?.isVerify === false).length / usersPerPage
  );
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  function Deletebtn() {

    for(let i =0 ;i <singleselected.length ; i++){
    
      removeJob1(singleselected[i])
    }
  }
  const handleCheckboxSelect = (event, data) => {
    if (event.target.checked) {
      setsingleselect([...singleselected, data]);
      
    
    // setshowMoreId(singleselected?.includes(data),"true/false")
      console.log(singleselected,"jjjj");
    } else {
      setsingleselect(singleselected?.filter(id => id !== data));
      

    }
  };
 console.log(singleselected,"fdfdfdfdfd");

  const handleSelectAll = (event) => {
    
    if (event.target.checked) {
      setSelectall(true)
      setsingleselect(...singleselected, unVerify?.filter((ele) => ele?.isVerify === false).map(item => item._id));
   
    } else {
      setsingleselect([]);
      setSelectall(false)
    
    }
  };
 
  return (
    <>
      <br />
      {check.status !== "Approved" ? (
        <div style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
          <h3>Admin Not approved your profile please update.</h3>{" "}
          {user?.reasion ? <p>{user?.reasion}</p> : <></>}
        </div>
      ) : (
        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ overflow: "scroll", height: "550px" }}
          >
            <div
              className="container"
              style={{
                border: "1px solid #24496a",
                borderRadius: "10px",
                marginBottom: "10px",
                boxShadow: " rgb(0 0 0 / 16%) 0px 1px 4px",
                border: " 1px solid rgb(36 73 106 / 21%)",
                marginBottom: "10px",
              }}
            >
              <div class="container">
                <div class="loginform" style={{ textAlign: "center" }}>
                  <h1
                    style={{
                      color: "#24496a",
                      fontFamily: "Yantramanav,sans-serif",
                    }}
                  >
                    <b>Posted Jobs</b>
                  </h1>
                  <div>
                    <p align="right" style={{ marginBottom: "-1em" }}>
                      <Link to={"/addjob"}>
                        <Button
                          variant="primary"
                          type="button"
                          value="ShortList"
                          style={{
                            backgroundColor: "rgb(5, 38, 78)",
                            color: "white",
                            border: "none",
                          }}
                        >
                          <b style={{ color: "white" }}>Post job+</b>
                        </Button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <br />

              <div className="tble">
              <div style={{display:'flex',flexDirection:'row',margin:"20px"}}>
            <input onChange={handleSelectAll}   type="checkbox"  style={{marginLeft:"20px"}}/>
            <Button class="btn btn-outline-primary" onClick={Deletebtn} style={{backgroundColor:"#24496a",borderColor:"#24496a",marginLeft:"20px"}}>
            Delete All{" "}
                 
                </Button> 
           
                
           </div> 
                <div
                  style={{
                    display: "flex",
                    margin: "20px",
                    justifyContent: "space-around",
                  }}
                >
                  <Button
                    class="btn btn-outline-primary"
                    style={{
                      backgroundColor: "#2bb792",
                      borderColor: "#2bb792",
                    }}
                  >
                    Pending{" "}
                    {
                      unVerify
                        ?.filter((ele) => ele?.isVerify === false)
                        .filter((item) => item.status === "Pending").length
                    }
                  </Button>
                  <Button
                    class="btn btn-outline-primary"
                    style={{
                      backgroundColor: "#2bb792",
                      borderColor: "#2bb792",
                    }}
                  >
                    Hold{" "}
                    {
                      unVerify
                        ?.filter((ele) => ele?.isVerify === false)
                        .filter((item) => item.status === "Hold").length
                    }
                  </Button>
                </div>
                <table style={{ width: "100%",zIndex:"auto" }}>
                  <tr>
                    <th></th>
                    <th>Job ID</th>
                    <th>Company Name</th>
                    <th>Job Title</th>

                    <th>Status</th>
                    <th>Posted On</th>
                    <th>Action</th>
                  </tr>
                  {unVerify
                    ?.filter((ele) => ele?.isVerify === false)
                    .slice(pagesVisited, pagesVisited + usersPerPage)
                    ?.map((item) => {
                      return (
                        <tr>
                     <td> {Selectall ? <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)} checked={Selectall} /> : <input  type="checkbox" onChange={(e) => handleCheckboxSelect(e, item._id)}  />}</td>

                          <td>{item._id.slice(18, 24)}</td>
                          <td>
                            <Link to={`/employeecompanydetails/${item?._id}`}>
                              {" "}
                              {item?.companyName}
                            </Link>
                          </td>{" "}
                          <td>
                            <Link to={`/employeecompanydetails/${item?._id}`}>
                              {item?.jobProfile}{" "}
                            </Link>
                          </td>{" "}
                          <td>
                            {" "}
                            {item?.status === "Approved" ? (
                              <p style={{ color: "green" }}>{item?.status}</p>
                            ) : (
                              <>
                                {item?.status === "Hold" ? (
                                  <p
                                    style={{
                                      color: "#f72840",
                                      marginTop: "15px",
                                    }}
                                  >
                                    {item?.status}
                                  </p>
                                ) : (
                                  <p
                                    style={{ color: "blue", marginTop: "15px" }}
                                  >
                                    {item?.status}
                                  </p>
                                )}
                              </>
                            )}{" "}
                          </td>
                          <td>{moment(item?.updatedAt).fromNow()}</td>
                          <td>
                            <Link
                              to={`/employeecompanydetails/${item?._id}`}
                              class="btn btn-outline-primary"
                            >
                              View
                            </Link>
                            <Button
                              variant="danger"
                              // onClick={() => removeJob(item?._id)}
                              style={{ color: "white", marginLeft: "20px" }}
                              onClick={() => showModal(item?._id)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </table>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>

              {/* <button onClick={ExportToExcel} style={{backgroundColor:'green',color:'white',float:"left"}}>Export to Excel</button> */}
              {/* table */}
              {/* <Button
          variant="primary"
          type="submit"
          style={{ margin: "20px 470px" }}
        >
          submit
        </Button> */}
              {/* img */}
            </div>
            <p align="right" style={{ marginBottom: "2em" }}>
              <Button
                variant="primary"
                type="button"
                value="ShortList"
                onClick={ExportToExcel}
                style={{
                  backgroundColor: "rgb(5, 38, 78)",
                  color: "white",
                  border: "none",
                }}
              >
                <b style={{ color: "white" }}>Export to Excel</b>
              </Button>
              <Modal
                // title="Basic Modal"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <h6>Are you sure?</h6>
              </Modal>
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default UnVerified;
