import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Wcs from "./Wcs";

function Profile() {
  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ overflow: "scroll", height: "550px" }}
        >
       

          <div
            className="container"
            style={{
              border: "1px solid #24496a",
              background: "#f1f4fb",
              marginTop: "30px",
              borderRadius: "10px",
              height: "500px",
              overflow: "scroll",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <div class="container">
              <div class="loginform" style={{ textAlign: "center" }}>
                <h1
                  style={{
                    color: "#24496a",
                    fontFamily: "Yantramanav,sans-serif",
                  }}
                >
                  <b>Your Profile</b>
                </h1>
              </div>
            </div>
            <br />
            <div className="categ d-flex">
              <br />
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Employee Name
                <Form.Control
                  required
                  type="text"
                  placeholder="Employee Name"
                />
              </Form.Group>
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                {" "}
                Contacts
                <Form.Control type="number" placeholder="Contacts" />{" "}
              </Form.Group>
            </div>
            <br />
            <div className="categ d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Company Name
                <Form.Control type="text" placeholder="Company Name" />
              </Form.Group>
              <br />
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Job Profile
                <Form.Control type="text" placeholder=" Job Profile" />
              </Form.Group>
            </div>
            <br />
            <div className="categ d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Job Status
                <Form.Control type="text" placeholder="Job Status" />
              </Form.Group>
              <br />
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                {" "}
                Email
                <Form.Control type="text" placeholder="Email" />
              </Form.Group>
            </div>
            <br />
            <div className="categ d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Skill
                <Form.Control type="text" placeholder="Skill" />
              </Form.Group>
              <br />
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Qualification
                <Form.Control
                  type="text"
                  placeholder="Qualification"
                  name="qualification"
                />
              </Form.Group>
            </div>
            <br />
            <div className="categ d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Location
                <Form.Control type="text" placeholder="location" />
              </Form.Group>

              <Form.Group
                controlId="formFile"
                className="mb-3"
                style={{ width: "500px", margin: "0px 8px" }}
              >
                <Form.Label htmlFor="upload1">profile Picture</Form.Label>
                <Form.Control name="file" accept="image/*" type="file" />
              </Form.Group>
            </div>

            <div className="categ d-flex">
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Salary
                <Form.Control type="text" placeholder="salaryType" />
              </Form.Group>

              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                city
                <Form.Control type="text" placeholder="city" />
              </Form.Group>
            </div>
            <br />
            <div className="categ d-flex">
              {/* <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
            isVerify
            <Form.Control type="Boolean" placeholder="isVerify" />
          </Form.Group> */}
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Required Age
                <Form.Control type="text" placeholder="Required Age" />
              </Form.Group>
              <br />
              <Form.Group style={{ width: "500px", margin: "0px 8px" }}>
                Experience
                <Form.Control type="text" placeholder="Experience" />{" "}
              </Form.Group>
            </div>
            <br />
            <Button
              variant="primary"
              type="submit"
              style={{ margin: "10px 0px" }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
